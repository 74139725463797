import axios from "axios";
import React, {useState, useEffect, useRef} from "react";
import Loading from "../components/Loading";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Globals from "../parameters/globals";
import "../scss/contact.scss";
import {useLocation} from "react-router-dom";
import StarsRating from "react-star-rate";
import SEO from "./Seo";

import {
	EReCaptchaV2Size,
	EReCaptchaV2Theme,
	ReCaptchaV2,
} from "react-recaptcha-x";
import {useContext} from "react";
import AuthProvider from "../context/AuthProvider";

function FormSondaggio(props) {
	const [name, setname] = useState("");
	const [surname, setsurname] = useState("");
	const [email, setemail] = useState("");
	const [conoscenzaAmici, setConoscenzaAmici] = useState(false);
	const [conoscenzaRadio, setConoscenzaRadio] = useState(false);
	const [conoscenzaPubblicita, setConoscenzaPubblcita] = useState(false);
	const [conoscenzaInternet, setConoscenzaInternet] = useState(false);
	const [conoscenzaSoccorso, setConoscenzaSoccorso] = useState(false);
	const [conoscenzaAltro, setConoscenzaAltro] = useState(false);
	const [msgConoscenzaAltro, setMsgConoscenzaAltro] = useState("");
	const [servitoSedeVerona, setServitoSedeVerona] = useState("");
	const [servitoSedeAlpo, setServitoSedeAlpo] = useState("");
	const [servizio, setServizio] = useState("vendita");
	const [servizioAccoglienza, setServizioAccoglienza] = useState(0);
	const [qualitaPrezzo, setQualitaPrezzo] = useState(0);
	const [soddisfazioneEsigenze, setSoddisfazioneEsigenze] = useState(0);
	const [tempoModalita, setTempoModalita] = useState(0);
	const [operatoriService, setOperatoriService] = useState(0);
	const [preventivazioneGestione, setPreventivazioneGestione] = useState(0);
	const [tempiQualita, setTempiQualita] = useState(0);
	const [valutazioniServizi, setValutazioniServizi] = useState(0);
	const [operatoriIntervento, setOperatoriIntervento] = useState(0);
	const [tempiAttesaIntervento, setTempiAttesaIntervento] = useState(0);
	const [capacitaGestione, setCapacitaGestione] = useState(0);
	const [messaggio, setmessaggio] = useState("");
	const [privacy, setPrivacy] = useState(false);
	const [newsletter, setNewsletter] = useState(false);

	const [message, setmessage] = useState("");

	const [is_robot, set_is_robot] = useState(false);
	const [is_robot_general, set_is_robot_general] = useState(false);
	const [token, setToken] = useState("");
	const [loading, setLoading] = useState(false);

	const [success, setSuccess] = useState(false);
	const [errMsg, setErrMsg] = useState("");

	const [schedule, setSchedule] = useState([]);
	const {lang} = useContext(AuthProvider);
	const [value, setValue] = useState(0);

	// const getMapsAndSchedule = async () => {
	// 	try {
	// 		const response = await axios.get(Globals.GETWORKSCHEDULE + "&lang=" + lang);
	// 		setSchedule(response.data.work_schedule);
	// 	} catch (err) {
	// 		console.log(err);
	// 	}
	// };

	const handleSubmit = async (e) => {
		e.preventDefault();

		// let derivazione = props.general ? "Homepage" : props.from;

		if (token === "") {
			setErrMsg(Globals.language.errorLabel.acceptRecaptcha[lang]);
			return false;
		}

		try {
			setLoading(true);
			let body = {
				nome: name,
				cognome: surname,
				email: email,
				conoscenza_tramite_amici: conoscenzaAmici === true ? 1 : 0,
				conoscenza_tramite_radio: conoscenzaRadio === true ? 1 : 0,
				conoscenza_tramite_pubblicita: conoscenzaPubblicita === true ? 1 : 0,
				conoscenza_tramite_internet: conoscenzaInternet === true ? 1 : 0,
				conoscenza_tramite_soccorso: conoscenzaSoccorso === true ? 1 : 0,
				conoscenza_tramite_altro: conoscenzaAltro === true ? 1 : 0,
				conoscenza_tramite_altro_text:
					msgConoscenzaAltro !== "" ? msgConoscenzaAltro : "",
				servito_sede_verona: servitoSedeAlpo === "verona" ? 1 : 0,
				servito_sede_alpo: servitoSedeAlpo === "alpo" ? 1 : 0,
				servito_vendita: servizio === "vendita" ? 1 : 0,
				servito_officina_carrozzeria: servizio === "officina" ? 1 : 0,
				servito_soccorso: servizio === "soccorso" ? 1 : 0,
				servizio_accoglienza: servizioAccoglienza,
				qualita_prezzo: qualitaPrezzo,
				soddisfazione_esigenze: soddisfazioneEsigenze,
				tempi_modalita: tempoModalita,
				operatori_service: operatoriService,
				preventivazione_gestione: preventivazioneGestione,
				tempi_qualita: tempiQualita,
				valutazione_servizi: valutazioniServizi,
				operatori_intervento: operatoriIntervento,
				tempi_attesa_intervento: tempiAttesaIntervento,
				capacita_gestione_emergenza: capacitaGestione,
				messaggio: messaggio,
				privacy: privacy === true ? 1 : 0,
				newsletter: newsletter === true ? 1 : 0,
				token : token
			};
			let formdata = new FormData();

			formdata.append("body", JSON.stringify(body));

			var response = await axios.postForm(
				Globals.POST_SONDAGGIO + "&lang=" + lang,
				formdata,
				{
					headers: {"Content-Type": "multipart/form-data"},
					withCredentials: true,
				},
			);

			// TODO: remove console.logs before deployment
			// console.log(JSON.stringify(response?.data));
			//console.log(JSON.stringify(response))

			if (response?.data?.error === true) {
				setErrMsg(response.data.message);

				setLoading(false);
				setSuccess(false);
			} else {
				//clear state and controlled inputs
				setLoading(false);
				setSuccess(true);
			}
		} catch (err) {
			// console.log(err);
			setLoading(false);
			setSuccess(false);
			setErrMsg(Globals.language.errorLabel.serverError[lang]);
		}
	};

	// useEffect(() => {
	// if (location.pathname === "/") {
	// 	set_is_robot_general(true);
	// } else {
	// 	set_is_robot(true);
	// }

	// if (props.general === true) {
	// 	getMapsAndSchedule();
	// }
	// return () => {
	// 	set_is_robot(false);
	// 	set_is_robot_general(false);
	// };
	// }, [lang]);

	const v2Callback = (token) => {
		if (typeof token === "string") {
			setToken(token);
		} else if (typeof token === "boolean" && !token) {
			setErrMsg(Globals.language.errorLabel.recaptchaExpired[lang]);
			setToken("");
		} else if (token instanceof Error) {
			setErrMsg(Globals.language.errorLabel.serverError[lang]);
			setToken("");
		}
	};

	if (props.general) {
		return (
			<>
				{loading ? (
					<Loading />
				) : success ? (
					<div className='p-2'>
						<Card style={{fontSize: "25px", textAlign: "center"}}>
							<i
								className='fa  fa-check-circle'
								style={{fontSize: "50px", color: "green"}}></i>
							<h2>Sondaggio inviato con successo!</h2>
							<p style={{fontSize: "24px"}}>
								Grazie del tuo tempo, la tua opinione per noi è importante!
							</p>
						</Card>
					</div>
				) : (
					<Form
						onSubmit={(e) => {
							handleSubmit(e);
						}}>
						<SEO
							title={" Sondaggio | Lepanto 2 "}
							description={
								" Sondaggio | Lepanto 2 	Aiutaci a migliorare, dedicaci 5 minuti del tuo tempo per compilare il nostro sondaggio "
							}
							keyword={" sondaggio "}
							noIndex={true}

						/>{" "}
						<div className=' d-flex responsive-div-form'>
							<Form.Group
								className='mb-3 col-md-6 col-sm-12 '
								controlId='formBasicEmail'>
								<Form.Label>{Globals.language.contacts.nameForm[lang]}</Form.Label>
								<Form.Control
									type='name'
									placeholder={Globals.language.contacts.nameFormPlaceholder[lang]}
									value={name}
									onChange={(e) => setname(e.target.value)}
									required
								/>
							</Form.Group>
							<Form.Group
								className='mb-3 col-md-6 col-sm-12 mx-1'
								controlId='formBasicEmail'>
								<Form.Label>{Globals.language.contacts.surnameForm[lang]}</Form.Label>
								<Form.Control
									type='surname'
									placeholder={Globals.language.contacts.surnameFormPlaceholder[lang]}
									value={surname}
									onChange={(e) => setsurname(e.target.value)}
									required
								/>
							</Form.Group>
						</div>
						<Form.Group className='mb-3' controlId='formBasicEmail'>
							<Form.Label>{Globals.language.contacts.emailForm[lang]}</Form.Label>
							<Form.Control
								type='email'
								placeholder={Globals.language.contacts.emailFormPlaceholder[lang]}
								value={email}
								onChange={(e) => setemail(e.target.value)}
								required
							/>
						</Form.Group>
						<div className='d-flex '>
							<Form.Group
								className='mb-3 col-md-6 col-sm-12'
								controlId='formBasicEmail'>
								<Form.Label>
									Come sei venuto a conoscenza di Lepanto?
									<p className='subtitle-sondaggio'>Seleziona una o più opzioni.</p>
								</Form.Label>
								<Form.Check
									type='checkbox'
									label={"Tramite amici"}
									onChange={(e) =>
										e.target.value === "on"
											? setConoscenzaAmici(true)
											: setConoscenzaAmici(false)
									}
								/>
								<Form.Check
									type='checkbox'
									label={"Tramite radio"}
									onChange={(e) =>
										e.target.value === "on"
											? setConoscenzaRadio(true)
											: setConoscenzaRadio(false)
									}
								/>
								<Form.Check
									type='checkbox'
									label={"Tramite pubblicità"}
									onChange={(e) =>
										e.target.value === "on"
											? setConoscenzaPubblcita(true)
											: setConoscenzaPubblcita(false)
									}
								/>
								<Form.Check
									type='checkbox'
									label={"Tramite internet"}
									onChange={(e) =>
										e.target.value === "on"
											? setConoscenzaInternet(true)
											: setConoscenzaInternet(false)
									}
								/>
								<Form.Check
									type='checkbox'
									label={"Soccorso stradale"}
									onChange={(e) =>
										e.target.value === "on"
											? setConoscenzaSoccorso(true)
											: setConoscenzaSoccorso(false)
									}
								/>
								<Form.Check
									type='checkbox'
									label={"Altro (specificare)"}
									// onChange={(e) =>
									//   e.target.value === "on"
									//     ? setConoscenzaAltro(true)
									//     : setConoscenzaAltro(false)
									// }
									onClick={() =>
										setConoscenzaAltro((conoscenzaAltro) => !conoscenzaAltro)
									}
								/>
								{conoscenzaAltro ? (
									<textarea
										value={msgConoscenzaAltro}
										onChange={(e) => setMsgConoscenzaAltro(e.target.value)}></textarea>
								) : (
									<></>
								)}
							</Form.Group>
							<Form.Group
								className='mb-3 col-md-6 col-sm-12'
								controlId='formBasicEmail'>
								<Form.Label>
									In quale sede sei stato servito?
									<p className='subtitle-sondaggio'>Seleziona un opzione.</p>
								</Form.Label>
								<Form.Check
									type='radio'
									label={"Verona (VR)"}
									name='group1'
									value={"verona"}
									defaultChecked
									onChange={(e) => setServitoSedeAlpo(e.target.value)}
									id={`inline-radio-1`}
								/>
								<Form.Check
									type='radio'
									name='group1'
									value={"alpo"}
									onChange={(e) => setServitoSedeAlpo(e.target.value)}
									label={"Alpo di Villafranca di Verona (VR)"}
									id={`inline-radio-2`}
								/>
							</Form.Group>
						</div>
						<Form.Group className='mb-3' controlId='formBasicEmail'>
							<Form.Label>
								Per quale servizio sei stato servito?
								<p className='subtitle-sondaggio'>Seleziona un opzione.</p>
							</Form.Label>
							<Form.Check
								type='radio'
								label={"Vendita Auto"}
								name='group2'
								value='vendita'
								defaultChecked
								onChange={(e) => setServizio(e.target.value)}
							/>

							<Form.Check
								type='radio'
								name='group2'
								value='officina'
								label={"Officina / Carrozzeria"}
								onChange={(e) => setServizio(e.target.value)}
							/>
							<Form.Check
								type='radio'
								name='group2'
								value='soccorso'
								label={"Soccorso"}
								onChange={(e) => setServizio(e.target.value)}
							/>

							{/* <Form.Control
                type="email"
                placeholder={
                  Globals.language.contacts.emailFormPlaceholder[lang]
                }
                value={email}
                onChange={(e) => setemail(e.target.value)}
                required
              /> */}
						</Form.Group>
						<Form.Label>
							Come valuti il nostro servizio di accoglienza?
							<p className='subtitle-sondaggio'>Dai un voto da 1(min) a 5(max)</p>
						</Form.Label>
						<Form.Group className='mb-3' controlId='formBasicCheckbox'>
							<StarsRating
								value={servizioAccoglienza}
								allowHalf={false}
								onChange={(value) => {
									setServizioAccoglienza(value);
								}}
							/>
						</Form.Group>
						{servizio === "vendita" ? (
							<Form.Group className='mb-3' controlId='formBasicCheckbox'>
								<div className='text-center font-bold'> AREA VENDITA</div>
								<p className='separator'></p>
								<Form.Label>
									Come valuti il il rapporto qualità / prezzo del nostro parco auto?
									<p className='subtitle-sondaggio'>Dai un voto da 1(min) a 5(max)</p>
								</Form.Label>
								<Form.Group className='mb-3' controlId='formBasicCheckbox'>
									<StarsRating
										value={qualitaPrezzo}
										allowHalf={false}
										onChange={(value) => {
											setQualitaPrezzo(value);
										}}
									/>
								</Form.Group>
								<Form.Label>
									Come valuti i nostri operatori commerciali nel soddisfare le tue
									esigenze?
									<p className='subtitle-sondaggio'>Dai un voto da 1(min) a 5(max)</p>
								</Form.Label>
								<Form.Group className='mb-3' controlId='formBasicCheckbox'>
									<StarsRating
										value={soddisfazioneEsigenze}
										allowHalf={false}
										onChange={(value) => {
											setSoddisfazioneEsigenze(value);
										}}
									/>
								</Form.Group>
								<Form.Label>
									Come valuti i tempi e le modalità di consegna del veicolo?
									<p className='subtitle-sondaggio'>Dai un voto da 1(min) a 5(max)</p>
								</Form.Label>
								<Form.Group className='mb-3' controlId='formBasicCheckbox'>
									<StarsRating
										value={tempoModalita}
										allowHalf={false}
										onChange={(value) => {
											setTempoModalita(value);
										}}
									/>
								</Form.Group>
							</Form.Group>
						) : servizio === "officina" ? (
							<Form.Group className='mb-3' controlId='formBasicCheckbox'>
								<div className='text-center font-bold'>
									{" "}
									AREA OFFICINA / CARROZZERIA
								</div>
								<p className='separator'></p>
								<Form.Label>
									Come valuti i nostri operatori service?
									<p className='subtitle-sondaggio'>Dai un voto da 1(min) a 5(max)</p>
								</Form.Label>
								<Form.Group className='mb-3' controlId='formBasicCheckbox'>
									<StarsRating
										value={operatoriService}
										allowHalf={false}
										onChange={(value) => {
											setOperatoriService(value);
										}}
									/>
								</Form.Group>
								<Form.Label>
									Come valuti la preventivazione e la gestione dell’intervento?{" "}
									<p className='subtitle-sondaggio'>Dai un voto da 1(min) a 5(max)</p>
								</Form.Label>
								<Form.Group className='mb-3' controlId='formBasicCheckbox'>
									<StarsRating
										value={preventivazioneGestione}
										allowHalf={false}
										onChange={(value) => {
											setPreventivazioneGestione(value);
										}}
									/>
								</Form.Group>
								<Form.Label>
									Come valuti i tempi e la qualità dell’intervento?{" "}
									<p className='subtitle-sondaggio'>Dai un voto da 1(min) a 5(max)</p>
								</Form.Label>
								<Form.Group className='mb-3' controlId='formBasicCheckbox'>
									<StarsRating
										value={tempiQualita}
										allowHalf={false}
										onChange={(value) => {
											setTempiQualita(value);
										}}
									/>
								</Form.Group>
								<Form.Label>
									Come valuti i servizi offerti da Lepanto?{" "}
									<p className='subtitle-sondaggio'>Dai un voto da 1(min) a 5(max)</p>
								</Form.Label>
								<Form.Group className='mb-3' controlId='formBasicCheckbox'>
									<StarsRating
										value={valutazioniServizi}
										allowHalf={false}
										onChange={(value) => {
											setValutazioniServizi(value);
										}}
									/>
								</Form.Group>
							</Form.Group>
						) : (
							<Form.Group className='mb-3' controlId='formBasicCheckbox'>
								<div className='text-center font-bold'> AREA SOCCORSO</div>
								<p className='separator'></p>
								<Form.Label>
									Come valuti i nostri operatori del pronto intervento?{" "}
									<p className='subtitle-sondaggio'>Dai un voto da 1(min) a 5(max)</p>
								</Form.Label>
								<Form.Group className='mb-3' controlId='formBasicCheckbox'>
									<StarsRating
										value={operatoriIntervento}
										allowHalf={false}
										onChange={(value) => {
											setOperatoriIntervento(value);
										}}
									/>
								</Form.Group>
								<Form.Label>
									Come valuti i tempi di attesa per l’intervento?{" "}
									<p className='subtitle-sondaggio'>Dai un voto da 1(min) a 5(max)</p>
								</Form.Label>
								<Form.Group className='mb-3' controlId='formBasicCheckbox'>
									<StarsRating
										value={tempiAttesaIntervento}
										allowHalf={false}
										onChange={(value) => {
											setTempiAttesaIntervento(value);
										}}
									/>
								</Form.Group>
								<Form.Label>
									Come valuti la nostra capacità nella gestione dell’emergenza?{" "}
									<p className='subtitle-sondaggio'>Dai un voto da 1(min) a 5(max)</p>
								</Form.Label>
								<Form.Group className='mb-3' controlId='formBasicCheckbox'>
									<StarsRating
										value={capacitaGestione}
										allowHalf={false}
										onChange={(value) => {
											setCapacitaGestione(value);
										}}
									/>
								</Form.Group>
							</Form.Group>
						)}
						<Form.Group className='mb-3' controlId='formBasicCheckbox'>
							<div className='text-center font-bold'> PARTE FINALE</div>
							<p className='separator'></p>
							<Form.Label>
								Esprimi pure un commento libero relativo all’esperienza presso LEPANTO:
							</Form.Label>
							<Form.Group className='mb-3' controlId='formBasicCheckbox'>
								<Form.Control
									type='text'
									placeholder={"Inserisci un commento libero"}
									as='textarea'
									value={messaggio}
									onChange={(e) => setmessaggio(e.target.value)}
								/>
							</Form.Group>
						</Form.Group>
						<Form.Group className='mb-3' controlId='formBasicCheckbox'>
							<Form.Check
								type='checkbox'
								label={"Iscrizione alla newsletter"}
								onChange={(e) =>
									e.target.value === "on" ? setNewsletter(true) : setNewsletter(false)
								}
							/>
						</Form.Group>
						<Form.Group className='mb-3' controlId='formBasicCheckbox'>
							<Form.Check
								type='checkbox'
								label={Globals.language.contacts.privacyForm[lang]}
								onChange={(e) =>
									e.target.value === "on" ? setPrivacy(true) : setPrivacy(false)
								}
								required
							/>
						</Form.Group>
						{/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label={Globals.language.contacts.newsletterForm[lang]}
                onChange={(e) =>
                  e.target.value === "on"
                    ? setNewsletter(true)
                    : setNewsletter(false)
                }
              />
            </Form.Group> */}
						<Form.Group className='position-relative' controlId='formBasicCheckbox'>
							<ReCaptchaV2
								callback={v2Callback}
								theme={EReCaptchaV2Theme.Light}
								size={EReCaptchaV2Size.Normal}
								id='my-id'
								data-test-id='my-test-id'
								tabindex={0}
							/>
						</Form.Group>
						<Button
							className='d-flex justify-content-center m-auto'
							style={{
								backgroundColor: "#003187",
								border: "1px solid #003187",
							}}
							type='submit'>
							Invia sondaggio
						</Button>
						{errMsg && <p> {errMsg} </p>}
					</Form>
				)}
			</>
		);
	}
}
export default FormSondaggio;
