import React from "react";
import Globals from "../parameters/globals";

function AdminArea() {
	return (
		<div
			className='container-fluid position-relative'
			style={{
				position: "relative",
				top: "50px",
				width: "100%",
				height: "950px",
				overflow: "hidden",
			}}>
			<iframe
				className='iframe'
				style={{
					position: "relative",
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					width: "100%",
					minHeight: "700px",
					height: "auto",
					border: "none",
					margin: 0,
					padding: 0,
				}}
				src={Globals.MylepantoUrl}
				title='Area Admin'></iframe>
		</div>
	);
}

export default AdminArea;
