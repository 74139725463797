import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Globals from "../parameters/globals";
// import { Helmet } from "react-helmet";
import GeneralContext from "../context/AuthProvider";
import SEO from "../components/Seo";
function Certificato() {
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [certificatoRina, setCertificatoRina] = useState([]);
  const { lang } = useContext(GeneralContext);

  useEffect(() => {
    getCondizioniAcquisto();
  }, [lang]);

  const getCondizioniAcquisto = async () => {
    setError(false);
    setMessage("");

    try {
      const response = await axios.get(Globals.GETCERTIFICATORINA, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });

      if (response?.data?.error === true) {
        setError(true);
        setMessage(response?.data?.error);
      } else {
        window.scrollTo(0, 0);
        setCertificatoRina(
          lang === "it"
            ? response?.data?.certificato.htmlcontents_it
            : lang === "en"
            ? response?.data?.certificato.htmlcontents_en
            : response?.data?.certificato.htmlcontents_si
        );
      }
    } catch (err) {
      setError(true);
      setMessage(err);
    }
  };

  return (
    <>
      {error ? (
        <section className="text-center">
          {" "}
          <h2> {message}</h2>
        </section>
      ) : (
        <div
          className="container"
          style={{ marginTop: "140px", marginBottom: "100px" }}
        >
          {/* <Helmet>
						<meta charSet='utf-8' />
						<link rel='canonical' href='https://www.gravner.it/privacy' />
					</Helmet> */}
          <SEO
            title={"   Certificazioni  | Lepanto 2  "}
            description={
              "Certificato di conformità alla norma Lepanto 2 S.r.l."
            }
            keyword={"Certificazioni"}
            noIndex={true}
          />
          <div dangerouslySetInnerHTML={{ __html: certificatoRina }} />
        </div>
      )}
    </>
  );
}

export default Certificato;
