//url da modificare in fase di deploy online
let baseUrl = "https://cms.lepanto.it/webservices/2.0/";
let domain = "https://cms.lepanto.it/";
let recaptcha_v2_key = process.env.REACT_APP_RECAPTCHA_PRODUCTION_KEY;

if (
	!process.env.NODE_ENV ||
	process.env.NODE_ENV === "development" ||
	window.location.href.toString().includes("lepanto.readynet.it") === true
) {
	baseUrl = "https://cmslepanto.readynet.it/webservices/2.0/";
	domain = "https://cmslepanto.readynet.it/";
	recaptcha_v2_key = process.env.REACT_APP_RECAPTCHA_DEV_KEY;
}

const Globals = {
	// baseUrl: baseUrl,
	// domain: domain,
	// paypalClientId: paypalClientId,
	recaptcha_v2_key: recaptcha_v2_key,
	LOGIN: baseUrl + "?a=login",
	GETHOME: baseUrl + "?a=get_home_element",
	GETALLCARS: baseUrl + "?a=get_all_cars",
	GETCAR: baseUrl + "?a=get_car_detail",
	SIMPLE_REQUEST: baseUrl + "?a=simple_request",
	QUOTATION_REQUEST: baseUrl + "?a=quotation_request",
	RENT_REQUEST: baseUrl + "?a=rent_request",
	GETRESERVATION: baseUrl + "?a=get_reservation",
	GETTYPERENT: baseUrl + "?a=get_type_rent",
	GETSCHEDULEBOOK: baseUrl + "?a=get_booking_schedule",
	BOOKING_REQUEST: baseUrl + "?a=booking_request",
	GETRENTDURATION: baseUrl + "?a=get_rent_duration",
	RENTALQUOTATIONREQUEST: baseUrl + "?a=rental_quotation_request",
	CHECKRENTAVALABILITY: baseUrl + "?a=check_rent_availability",
	WORKWITHUS: baseUrl + "?a=lavora_con_noi_attachment",
	CONTACT: baseUrl + "?a=general_contact",
	GET_ABOUT: baseUrl + "?a=get_about_us",
	POST_SONDAGGIO: baseUrl + "?a=sondaggio_request",
	GETLEGALPAGE: baseUrl + "?a=get_legal_page",
	GETCONDIZIONIUTILIZZO: baseUrl + "?a=get_condizioni_utilizzo",
	GETCONDIZIONIACQUISTO: baseUrl + "?a=get_condizioni_acquisto",
	GETCERTIFICATORINA: baseUrl + "?a=get_certificato_rina",	
	CREDITS: baseUrl + "?a=get_credits_page",
	DISCLAIMER: baseUrl + "?a=get_disclaimer_page",
	GETPREFERENCECOOKIE: baseUrl + "?a=getPreferencesCookie",
	SAVEPREFERENCECOOKIE: baseUrl + "?a=saveCookiePreferences",
	GETFOOTERCOOKIE: baseUrl + "?a=get_footer_cookie",
	POST_PREVENTIVO: baseUrl + "?a=preventivo_request",
	CHECKLOGIN: baseUrl + "?a=is_logged",
	LOGIN: baseUrl + "?a=login",
	REGISTER: baseUrl + "?a=register",
	LOGOUT: baseUrl + "?a=logout",
	GETMYVEHICLES: baseUrl + "?a=get_my_vehicles",
	POSTPASSWORD: baseUrl + "?a=passwordRecovery",
	CHANGEPASSWORD: baseUrl + "?a=recoveryPasswordOtp",
	CHECKREDIRECT301: baseUrl + "?a=check_redirect",
	UPDATEREDIRECTCOUNTER: baseUrl + "?a=update_redirect_counter",

	OPENCARPRINTSALE: domain + "?s=public&p=vendita&a=print_pdf&id=",
	YOURBOOKINGREQUEST: baseUrl + "?a=your_booking_request",
	loginUrl: domain + "login",
	MylepantoUrl: domain + "my_lepanto.html",

	// NATIONAPI: "https://restcountries.com/v3.1/all",

	language: {
		navbar: {
			congress: {
				it: "Congressi",
				en: "Congresses",
			},

			events: {
				it: "Eventi",
				en: "Events",
			},

			clients: {
				it: "Clienti",
				en: "Customers",
			},

			aboutUs: {
				it: "Chi siamo",
				en: "About us",
			},

			contacts: {
				it: "Contatti",
				en: "Contact us",
			},

			portfolio: {
				it: "Portfolio",
				en: "Portfolio",
			},
			empty: {
				it: "Svuota",
				en: "Empty cart",
			},
			adminArea: {
				it: "Area admin",
				en: "Admin area",
			},
			userArea: {
				it: "Area utente",
				en: "User area",
			},
			welcome: {
				it: "Benvenuto",
				en: "Welcome",
			},
			accedi: {
				it: "Accedi",
				en: "Sign in",
			},
			registrati: {
				it: "Registrati",
				en: "Sign up",
			},
		},
		congress: {
			titleCongress: {
				it: "Congressi",
				en: "Congresses",
			},
			iscritto: {
				it: "Iscritto",
				en: "Subscribe",
			},
			capienzaRaggiunta: {
				it: "Raggiunta capienza massima",
				en: "Achieved maximum capacity",
			},
			iscrittoCongresso: {
				it: "Iscritto a questo congresso",
				en: "Joined in this congress",
			},
			scopriPiu: {
				it: "Scopri di più",
				en: "Read more",
			},
			iscriviti: {
				it: "Iscriviti al congresso",
				en: "Join the conference",
			},
			iscrivitiGratuitamente: {
				it: "Iscrizione gratuita",
				en: "Free membership",
			},
			nessunaDescrizione: {
				it: "Questo congresso non ha una descrizione",
				en: "This congress does not have a description",
			},
		},
		events: {
			titleEvents: {
				it: "Eventi",
				en: "Events",
			},
			iscritto: {
				it: "Iscritto",
				en: "Subscribe",
			},
			capienzaRaggiunta: {
				it: "Raggiunta capienza massima",
				en: "Achieved maximum capacity",
			},
			iscrittoCongresso: {
				it: "Iscritto a questo evento",
				en: "Joined in this event",
			},
			scopriPiu: {
				it: "Scopri di più",
				en: "Read more",
			},
			iscriviti: {
				it: "Iscriviti all'evento",
				en: "Register for the event",
			},
			iscrivitiGratuitamente: {
				it: "Iscrizione gratuita",
				en: "Free membership",
			},
			nessunaDescrizione: {
				it: "Questo evento non ha una descrizione",
				en: "This event does not have a description",
			},
			messageNoEventsCongress: {
				it: "Al momento non sono in programma eventi o congressi futuri, ma arriveranno presto!",
				en: "No future events or conferences are currently scheduled, but they will be coming soon!",
			},
			titleNoEventsCongress: {
				it: "Intanto, alcuni progetti del passato.",
				en: "Meanwhile, some past projects.",
			},
		},
		customers: {
			titleCustomers: {
				it: "Clienti",
				en: "Customers",
			},
		},
		aboutUs: {
			titleCustomers: {
				it: "Chi siamo",
				en: "About us",
			},
		},
		contacts: {
			titleCustomers: {
				it: "Contattaci",
				en: "Contact us",
			},
			nameCurriculum: {
				it: "Carica il tuo curriculum",
				en: "Upload your CV",
				ru: "",
			},
			nameFormCurriculum: {
				it: "Nessun file selezionato",
				en: "No file selected",
				ru: "",
			},
			sendSuccess: {
				it: "Contatto inviato con successo !",
				en: "Contact sent successfully !",
			},
			sendTextSuccess: {
				it: "Risponderemo al più presto alla casella di posta all'indirizzo suggerito",
				en: "We will respond as soon as possible to the mailbox at the suggested address",
			},
			nameForm: {
				it: "Nome",
				en: "Name",
			},
			nameFormPlaceholder: {
				it: "Inserisci il nome",
				en: "Enter the name",
			},
			surnameForm: {
				it: "Cognome",
				en: "Surname",
			},
			surnameFormPlaceholder: {
				it: "Inserisci il cognome",
				en: "Enter the surname",
			},
			emailForm: {
				it: "Email",
				en: "Email",
			},
			emailFormPlaceholder: {
				it: "Inserisci email",
				en: "Enter email",
			},
			phoneForm: {
				it: "Numero di telefono",
				en: "Phone number",
			},
			phoneFormPlaceholder: {
				it: "Inserisci il numero di telefono",
				en: "Enter phone number",
			},
			privateForm: {
				it: "Privato",
				en: "Private user",
			},
			companyForm: {
				it: "Azienda",
				en: "Company",
			},
			messaggeForm: {
				it: "Messaggio",
				en: "Message",
			},
			messaggeFormPlaceholder: {
				it: "Inserisci il testo del messaggio",
				en: "Enter the text of the message",
			},
			privacyForm: {
				it: "Ho preso visione dell’informativa privacy e acconsento al trattamento dei miei dati.",
				en: "I have read the privacy policy and consent to the processing of my data.",
			},
			newsletterForm: {
				it: "Presto il mio consenso per l’iscrizione al servizio di newsletter.",
				en: "I give my consent to subscribe to the newsletter service.",
			},
			sendButtonForm: {
				it: "Invia Contatto",
				en: "Send",
			},
			sedeOperativa: {
				it: "Sede operativa:",
				en: "Operational headquarters:",
			},
			phone: {
				it: "Telefono:",
				en: "Phone number:",
			},
			email: {
				it: "Email:",
				en: "Email:",
			},
			hours: {
				it: "I nostri orari:",
				en: "Opening hours: ",
			},
			monday: {
				it: "Lunedì - Venerdì:",
				en: "Monday - Friday:",
			},
			saturday: {
				it: "Sabato:",
				en: "Saturday:",
			},
			sunday: {
				it: "Domenica",
				en: "Sunday",
			},
		},
		newsletter: {
			subrscribeNewsletter: {
				it: "Iscriviti alla newsletter",
				en: "Sign up for newsletter",
			},
			titleAccossentNewsletter: {
				it: "Registrazione alle newsletter avvenuta con successo!",
				en: "Successful newsletter registration!",
			},
			descAccossentNewsletter: {
				it: "Ora potrai ricevere le nostre news in anteprima.",
				en: "Now you can receive our news in advance.",
			},
			errorPrivacyNewsletter: {
				it: "Acconsentire al trattamento dei dati personali.",
				en: "Agree to the processing of personal data.",
			},
			newsletterConfirmPrivacy: {
				it: "Ho preso visione dell’informativa privacy e presto il consenso per l’iscrizione al servizio di newsletter.",
				en: "I have read the privacy policy and consent to subscribe to the newsletter service.",
			},
			buttonSubscribeNewsletter: {
				it: "Iscriviti",
				en: "Sign up",
			},
			insertEmailNewsletter: {
				it: "Inserisci email",
				en: "Enter email",
			},
		},
		footer: {
			footerFirstDesc: {
				it: "Sede legale: Via Roveggia 29/C 37136 Verona - Sede operativa: Via Anfiteatro 10 37121 Verona.",
				en: "Registered office: Street Roveggia 29/C 37136 Verona - Operational headquarters: 10 Amphitheater Street 37121 Verona.",
			},
			footerSecondDesc: {
				it: "C.F./P.IVA e Reg. Imp. Verona n° 02885140232 - Cap.soc 15.300€ i.v.",
				en: "Tax code/VAT number and Business Register Verona n° 02885140232 - Capital stock 15.300€ f.p.",
			},
			cookiePolicy: {
				it: "Cookie policy",
				en: "Cookie policy",
			},
			privacyPolicy: {
				it: "Privacy policy",
				en: "Privacy policy",
			},
			condizioniVendita: {
				it: "Condizioni di vendita",
				en: "Terms of sale",
			},
			condizioniUtilizzo: {
				it: "Condizioni di utilizzo",
				en: "Terms of use",
			},
			preferenzeCookie: {
				it: "Preferenze cookie",
				en: "Cookie preferences",
			},
		},
		cardObject: {
			buttondetail: {
				it: "Dettaglio",
				en: "Detail",
			},
			title: {
				it: "Titolo",
				en: "Title",
			},
			street: {
				it: "Via",
				en: "Street",
			},
			city: {
				it: "Città",
				en: "City",
			},
			services: {
				it: "Servizi legati",
				en: "Related services",
			},
			agents: {
				it: "Agenti",
				en: "Agents",
			},
			ticket: {
				it: "Genera biglietto",
				en: "Generate ticket",
			},
			buttonClose: {
				it: "Chiudi",
				en: "Close",
			},
		},
		cart: {
			riepilogoCart: {
				it: "Riepilogo di acquisto per",
				en: "Summary of purchase for",
			},
			riepilogoAcquisto: {
				it: "Riepilogo di acquisto",
				en: "Summary of purchase",
			},
			riepilogo: {
				it: "Riepilogo",
				en: "Summary",
			},
			noTicket: {
				it: "Nessun biglietto",
				en: "No ticket",
			},
			numberTicket: {
				it: "Numero biglietti: ",
				en: "Number of tickets: ",
			},
			emptyCart: {
				it: "Il tuo carrello è vuoto!",
				en: "Your cart is empty!",
			},
			emptyCartSecond: {
				it: "Per effetuare un'iscrizione torna alla",
				en: "To effect an enrollment go back to the",
			},
			luogo: {
				it: "Luogo",
				en: "Place",
			},
			quantita: {
				it: "Quantità",
				en: "Quantity",
			},
			price: {
				it: "Prezzo",
				en: "Price",
			},
			vat: {
				it: "iva",
				en: "vat",
			},
			tipoBiglietto: {
				it: "Quota di iscrizione",
				en: "Registration fee",
			},
			name: {
				it: "Nome",
				en: "Name",
			},
			service: {
				it: "Servizi",
				en: "Services",
			},
			typeService: {
				it: "Tipo di servizio",
				en: "Type of service",
			},
			actions: {
				it: "Azioni",
				en: "Actions",
			},
			serviceAddedd: {
				it: "Non sono presenti servizi aggiuntivi",
				en: "There are no additional services",
			},
			start: {
				it: "Inizio",
				en: "Start",
			},
			end: {
				it: "Fine",
				en: "End",
			},
			agentSelected: {
				it: "Agente selezionato",
				en: "Selected agent",
			},
			buttonEmpty: {
				it: "Svuota",
				en: "Empties",
			},
			buttonModify: {
				it: "Modifica",
				en: "Edit",
			},
			additionaNotesCart: {
				it: "Note aggiuntive da te inserite",
				en: "Additional notes you included",
			},
			vatExempt: {
				it: "esente iva",
				en: "vat exempt",
			},
			vatIncl: {
				it: "iva incl",
				en: "vat incl",
			},
			condVend: {
				it: "Ho letto e accetto le",
				en: "I have read and I accept the",
			},
			condVendSecond: {
				it: "condizioni di vendita",
				en: "terms and conditions of sale",
			},
			clausole: {
				it: "Dichiaro di accettare espressamente le clausole contenute ai seguenti articoli: 3.7, 3.8, 3.9 (Obblighi del Cliente), 6 (Responsabilità del Fornitore), 7.1 (Recesso), 7.2 (Annullamento), 8 (Risoluzione), 10.1 (Lingua prevalente), 10.2 (Legge applicabile), 10.3 (Foro competente)",
				en: "I declare that I expressly accept the clauses contained in the following articles: 3.7, 3.8, 3.9 (Customer's Obligations), 6 (Supplier's Liability), 7.1 (Withdrawal), 7.2 (Cancellation), 8 (Termination), 10.1 (Prevailing Language), 10.2 (Applicable Law), 10.3 (Jurisdiction)",
			},
			backHome: {
				it: "Torna alla home",
				en: "Back to home",
			},
			closeCartTitle: {
				it: "Attenzione!",
				en: "Attention!",
			},
			from: {
				it: "Dal",
				en: "From",
			},
			at: {
				it: "al",
				en: "at",
			},
			time: {
				it: "Orario",
				en: "Schedule",
			},
			total: {
				it: "Totale",
				en: "Total",
			},
			data: {
				it: "Data",
				en: "Date",
			},
		},
		cookiebar: {
			btn_essenziali: {
				it: "Rifiuta tutti",
				en: "Rejects all ",
			},
			btn_all: {
				it: "Accetta tutto",
				en: "All Cookies",
			},
			btn_more: {
				it: "Preferenze cookie",
				en: "More options",
			},
			privacy: {
				it: "Privacy",
				en: "privacy",
			},
			cookie_necessari: {
				it: "Cookie Necessari (Obbligatori)",
				en: "Essential cookies (Required)",
			},
			cookie_analitici: {
				it: "Cookie analitici",
				en: "Analytical cookies",
			},
			cookie_profilazione: {
				it: "Cookie di profilazione",
				en: "Profiling cookie",
			},
			link_privacy: {
				it: "Scopri di più su",
				en: "Find out more",
			},
			btn_confirm: {
				it: "Conferma",
				en: "Confirm",
			},
		},
		ticketService: {
			luogo: {
				it: "Luogo",
				en: "Place",
			},
			quantita: {
				it: "Quantità",
				en: "Quantity",
			},
			descrizione: {
				it: "Descrizione",
				en: "Description",
			},
			selectTicket: {
				it: "Seleziona quota di iscrizione",
				en: "Select registration fee ",
			},
			noServices: {
				it: "Non sono presenti servizi aggiuntivi.",
				en: "There are no additional services.",
			},
			price: {
				it: "Prezzo",
				en: "Price",
			},
			priceCongress: {
				it: "Prezzo congresso ",
				en: "Congress price ",
			},
			priceEvent: {
				it: "Prezzo evento ",
				en: "Event price ",
			},
			vat: {
				it: "iva",
				en: "vat",
			},
			tipoBiglietto: {
				it: "Quota di iscrizione",
				en: "Registration fee",
			},
			name: {
				it: "Nome",
				en: "Name",
			},
			service: {
				it: "Servizi",
				en: "Services",
			},
			typeService: {
				it: "Tipo di servizio",
				en: "Type of service",
			},
			actions: {
				it: "Azioni",
				en: "Actions",
			},
			serviceAddedd: {
				it: "Non sono presenti servizi aggiuntivi",
				en: "There are no additional services present",
			},
			start: {
				it: "Inizio",
				en: "Start",
			},
			end: {
				it: "Fine",
				en: "End",
			},
			startDate: {
				it: "Data inizio:",
				en: "Start date:",
			},
			endDate: {
				it: "Data fine:",
				en: "End date:",
			},
			buttonEmpty: {
				it: "Svuota",
				en: "Empties",
			},
			buttonModify: {
				it: "Modifica",
				en: "Edit",
			},
			additionaNotesCart: {
				it: "Note aggiuntive da te inserite",
				en: "Additional notes you included",
			},
			noDisponibile: {
				it: "Non disponibile",
				en: "Not available",
			},
			vatExempt: {
				it: "esente iva",
				en: "vat exempt",
			},
			vatIncl: {
				it: "iva incl",
				en: "vat incl",
			},
			condVend: {
				it: "Ho letto e accetto le",
				en: "I have read and I accept the",
			},
			condVendSecond: {
				it: "condizioni di vendita",
				en: "terms and conditions of sale",
			},
			clausole: {
				it: "Dichiaro di accettare espressamente le clausole contenute ai seguenti articoli: 3.7, 3.8, 3.9 (Obblighi del Cliente), 6 (Responsabilità del Fornitore), 7.1 (Recesso), 7.2 (Annullamento), 8 (Risoluzione), 10.1 (Lingua prevalente), 10.2 (Legge applicabile), 10.3 (Foro competente)",
				en: "I declare that I expressly accept the clauses contained in the following articles: 3.7, 3.8, 3.9 (Customer's Obligations), 6 (Supplier's Liability), 7.1 (Withdrawal), 7.2 (Cancellation), 8 (Termination), 10.1 (Prevailing Language), 10.2 (Applicable Law), 10.3 (Jurisdiction)",
			},
			backHome: {
				it: "Torna alla home",
				en: "Back to home",
			},
			closeCartTitle: {
				it: "Attenzione!",
				en: "Attention!",
			},
			from: {
				it: "Dal",
				en: "From",
			},
			at: {
				it: "al",
				en: "at",
			},
			time: {
				it: "Orario",
				en: "Schedule",
			},
			total: {
				it: "Totale",
				en: "Total",
			},
			data: {
				it: "Data",
				en: "Date",
			},
			selectAgents: {
				it: "Seleziona l'agente:",
				en: "Select agent:",
			},
			notBuy: {
				it: "LIMITE RAGGIUNTO, BIGLIETTO NON ACQUISTABILE",
				en: "LIMIT REACHED, TICKET CANNOT BE PURCHASED",
			},
			additionalNotes: {
				it: "Inserisci note aggiuntive o informazioni che vuoi dare agli organizzatori",
				en: "Enter additional notes or information you want to give to organizers",
			},
			buttonConferma: {
				it: "Conferma",
				en: "Confirm",
			},
			buttonBack: {
				it: "Annulla scelte",
				en: "Cancel choices",
			},
			attention: {
				it: "Attenzione",
				en: "Attention",
			},
			allegati: {
				it: "Allegati",
				en: "Program",
			},
		},
		portfolio: {
			allEventsCongress: {
				it: "Tutti gli eventi e congressi",
				en: "All events and congresses",
			},
			congress: {
				it: "Congressi",
				en: "Congresses",
			},
			events: {
				it: "Eventi",
				en: "Events",
			},
			noPortfoil: {
				it: "Al momento non sono presenti eventi o congressi del passato nel catalogo.",
				en: "There are currently no past events or conferences in the catalog.",
			},
			card_congress: {
				it: "Congresso",
				en: "Congress",
			},
			card_events: {
				it: "Evento",
				en: "Event",
			},
		},
		userArea: {
			userArea: {
				it: "Area Utente",
				en: "User area",
			},
			biography: {
				it: "La mia anagrafica",
				en: "My biography",
			},
			registrationEventsCongress: {
				it: "Iscrizioni eventi e congressi",
				en: "Events and conference registration",
			},
			storicoIscrizioni: {
				it: "Storico iscrizioni",
				en: "Enrollment history",
			},
		},
		activeRegistration: {
			title: {
				it: "Iscrizioni eventi e congressi",
				en: "Event and conference registrations",
			},
			congress: {
				it: "Congressi a cui sei iscritto",
				en: "Congresses in which you are registered",
			},
			events: {
				it: "Eventi a cui sei iscritto",
				en: "Events in which you are registered",
			},
			notSubscription: {
				it: "Non sei iscritto a nessun evento o congresso",
				en: "You are not registered for any events or conferences",
			},
		},
		archiveRegistration: {
			title: {
				it: "Hai partecipato:",
				en: "You participated in:",
			},
			agents: {
				it: "Agenti",
				en: "Agents",
			},
			details: {
				it: "Dettagli",
				en: "Details",
			},
			titleModal: {
				it: "Titolo",
				en: "Title",
			},
			streetModal: {
				it: "Via",
				en: "Street",
			},
			cityModal: {
				it: "Città",
				en: "City",
			},
			noRegistration: {
				it: "Non hai ancora effettuato nessuna iscrizione ad un evento in passato.",
				en: "You have not yet registered for an event in the past.",
			},
			noRegistrationCongress: {
				it: "Non hai ancora effettuato nessuna iscrizione ad un congresso in passato.",
				en: "You have not yet registered for a conference in the past.",
			},
			services: {
				it: "Servizi legati",
				en: "Related services",
			},
			free: {
				it: "Gratis",
				en: "Free",
			},
		},
		userRegistry: {
			tooltip: {
				it: "Questo campo verrà utilizzato come utente per l'acesso all'area privata.",
				en: "This field will be used as the user for access to the private area.",
			},
			accountPrivate: {
				it: "Account privato",
				en: "Private account",
			},
			email: {
				it: "Email",
				en: "Email",
			},
			emailPlaceholder: {
				it: "Inserisci la tua email.",
				en: "Enter your email.",
			},
			name: {
				it: "Nome",
				en: "Name",
			},
			namePlaceholder: {
				it: "Insersci il tuo nome.",
				en: "Insert your name.",
			},
			surname: {
				it: "Cognome",
				en: "Surname",
			},
			surnamePlaceholder: {
				it: "Insersci il tuo cognome.",
				en: "Insert your surname.",
			},
			phone: {
				it: "Numero di telefono",
				en: "Phone number",
			},
			phonePlaceholder: {
				it: "Insersci il tuo numero di telefono.",
				en: "Enter your phone number.",
			},
			companyName: {
				it: "Ragione sociale",
				en: "Company name",
			},
			companyNamePlaceholder: {
				it: "Inserisci la ragione sociale.",
				en: "Enter company name.",
			},
			vatNumber: {
				it: "P.IVA",
				en: "VAT number",
			},
			vatNumberPlaceholder: {
				it: "Inserisci la partita iva.",
				en: "Enter VAT number.",
			},
			birtday: {
				it: "Data di nascita",
				en: "Date of birth",
			},
			birtdayPlaceholder: {
				it: "Inserisci la tua data di nascita.",
				en: "Enter your date of birth.",
			},
			luogoNascita: {
				it: "Luogo di nascita",
				en: "Place of birth",
			},
			luogoNascitaPlaceholder: {
				it: "Inserisci il tuo luogo di nascita.",
				en: "Enter your place of birth.",
			},
			fiscalCode: {
				it: "Codice fiscale",
				en: "Tax code",
			},
			fiscalCodePlaceholder: {
				it: "Inserisci il tuo codice fiscale.",
				en: "Enter your tax code.",
			},
			fiscalCodeCompany: {
				it: "Codice fiscale azienda",
				en: "Company tax code",
			},
			fiscalCodeCompanyPlaceholder: {
				it: "Inserisci il codice fiscale aziendale.",
				en: "Enter the company tax code.",
			},
			indirizzo: {
				it: "Indirizzo",
				en: "Address",
			},
			indirizzoPlaceholder: {
				it: "Inserisci il tuo indirizzo.",
				en: "Enter your address.",
			},
			city: {
				it: "Città",
				en: "City",
			},
			cityPlaceholder: {
				it: "Inserisci la città.",
				en: "Enter the city.",
			},
			province: {
				it: "Provincia",
				en: "Province",
			},
			provincePlaceholder: {
				it: "Inserisci la provincia.",
				en: "Enter the province.",
			},
			cap: {
				it: "Cap",
				en: "Cap",
			},
			capPlaceholder: {
				it: "Inserisci il cap.",
				en: "Enter cap.",
			},
			publicAdministration: {
				it: "Questo account fattura alla pubblica amministrazione.",
				en: "This account invoices the public administration.",
			},
			uniqueCode: {
				it: "Codice univoco",
				en: "Unique code",
			},
			uniqueCodePlaceholder: {
				it: "Inserisci il codice univoco.",
				en: "Enter the unique code.",
			},
			pec: {
				it: "PEC",
				en: "PEC",
			},
			pecPlaceholder: {
				it: "Inserisci la pec.",
				en: "Enter the PEC.",
			},
			buttonModify: {
				it: "Modifica",
				en: "Edit",
			},
			buttonBack: {
				it: "Annulla",
				en: "Cancel",
			},
			buttonConfirmEdit: {
				it: "Conferma modifiche",
				en: "Confirm changes",
			},
			buttonDeleteAccount: {
				it: "Elimina Account",
				en: "Delete account",
			},
			errorReg: {
				it: "Errore",
				en: "Error",
			},
			infoModalActions: {
				it: "Modifiche effetuate",
				en: "Changes effected",
			},

			infoModal: {
				it: "Hai completato con successo le modifiche",
				en: "You have successfully completed the changes",
			},
			businessAccount: {
				it: "Account aziendale",
				en: "Business account",
			},
			userDelete: {
				it: "Il tuo account è stato eliminato con successo",
				en: "Your account has been successfully deleted",
			},
		},
		register: {
			accountPrivate: {
				it: "Privato",
				en: "Private account",
			},
			accountCompany: {
				it: "Azienda",
				en: "Company",
			},
			privateRegister: {
				it: "Registrazione privata",
				en: "Private registration",
			},
			companyRegister: {
				it: "Registrazione aziendale",
				en: "Company  registration",
			},
			publicAdministrationText: {
				it: "Fatturazione alla pubblica ammnistrazione? Se si, spunta la casella corrente",
				en: "Invoicing to public administration ? If yes, check the current box",
			},

			email: {
				it: "Email",
				en: "Email",
			},
			emailPlaceholder: {
				it: "Inserisci la tua email.",
				en: "Enter your email.",
			},
			name: {
				it: "Nome",
				en: "Name",
			},
			namePlaceholder: {
				it: "Insersci il tuo nome.",
				en: "Insert your name.",
			},
			surname: {
				it: "Cognome",
				en: "Surname",
			},
			surnamePlaceholder: {
				it: "Insersci il tuo cognome.",
				en: "Insert your surname.",
			},
			phone: {
				it: "Numero di telefono",
				en: "Phone number",
			},
			phonePlaceholder: {
				it: "Insersci il tuo numero di telefono.",
				en: "Enter your phone number.",
			},
			companyName: {
				it: "Ragione sociale",
				en: "Company name",
			},
			companyNamePlaceholder: {
				it: "Inserisci la ragione sociale.",
				en: "Enter company name.",
			},
			vatNumber: {
				it: "P.IVA",
				en: "VAT number",
			},
			vatNumberPlaceholder: {
				it: "Inserisci la partita iva.",
				en: "Enter VAT number.",
			},
			birtday: {
				it: "Data di nascita",
				en: "Date of birth",
			},
			birtdayPlaceholder: {
				it: "Inserisci la tua data di nascita.",
				en: "Enter your date of birth.",
			},
			luogoNascita: {
				it: "Luogo di nascita",
				en: "Place of birth",
			},
			luogoNascitaPlaceholder: {
				it: "Inserisci il tuo luogo di nascita.",
				en: "Enter your place of birth.",
			},
			fiscalCode: {
				it: "Codice fiscale",
				en: "Tax code",
			},
			fiscalCodePlaceholder: {
				it: "Inserisci il tuo codice fiscale.",
				en: "Enter your tax code.",
			},
			fiscalCodeCompany: {
				it: "Codice fiscale azienda",
				en: "Company tax code",
			},
			fiscalCodeCompanyPlaceholder: {
				it: "Inserisci il codice fiscale aziendale.",
				en: "Enter the company tax code.",
			},
			indirizzo: {
				it: "Indirizzo",
				en: "Address",
			},
			indirizzoPlaceholder: {
				it: "Inserisci il tuo indirizzo.",
				en: "Enter your address.",
			},
			city: {
				it: "Città",
				en: "City",
			},
			cityPlaceholder: {
				it: "Inserisci la città.",
				en: "Enter the city.",
			},
			province: {
				it: "Provincia",
				en: "Province",
			},
			provincePlaceholder: {
				it: "Inserisci la provincia.",
				en: "Enter the province.",
			},
			cap: {
				it: "Cap",
				en: "Cap",
			},
			capPlaceholder: {
				it: "Inserisci il cap.",
				en: "Enter cap.",
			},
			passwordPlaceholder: {
				it: "Inserisci la password.",
				en: "Enter the password.",
			},
			passwordConfirm: {
				it: "Conferma password",
				en: "Confirm Password",
			},
			passwordConfirmPlaceholder: {
				it: "Reinserisci la tua password.",
				en: "Re-enter your password.",
			},
			publicAdministration: {
				it: "Questo account fattura alla pubblica amministrazione.",
				en: "This account invoices the public administration.",
			},
			uniqueCode: {
				it: "Codice univoco",
				en: "Unique code",
			},
			uniqueCodePlaceholder: {
				it: "Inserisci il codice univoco.",
				en: "Enter the unique code.",
			},
			pec: {
				it: "PEC",
				en: "PEC",
			},
			pecPlaceholder: {
				it: "Inserisci la pec.",
				en: "Enter the PEC.",
			},
			Nation: {
				it: "Stato",
				en: "Country",
			},
			selectNation: {
				it: "Seleziona stato",
				en: "Select country",
			},
			medicalSpecialty: {
				it: "Specialità medica",
				en: "Medical specialty",
			},
			selectMedicalSpecialty: {
				it: "Inserisci specialità medica",
				en: "Insert medical specialty",
			},
			buttonIscriviti: {
				it: "Iscriviti",
				en: "Sign up",
			},
			privacy: {
				it: "Ho preso visione dell’",
				en: "I have taken note of the",
			},
			privacySecond: {
				it: "informativa privacy",
				en: "privacy policy",
			},
			privacyDate: {
				it: "e acconsento al trattamento dei miei dati.",
				en: "and consent to the processing of my data.",
			},

			errorReg: {
				it: "Errore",
				en: "Error",
			},
			infoModalActions: {
				it: "Modifiche effetuate",
				en: "Changes effected",
			},

			infoModal: {
				it: "Hai completato con successo le modifiche",
				en: "You have successfully completed the changes",
			},
			businessAccount: {
				it: "Account aziendale",
				en: "Business account",
			},
			confirmReg: {
				it: "Grazie per la registrazione!",
				en: "Thanks for the registration!",
			},
			confirmRegFirst: {
				it: "Per favore controlla la tua email",
				en: "Please check your email ",
			},
			confirmRegSecond: {
				it: "per l'attivazione del tuo account.",
				en: "to activate your account.",
			},
			confirmRegForth: {
				it: "Se l'email non fosse recapitata entro pochi minuti, controllate la posta indesiderata o spam. Se non vedete l'email nella vostra casella di posta, controllate la cartella della posta indesiderata e aggiungete iscrizioni@soluzioniomniamedia.com alla vostra White List o Safe Sender List. ",
				en: "If you do not receive the email in a few minutes, check your “junk mail” folder or “spam” folder. If you do not receive the email in your inbox, please check your junk mail folder and add “iscrizioni@soluzioniomniamedia.com” to your White List or Safe Sender List. ",
			},
			confirmRegThird: {
				it: "Qualche dubbio?",
				en: "Any questions?",
			},
			confirmRegContact: {
				it: "Contattaci",
				en: "Contact us",
			},
			confirmRegContactSecond: {
				it: "altrimenti",
				en: "or else",
			},
			confirmRegContactHomepage: {
				it: "Continua in homepage",
				en: "Continued on home page",
			},
			errorEmail: {
				it: "Formato dell'email non corretto",
				en: "Incorrect format of the email",
			},
			errorFiscalCode: {
				it: "Formato del codice fiscale non valido.",
				en: "Invalid tax code format.",
			},
			errorFiscalCodeBlank: {
				it: "Il codice fiscale è un campo obbligatorio.",
				en: "The tax code is a required field.",
			},
			errorPiva: {
				it: "Formato della partita iva non valido.",
				en: "Invalid vat number format.",
			},
			errorPassword: {
				it: "Le password non coincidono! Ritentare.",
				en: "Passwords do not match! Retry.",
			},
			errorCompany: {
				it: "Almeno uno dei campi codice univoco o pec devono essere compilati.",
				en: "At least one of the unique code or pec fields must be filled in.",
			},
		},
		loginPage: {
			paymentRequest: {
				it: "Per procedere al pagamento è necessario avere un account.",
				en: "An account is required to proceed with payment.",
			},
			accountCompany: {
				it: "Azienda",
				en: "Company",
			},

			email: {
				it: "Email",
				en: "Email",
			},
			emailPlaceholder: {
				it: "Inserisci la tua email.",
				en: "Enter your email.",
			},
			passwordPlaceholder: {
				it: "Inserisci la password.",
				en: "Enter the password.",
			},
			buttonEntra: {
				it: "Entra",
				en: "Log in",
			},
			notRegister: {
				it: "Non sei registrato?",
				en: "Not registered?",
			},
			registrati: {
				it: "Registrati",
				en: "Sign up",
			},
			pwdDimenticata: {
				it: "Password dimenticata?",
				en: "Forgotten password?",
			},
			welcome: {
				it: "Benvenuto in soluzioni!",
				en: "Welcome to soluzioni!",
			},
			welcomeArea: {
				it: "Accedi all'area privata",
				en: "Login to private area",
			},
			recuperoPasswordTitle: {
				it: "Recupero password",
				en: "Password recovery",
			},
			recuperoPasswordDesc: {
				it: "Inserisci la tua email. Riceverai un codice sulla tua casella di posta per la configurazione della nuova password.",
				en: "Enter your email. You will receive a code in your inbox for configuring your new password.",
			},
			buttonConferma: {
				it: "Conferma",
				en: "Confirm",
			},
			successChangePwd: {
				it: "Password cambiata con successo!",
				en: "Password changed successfully!",
			},
			operationCompleted: {
				it: "Operazione completata",
				en: "Operation completed",
			},
			goToLogin: {
				it: "Vai al login",
				en: "Go to login",
			},
			changePassword: {
				it: "Cambio password",
				en: "Change password",
			},
			changePasswordDesc: {
				it: "Controlla la tua email e inserisci il tuo codice di verifica.",
				en: "Check your email and enter your verification code.",
			},
			code: {
				it: "Codice di verifica",
				en: "Code verification",
			},
			newPassword: {
				it: "Nuova password",
				en: "New password",
			},
		},

		readMore: {
			programm: {
				it: "Programma",
				en: "Schedule",
			},
			maximumCapacityReached: {
				it: "Raggiunta capienza massima",
				en: "Maximum capacity reached",
			},
			subscribeCongress: {
				it: "Iscriviti al congresso",
				en: "Sign up for this conference",
			},
			subscribeEvent: {
				it: "Iscriviti all'evento",
				en: "Sign up for the event",
			},
			freeMembership: {
				it: "Iscrizione gratuita",
				en: "Free registration",
			},
			freeMembershipEvent: {
				it: "Evento a libera entrata",
				en: "Free-entry event",
			},
			alreadySubscribeCongress: {
				it: "Già iscritto a questo congresso",
				en: "You are already registered for this congress",
			},
			alreadySubscribeEvent: {
				it: "Già iscritto a questo evento",
				en: "You are already registered for this event",
			},
		},

		choosePayment: {
			chooseMethod: {
				it: "Scegli il metodo di pagamento",
				en: "Choose the payment method",
			},
			advancePayment: {
				it: "Bonifico anticipato",
				en: "Advance wire transfer",
			},
			advancePaymentExplain: {
				it: "Pagamento con bonifico anticipato che verrà confermato una volta avvenuta la transazione con una email di notifica",
				en: "Registration will only be valid upon receipt of the full payment according to the deadline indicated. An email confirming registration will only be sent after receipt of the required fees",
			},
			payTransfer: {
				it: "Paga con bonifico",
				en: "Pay by bank transfer",
			},
			paypalLabel: {
				it: "Paypal / Carta di Credito",
				en: "Paypal / Credit Card",
			},
			chooseAnotherPaymentMethod: {
				it: "Scegli un'altro metodo di pagamento",
				en: "Choose another payment method",
			},
			bankTransfer: {
				it: "Bonifico Bancario",
				en: "Bank Transfer",
			},
			detailPayment: {
				it: "Dettagli per il pagamento",
				en: "Payment details",
			},
			amountToPay: {
				it: "Importo da versare :",
				en: "Amount to pay : ",
			},
			copyIban: {
				it: "Copia IBAN",
				en: "Copy IBAN",
			},
			placeOrder: {
				it: "Esegui Ordine",
				en: "Place Order",
			},
			exitPaypalMessage: {
				it: "Sei uscito dal pagamento paypal",
				en: "You have exited the Paypal payment",
			},
			or: {
				it: "Oppure",
				en: "Or",
			},
			warningTitle: {
				it: "Attenzione",
				en: "Warning",
			},
			goToHome: {
				it: "Vai alla home",
				en: "Go to the home page",
			},
			goToLogin: {
				it: "Vai alla home",
				en: "Go to the home page",
			},
			accepConditionModal: {
				it: "Accettare le condizioni di vendita e le clausole sottoposte a doppia sottoscrizione",
				en: "Accept the terms of sale and clauses subject to double subscription",
			},
			creditCardLabel: {
				it: "Pagamento disponibile anche con carta di credito, senza account necessario",
				en: "Registration will only be valid upon receipt of the full payment according to the deadline indicated. An email confirming registration will only be sent after receipt of the required fees.",
			},
			signUpFree: {
				it: "Premi per iscriverti gratuitamente",
				en: "Rewards for signing up for free",
			},
		},

		successPage: {
			successMessage: {
				it: "Hai completato il tuo ordine con successo!",
				en: "You have successfully completed your order",
			},
			reservationMade: {
				it: "Prenotazione effettuata per",
				en: "Reservation made for ",
			},
			paypalOrderCode: {
				it: "Codice paypal ordine",
				en: "Paypal order code",
			},
			reservationCode: {
				it: "Codice prenotazione ",
				en: "Reservation Code ",
			},
			informationLabel: {
				it: "Ecco alcune informazioni utili",
				en: "Here is some helpful information",
			},
			messageMail: {
				it: "Riceverai una mail con il riepilogo dell'ordine e del pagamento",
				en: "You will receive an email with a summary of your order and payment",
			},
			messageMailSecondPaypalOrFree: {
				it: "Nella mail è disponibile il biglietto con il codice QR per l'accesso al servizio acquistato ",
				en: "The ticket with the QR code for access to the purchased service is available in the email",
			},
			messageMailSecondTransfer: {
				it: "Una volta che il pagamento verrà convalidato, verrà inviata una email con il biglietto contenente il qrcode per l'accesso al servizio acquistato",
				en: "Once the payment is validated, an email will be sent with the ticket containing the qrcode for access to the purchased service",
			},
			thankyouMessage: {
				it: "Grazie dal team soluzioni!",
				en: "Thank you from Soluzioni team!",
			},
			codeOrder: {
				it: "Codice ordine :",
				en: "Order code : ",
			},
		},
		title: {
			home: {
				it: "Home - Soluzioni Omnia Media",
				en: "Home - Soluzioni Omnia Media",
			},
			success: {
				it: "Prenotazione effettuata - Soluzioni Omnia media",
				en: "Reservation made - Soluzioni Omnia Media",
			},
			userRegistry: {
				it: "La mia anagrafica - Soluzioni Omnia media",
				en: "My biography - Soluzioni Omnia Media",
			},
			userArea: {
				it: "Area utente - Soluzioni Omnia media",
				en: "User area - Soluzioni Omnia Media",
			},
			cart: {
				it: "Carrello - Soluzioni Omnia media",
				en: "Cart - Soluzioni Omnia Media",
			},
			cookiePolicy: {
				it: "Cookie Policy - Soluzioni Omnia media",
				en: "Cookie Policy - Soluzioni Omnia Media",
			},
			privacyPolicy: {
				it: "Privacy Policy - Soluzioni Omnia media",
				en: "Privacy Policy - Soluzioni Omnia Media",
			},
			TermsOfSale: {
				it: "Condizioni di vendita - Soluzioni Omnia media",
				en: "Terms of sale - Soluzioni Omnia Media",
			},
			TermsOfUse: {
				it: "Condizioni di utilizzo - Soluzioni Omnia media",
				en: "Terms of use - Soluzioni Omnia Media",
			},
			registration: {
				it: "Registrazioni - Soluzioni Omnia media",
				en: "Sign up - Soluzioni Omnia Media",
			},
			login: {
				it: "Login - Soluzioni Omnia media",
				en: "Sign in - Soluzioni Omnia Media",
			},
			eventAndCongressRegistration: {
				it: "Iscrizioni eventi e congressi - Soluzioni Omnia media",
				en: "Event and conference registrations - Soluzioni Omnia Media",
			},
			enrollmentHistory: {
				it: "Storico congressi ed eventi - Soluzioni Omnia media",
				en: "Conference and event history - Soluzioni Omnia Media",
			},
			activeAccount: {
				it: "Account attivato - Soluzioni Omnia media",
				en: "Account activated - Soluzioni Omnia Media",
			},
			serviceAdded: {
				it: "Servizi aggiuntivi - Soluzioni Omnia media",
				en: "Additional services - Soluzioni Omnia Media",
			},
			unathorized: {
				it: "Accesso riservato - Soluzioni Omnia media",
				en: "401 Unauthorized- Soluzioni Omnia Media",
			},
		},
		activeAccount: {
			activeAccount: {
				it: "Registrazione avvenuta con successo!",
				en: "Successfully registered!",
			},
			labelaccount: {
				it: "Il tuo account è stato attivato correttamente.",
				en: "Your account has been successfully activated.",
			},
			buttonLogin: {
				it: "Clicca qui per andare al login.",
				en: "Click here to go to the login.",
			},
		},

		toastMessage: {
			iban_copy: {
				it: "Iban copiato con successo!",
				en: "Iban successfully copied",
			},
			logout: {
				it: "Logout eseguito con successo!",
				en: "Successfully logged out",
			},
			addToCart: {
				it: "aggiunto al carrello!",
				en: "added to cart",
			},
			welcome: {
				it: "Benvenuto",
				en: "Welcome",
			},
			emptyCart: {
				it: "Carrello svuotato",
				en: "Empty cart",
			},
		},

		infoModal: {
			logoutTitle: {
				it: "Attenzione",
				en: "Warning",
			},
			logoutMessage: {
				it: "Vuoi eseguire il logout",
				en: "Do you want to logout?",
			},
			confirm: {
				it: "Conferma",
				en: "Confirm",
			},
			cancel: {
				it: "Annulla",
				en: "Cancel",
			},
		},

		errorLabel: {
			errorPagelabel: {
				it: "Ci dispiace, ma la pagina cercata non esiste!",
				en: "Sorry, but the page you are looking for does not exist!",
			},
			comeBackHome: {
				it: "Torna alla home!",
				en: "Return to home!",
			},
			doLogin: {
				it: "É necessario effettuare il login per effettuare un pagamento",
				en: "You must login to make a payment",
			},
			noSelectCongress: {
				it: "Non è stato selezionato alcun congresso/evento",
				en: "No congresses/events selected",
			},
			noAuth: {
				it: "Non sei autenticato per poter eseguire l'ordine",
				en: "You are not authenticated to submit your order",
			},
			serverError: {
				it: "Errore di comunicazione con il server",
				en: "Communication error with the server",
			},
			errorPaymentInfo: {
				it: "Errore nel recupero dei dati di pagamento",
				en: "Error in retrieving payment data",
			},
			acceptRecaptcha: {
				it: "Accettare il recaptcha per poter eseguire la form di contatto",
				en: "Accept recaptcha in order to run the contact form",
			},
			recaptchaExpired: {
				it: "É necessario riaccettare il checkbox recaptcha, token scaduto",
				en: "You need to re-accept recaptcha checkbox, token expired",
			},
			logoutError: {
				it: "Errore in fase di logout, sarai reindirizzato alla homepage",
				en: "Error on logout, you will be redirected to the homepage",
			},
			noDescription: {
				it: "Nessuna descrizione",
				en: "No description",
			},
			selectTicket: {
				it: "Devi selezionare la quota di iscrizione",
				en: "You have to select a registration fee",
			},
			deleteUser: {
				it: "Sei sicuro di voler eliminare il tuo utente, l'operazione è irreversibile",
				en: "Are you sure you want to delete your user, the operation is irreversible",
			},
			label_unauthorized: {
				it: "Accesso non autorizzato, torna alla home",
				en: "Unauthorized, return to home page ",
			},
			error_unauthorized: {
				it: "ERRORE 401, NON AUTORIZZATO",
				en: "ERROR 401, UNAUTHORIZED ",
			},
		},

		errorPwLabel: {
			weak: {
				it: "debole",
				en: "weak",
			},
			average: {
				it: "medio",
				en: "average",
			},
			good: {
				it: "buona",
				en: "good",
			},
			strong: {
				it: "forte",
				en: "strong",
			},
			tooWeak: {
				it: "troppo debole",
				en: "too weak",
			},
		},
	},
};

export default Globals;
