import React, {useEffect, useState, useContext, useMemo} from "react";
import CardHome from "../components/CardHome";
import Officina from "../components/Officina";
import SoccorsoNew from "../components/SoccorsoNew";
import ChiSiamo from "../components/ChiSiamo";
import Contact from "../components/Contact";
import LavoraConNoi from "../components/LavoraConNoi";
import Opinione from "../components/Opinione";
import axios from "axios";
import Globals from "../parameters/globals";
import CarouselCard from "../components/CarouselCard";
// import Noleggio from "../components/Noleggio";
import Loading from "../components/Loading";
import SEO from "../components/Seo";
import CardCar from "../components/CardCar";
import Banner from "../components/Banner";
import NoleggioNew from "../components/NoleggioNew";
import AuthContext from "../context/AuthProvider";

function Homepage() {
	const {auth} = useContext(AuthContext);

	const [cardHomepage, setCardHomepage] = useState([]);
	const [officinaArticoli, setOfficinaArticoli] = useState([]);
	const [assistenzaArticoli, setAssistenzaArticoli] = useState([]);
	const [serviziArticoli, setServiziArticoli] = useState([]);
	const [veicoli, setVeicoli] = useState([]);
	const [loading, setLoading] = useState(true);

	const [chiSiamo, setChiSiamo] = useState("");
	const [banner, setBanner] = useState({
		title: "",
		testo: "",
		src: "",
		button_link: "",
		button_text: "",
		show: false,
	});

	const memoizedGetHomeElement = useMemo(() => {
		return async () => {
			setLoading(true);

			try {
				const response = await axios.get(Globals.GETHOME, {
					withCredentials: true,
				});

				if (response?.data?.error === true) {
					setLoading(true);
				} else {
					let data = response?.data;
					setCardHomepage(data.card_homepage || []);
					setOfficinaArticoli(data.officina_carrozzeria || []);
					setVeicoli(data.veicoli || []);
					setAssistenzaArticoli(data.assistenza || []);
					setServiziArticoli(data.to_move || []);
					setChiSiamo(data.chi_siamo);
					setLoading(false);

					let banner_id = parseInt(localStorage.getItem("banner_id")) || null;

					setBanner({
						title: data?.novita?.titolo,
						text: data?.novita?.descrizione,
						src: data?.novita?.src,
						show: banner_id === parseInt(data?.novita?.id) ? false : true,
						button_text: data?.novita?.testo_bottone,
						button_link: data?.novita?.link,
						id: data?.novita?.id || null,
					});
				}
			} catch (err) {
				console.log(err);
			}
		};
	}, []);

	useEffect(() => {
		memoizedGetHomeElement();
	}, [memoizedGetHomeElement]);

	if (loading === true) {
		return <Loading />;
	} else {
		return (
			<div>
				<SEO
					title={"Officina, carrozzeria e vendita auto | Lepanto 2 "}
					description={
						"Lepanto 2 srl si occupa di tutti i servizi del mondo automobilistico dalla vendita, officina e soccorso 24h"
					}
					keyword={"Verona servizi automobile"}
				/>
				<CardHome data={cardHomepage} />
				{veicoli.length !== 0 && <CarouselCard data={veicoli} />}
				{/* <CardCar data={veicoli}/> */}
				{/* <Officina data={officinaArticoli} /> */}
				<Officina data={officinaArticoli}/>
				{/* <Soccorso data={assistenzaArticoli} /> */}
				<SoccorsoNew data={assistenzaArticoli} />

				
				{/* <Noleggio data={serviziArticoli} /> */}
				<NoleggioNew data={serviziArticoli}></NoleggioNew>
				<ChiSiamo data={chiSiamo} />
				{/* <DoveSiamo/> */}
				<Contact general={true} />
				<LavoraConNoi />
				{/* <AppLepanto /> */}
				<Opinione />
				{banner.id !== null && (
					<Banner
						show={banner.show}
						title={banner.title}
						text={banner.text}
						src={banner.src}
						button_link={banner.button_link}
						button_text={banner.button_text}
						id={banner.id}
					/>
				)}
			</div>
		);
	}
}

export default Homepage;
