import React, { useState, useEffect, useContext } from "react";
import "../scss/opinione.scss";
import { Card, Modal, Table } from "react-bootstrap";
import Carousel from "react-grid-carousel";
import Contact from "../components/Contact";
import user from "../images/user.png";
import axios from "axios";
import Globals from "../parameters/globals";
import Loading from "../components/Loading";
import StarsRating from "react-star-rate";
import FormSondaggio from "./FormSondaggio";
import useWindowSize from "use-window-size-v2";

function Opinione() {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShowOpinion, setModalShowOpinion] = React.useState(false);

  const [loading, setLoading] = useState(true);
  const [messaggi, setMessaggi] = useState([]);
  const [mediaAccoglienza, setMediaAccoglienza] = useState([]);
  const [mediaVendita, setMediaVendita] = useState([]);
  const [mediaSoccorso, setMediaSoccorso] = useState([]);
  const [mediaOfficina, setMediaOfficina] = useState([]);
  const [mediaTotale, setMediaTotale] = useState([]);

  const [totaleSondaggi, setTotaleSondaggi] = useState([]);
  const { width, height } = useWindowSize(100); // wait 100ms for the resize events

  const getAboutReview = async () => {
    setLoading(true);

    try {
      const response = await axios.get(Globals.GET_ABOUT, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });

      if (response?.data?.error === true) {
        console.log("err");
        setLoading(true);
      } else {
        let data = response?.data;
        setMessaggi(data.lista_messaggi || []);
        setMediaAccoglienza(data.media_accoglienza || []);
        setMediaVendita(data.media_vendita || []);
        setMediaSoccorso(data.media_soccorso || []);
        setMediaOfficina(data.media_officina_carrozzeria || []);
        setTotaleSondaggi(data.totale_sondaggi || []);
        setMediaTotale(data.media_totale || []);
        setLoading(false);
      }
    } catch (err) {
      console(err);
    }
  };
  // const LeftArrowGallery = ({ isActive }) => (
  //   <button
  //     className="btn btn-light sc-bwzfXH kVtMtA border-0"
  //     // style={{ backgroundColor: "#003187", opacity: 1, height: "50px" }}
  //     style={
  //       //  { backgroundColor: "#003187", opacity: 1, height: "50px" }
  //       {
  //         border: "1px solid #003187",
  //         backgroundColor: "#eee",
  //         color: "#003187",
  //         opacity: 1,
  //         height: "50px",
  //       }
  //     } // disabled={isFirstItemVisible}
  //   >
  //     <i className="fa fa-arrow-left"></i>
  //   </button>
  // );

  // const LeftRightGallery = ({ isActive }) => (
  //   <button
  //     className="btn btn-light sc-bwzfXH bhRnqO border-0"
  //     // style={{ backgroundColor: "#003187", opacity: 1, height: "50px" }}
  //     style={{
  //       border: "1px solid #003187",
  //       backgroundColor: "#eee",
  //       color: "#003187",
  //       opacity: 1,
  //       height: "50px",
  //     }} // disabled={isFirstItemVisible}
  //   >
  //     <i className="fa fa-arrow-right"></i>
  //   </button>
  // );

  useEffect(() => {
    getAboutReview();
  }, []);
  if (loading === true) {
    return <Loading />;
  } else {
    return (
      <div style={{ backgroundColor: "#f4f4f4" }} id="opinione">
        <div className="py-3 container-fluid">
          <div>
            <h2 className="divider donotcross" contenteditable>
              {" "}
              La tua opinione
            </h2>

            <div className="py-3">
              <div className="container col-12">
                <div className="row d-flex ">
                  <div className="col-xl-6 col-md-12">
                    <div className="py-0">
                      {" "}
                      <h3 style={{ fontWeight: 400 }}>Aiutaci a migliorare!</h3>
                      <span className="devider bg-info d-inline-block my-3"></span>
                      <p>
                        Dedicaci 5 minuti del tuo tempo per compilare il nostro
                        sondaggio. La tua opinione è importante. Grazie!
                      </p>
                      <div className="text-center my-4">
                        <button
                          onClick={() => setModalShow(true)}
                          className="btn btn-primary"
                          style={{ backgroundColor: "#003187" }}
                        >
                          <i className="fa fa-star mx-2"></i>Compila il
                          sondaggio
                        </button>
                      </div>
                      <div className="container-fluid my-4">
                        <div className="card-stars p-4 my-3 responsive-card-opinione">
                          <div>
                            <h3 className={width > 600 ? "" : "text-center"}>
                              Dicono di noi
                            </h3>
                          </div>
                          <div
                            className={
                              width > 600
                                ? "d-flex justify-content-between align-items-center "
                                : "d-inline text-center my-2 "
                            }
                          >
                            <div className="ratings mb-3">
                              {/* <i className="fa fa-star rating-color"></i>
                              <i className="fa fa-star rating-color"></i>
                              <i className="fa fa-star rating-color"></i>
                              <i className="fa fa-star rating-color"></i>
                              <i className="fa fa-star half-star"></i> */}
                              <StarsRating
                                value={mediaTotale}
                                disabled
                                onChange={(mediaTotale) => {
                                  setMediaTotale(mediaTotale);
                                }}
                              />
                            </div>
                            <h5 className="review-count mb-3">
                              {totaleSondaggi} Sondaggi
                            </h5>
                          </div>

                          <div
                            className={
                              width > 600
                                ? " d-flex justify-content-between align-items-center "
                                : " d-inline text-center my-2 "
                            }
                          >
                            <h5 className="review-stat">
                              Servizio di accoglienza
                            </h5>
                            <div className="small-ratings">
                              {/* <span className="mx-1" style={{ fontSize: "17px" }}>
                                ({mediaAccoglienza})
                              </span> */}
                              <StarsRating
                                value={mediaAccoglienza}
                                disabled
                                onChange={(mediaAccoglienza) => {
                                  setMediaAccoglienza(mediaAccoglienza);
                                }}
                              />

                              {/* <i className="fa fa-star rating-color"></i>
                              <i className="fa fa-star rating-color"></i>
                              <i className="fa fa-star rating-color"></i>
                              <i className="fa fa-star half-star"></i>
                              <i className="fa fa-star"></i> */}
                            </div>
                          </div>

                          <div
                            className={
                              width > 600
                                ? "d-flex justify-content-between align-items-center "
                                : "d-inline text-center my-2 "
                            }
                          >
                            <h5 className="review-stat">Vendita auto</h5>
                            <div className="small-ratings">
                              {/* <span  className="mx-1" style={{ fontSize: "17px" }}>
                                ({mediaVendita})
                              </span> */}
                              <StarsRating
                                value={mediaVendita}
                                disabled
                                onChange={(mediaVendita) => {
                                  setMediaVendita(mediaVendita);
                                }}
                                style={{ fontSize: "10px" }}
                              />

                              {/* <i className="fa fa-star rating-color"></i>
                              <i className="fa fa-star rating-color"></i>
                              <i className="fa fa-star rating-color"></i>
                              <i className="fa fa-star rating-color"></i>
                              <i className="fa fa-star rating-color"></i> */}
                            </div>
                          </div>

                          <div
                            className={
                              width > 600
                                ? "d-flex justify-content-between align-items-center "
                                : "d-inline text-center my-2 "
                            }
                          >
                            <h5 className="review-stat">
                              Officina / carrozzeria
                            </h5>
                            <div className="small-ratings">
                              {/* <span className="mx-1" style={{ fontSize: "17px" }}>
                                ({mediaOfficina})
                              </span> */}
                              <StarsRating
                                value={mediaOfficina}
                                disabled
                                onChange={(mediaOfficina) => {
                                  setMediaOfficina(mediaOfficina);
                                }}
                                style={{ fontSize: "10px" }}
                              />

                              {/* <i className="fa fa-star rating-color"></i>
                              <i className="fa fa-star rating-color"></i>
                              <i className="fa fa-star rating-color"></i>
                              <i className="fa fa-star rating-color"></i>
                              <i className="fa fa-star"></i> */}
                            </div>
                          </div>
                          <div
                            className={
                              width > 600
                                ? "d-flex justify-content-between align-items-center "
                                : "d-inline text-center my-2 "
                            }
                          >
                            <h5 className="review-stat">Soccorso stradale</h5>
                            <div className="small-ratings">
                              {/* <span className="mx-1" style={{ fontSize: "17px" }}>
                                ({mediaSoccorso})
                              </span> */}
                              <StarsRating
                                value={mediaSoccorso}
                                disabled
                                onChange={(mediaSoccorso) => {
                                  setMediaSoccorso(mediaSoccorso);
                                }}
                                style={{ fontSize: "10px" }}
                              />

                              {/* <i className="fa fa-star rating-color"></i>
                              <i className="fa fa-star rating-color"></i>
                              <i className="fa fa-star rating-color"></i>
                              <i className="fa fa-star half-star"></i>
                              <i className="fa fa-star"></i> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p></p>
                  </div>
                  <div className="col-xl-6 col-md-12">
                    <h3 className="text-center" style={{ fontWeight: 400 }}>
                      Le opinioni dei nostri clienti
                    </h3>

                    <Carousel
                      cols={1}
                      rows={1}
                      gap={10}
                      loop={true}
                      autoplay={5000}
                      showDots={false}
                      hideArrow={true}
                      scrollSnap={true}
                      // arrowLeft={LeftArrowGallery}
                      // arrowRight={LeftRightGallery}

                      // shouldResetAutoplay={true}
                      // responsive={multi}
                    >
                      {messaggi.map((item) => {
                        return (
                          <Carousel.Item>
                            <div className="testimonial9 py-5 bg-light">
                              {" "}
                              <div className="item">
                                <div className="card-testimonial card-shadow border-0 mb-4 position-relative">
                                  <div className="p-4">
                                    <h5 style={{fontWeight:400}}>
                                      {item.messaggio}
                                    </h5>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center mx-3">
                                  <div className="customer-thumb mx-2">
                                    <img
                                      src={user}
                                      alt="wrapkit"
                                      className="rounded-circle mr-3"
                                    />
                                  </div>
                                  <div className="">
                                    <h6 className="font-weight-bold mb-0">
                                      {item.nome}
                                    </h6>
                                    <span className="font-13">Utente</span>
                                  </div>
                                </div>
                              </div>{" "}
                            </div>
                          </Carousel.Item>
                        );
                      })}
                      {/* <Carousel.Item>
                        <div className="testimonial9 py-5 bg-light">
                          {" "}
                          <div className="item">
                            <div className="card-testimonial card-shadow border-0 mb-4 position-relative">
                              <div className="p-4">
                                <h5 className="font-weight-light">
                                  WrapKit has given our websites huge national
                                  presence. We are #1 on page one in Google
                                  search
                                </h5>
                              </div>
                            </div>
                            <div className="d-flex align-items-center mx-3">
                              <div className="customer-thumb mx-2">
                                <img
                                  src={user}
                                  alt="wrapkit"
                                  className="rounded-circle mr-3"
                                />
                              </div>
                              <div className="">
                                <h6 className="font-weight-bold mb-0">
                                  Michael Anderson
                                </h6>
                                <span className="font-13">Project client</span>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                      </Carousel.Item>
                      <Carousel.Item>
                        <div className="testimonial9 py-5 bg-light">
                          {" "}
                          <div className="item">
                            <div className="card-testimonial card-shadow border-0 mb-4 position-relative">
                              <div className="p-4">
                                <h5 className="font-weight-light">
                                  WrapKit has given our websites huge national
                                  presence. We are #1 on page one in Google
                                  search results for every website we’ve built,
                                  and rank for more keywords than I ever
                                  expected in a very competitive, high-value
                                  customer industry. In addition,
                                </h5>
                              </div>
                            </div>
                            <div className="d-flex align-items-center mx-3">
                              <div className="customer-thumb mx-2">
                                <img
                                  src={user}
                                  alt="wrapkit"
                                  className="rounded-circle mr-3"
                                />
                              </div>
                              <div className="">
                                <h6 className="font-weight-bold mb-0">
                                  Michael Anderson
                                </h6>
                                <span className="font-13">Project client</span>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                      </Carousel.Item> */}
                    </Carousel>
                    <div className="text-center">
                      <button
                        onClick={() => setModalShowOpinion(true)}
                        className="btn btn-primary"
                        style={{ backgroundColor: "#003187" }}
                      >
                        <i className="fa fa-comment mx-2"></i>Visualizza tutte
                        le opinioni
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal
              show={modalShowOpinion}
              style={{ zIndex: 9999 }}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() => setModalShowOpinion(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  Tutte le opinioni
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {messaggi.map((item) => {
                  return (
                    <div className="testimonial9 py-5 bg-light">
                      {" "}
                      <div className="item">
                        <div className="card-testimonial card-shadow border-0 mb-4 position-relative">
                          <div className="p-4">
                            <h5 style={{fontWeight:400}}>
                              {item.messaggio}
                            </h5>
                          </div>
                        </div>
                        <div
                          className={
                            width > 1300
                              ? "d-flex align-items-center mx-3"
                              : "d-inline text-center mx-auto"
                          }
                        >
                          <div className="col-xl-3 col-lg-12 text-center">
                            <div className="customer-thumb mx-auto ">
                              <img
                                src={user}
                                alt="wrapkit"
                                className="rounded-circle mr-3"
                              />
                            </div>
                            <div>
                              <h6 className="font-weight-bold mb-0">
                                {item.nome}
                              </h6>
                              <span className="font-13">Utente</span>
                            </div>
                          </div>

                          <div className="col-xl-9 col-lg-12">
                            <Card className="p-2">
                              {parseInt(item.servito_vendita) === 1 ? (
                                <div>
                                  <div>
                                    <h4
                                      className={
                                        width > 600 ? "" : "text-center"
                                      }
                                    >
                                      Servizio Vendita auto
                                    </h4>
                                  </div>
                                  <div
                                    className={
                                      width > 600
                                        ? " d-flex justify-content-between align-items-center "
                                        : " d-inline text-center my-2 "
                                    }
                                  >
                                    <h5 className="review-stat">
                                      Servizio di accoglienza
                                    </h5>
                                    <div className="small-ratings">
                                      {/* <span className="mx-1" style={{ fontSize: "17px" }}>
                                ({mediaAccoglienza})
                              </span> */}
                                      <StarsRating
                                        value={parseFloat(
                                          item.servizio_accoglienza
                                        )}
                                        disabled
                                      />

                                      {/* <i className="fa fa-star rating-color"></i>
                              <i className="fa fa-star rating-color"></i>
                              <i className="fa fa-star rating-color"></i>
                              <i className="fa fa-star half-star"></i>
                              <i className="fa fa-star"></i> */}
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      width > 600
                                        ? "d-flex justify-content-between align-items-center "
                                        : "d-inline text-center my-2 "
                                    }
                                  >
                                    <h5 className="review-stat">
                                      Qualità / prezzo
                                    </h5>
                                    <div className="small-ratings">
                                      <StarsRating
                                        value={parseFloat(item.qualita_prezzo)}
                                        disabled
                                        style={{ fontSize: "10px" }}
                                      />
                                    </div>
                                  </div>

                                  <div
                                    className={
                                      width > 600
                                        ? "d-flex justify-content-between align-items-center "
                                        : "d-inline text-center my-2 "
                                    }
                                  >
                                    <h5 className="review-stat">
                                      Soddisfazione esigenze
                                    </h5>
                                    <div className="small-ratings">
                                      <StarsRating
                                        value={parseFloat(
                                          item.soddisfazione_esigenze
                                        )}
                                        disabled
                                        style={{ fontSize: "10px" }}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      width > 600
                                        ? "d-flex justify-content-between align-items-center "
                                        : "d-inline text-center my-2 "
                                    }
                                  >
                                    <h5 className="review-stat">
                                      Tempi e modalità
                                    </h5>
                                    <div className="small-ratings">
                                      <StarsRating
                                        value={parseFloat(item.tempi_modalita)}
                                        disabled
                                        style={{ fontSize: "10px" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : parseInt(
                                  item.servito_officina_carrozzeria
                                ) === 1 ? (
                                <div>
                                  <div>
                                    <h4
                                      className={
                                        width > 600 ? "" : "text-center"
                                      }
                                    >
                                      Servizio Officina / Carrozzeria
                                    </h4>
                                  </div>
                                  <div
                                    className={
                                      width > 600
                                        ? " d-flex justify-content-between align-items-center "
                                        : " d-inline text-center my-2 "
                                    }
                                  >
                                    <h5 className="review-stat">
                                      Servizio di accoglienza
                                    </h5>
                                    <div className="small-ratings">
                                      <StarsRating
                                        value={parseFloat(
                                          item.servizio_accoglienza
                                        )}
                                        disabled
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      width > 600
                                        ? "d-flex justify-content-between align-items-center "
                                        : "d-inline text-center my-2 "
                                    }
                                  >
                                    <h5 className="review-stat">
                                      Operatori service
                                    </h5>
                                    <div className="small-ratings">
                                      <StarsRating
                                        value={parseFloat(
                                          item.operatori_service
                                        )}
                                        disabled
                                        style={{ fontSize: "10px" }}
                                      />
                                    </div>
                                  </div>

                                  <div
                                    className={
                                      width > 600
                                        ? "d-flex justify-content-between align-items-center "
                                        : "d-inline text-center my-2 "
                                    }
                                  >
                                    <h5 className="review-stat">
                                      Preventivazione e gestione
                                    </h5>
                                    <div className="small-ratings">
                                      <StarsRating
                                        value={parseFloat(
                                          item.preventivazione_gestione
                                        )}
                                        disabled
                                        style={{ fontSize: "10px" }}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      width > 600
                                        ? "d-flex justify-content-between align-items-center "
                                        : "d-inline text-center my-2 "
                                    }
                                  >
                                    <h5 className="review-stat">
                                      Tempi e qualità
                                    </h5>
                                    <div className="small-ratings">
                                      <StarsRating
                                        value={parseFloat(item.tempi_qualita)}
                                        disabled
                                        style={{ fontSize: "10px" }}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      width > 600
                                        ? "d-flex justify-content-between align-items-center "
                                        : "d-inline text-center my-2 "
                                    }
                                  >
                                    <h5 className="review-stat">
                                      Valutazione servizi offerti
                                    </h5>
                                    <div className="small-ratings">
                                      <StarsRating
                                        value={parseFloat(
                                          item.valutazione_servizi
                                        )}
                                        disabled
                                        style={{ fontSize: "10px" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  <div>
                                    <h4
                                      className={
                                        width > 600 ? "" : "text-center"
                                      }
                                    >
                                      Servizio Soccorso
                                    </h4>
                                  </div>
                                  <div
                                    className={
                                      width > 600
                                        ? " d-flex justify-content-between align-items-center "
                                        : " d-inline text-center my-2 "
                                    }
                                  >
                                    <h5 className="review-stat">
                                      Servizio di accoglienza
                                    </h5>
                                    <div className="small-ratings">
                                      <StarsRating
                                        value={parseFloat(
                                          item.servizio_accoglienza
                                        )}
                                        disabled
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      width > 600
                                        ? "d-flex justify-content-between align-items-center "
                                        : "d-inline text-center my-2 "
                                    }
                                  >
                                    <h5 className="review-stat">
                                      Valutazione operatori pronto soccorso
                                    </h5>
                                    <div className="small-ratings">
                                      {/* <span  className="mx-1" style={{ fontSize: "17px" }}>
                                ({mediaVendita})
                              </span> */}
                                      <StarsRating
                                        value={parseFloat(
                                          item.operatori_intervento
                                        )}
                                        disabled
                                        style={{ fontSize: "10px" }}
                                      />
                                    </div>
                                  </div>

                                  <div
                                    className={
                                      width > 600
                                        ? "d-flex justify-content-between align-items-center "
                                        : "d-inline text-center my-2 "
                                    }
                                  >
                                    <h5 className="review-stat">
                                      Tempi di attesa
                                    </h5>
                                    <div className="small-ratings">
                                      <StarsRating
                                        value={parseFloat(
                                          item.tempi_attesa_intervento
                                        )}
                                        disabled
                                        style={{ fontSize: "10px" }}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      width > 600
                                        ? "d-flex justify-content-between align-items-center "
                                        : "d-inline text-center my-2 "
                                    }
                                  >
                                    <h5 className="review-stat">
                                      Capacità di gestione
                                    </h5>
                                    <div className="small-ratings">
                                      <StarsRating
                                        value={parseFloat(
                                          item.capacita_gestione_emergenza
                                        )}
                                        disabled
                                        style={{ fontSize: "10px" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Card>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                  );
                })}
              </Modal.Body>
            </Modal>
            <Modal
              show={modalShow}
              size="lg"
              style={{ zIndex: 9999 }}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() => setModalShow(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  Sondaggio
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormSondaggio general={true} />
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

export default Opinione;
