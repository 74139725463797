import axios from "axios";
import React, {useState, useEffect, useRef} from "react";
import Loading from "../components/Loading";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Globals from "../parameters/globals";
import "../scss/contact.scss";
import {useParams} from "react-router-dom";

import {
	EReCaptchaV2Size,
	EReCaptchaV2Theme,
	ReCaptchaV2,
} from "react-recaptcha-x";
import {useContext} from "react";
import AuthContext from "../context/AuthProvider";

function Formpreventivo(props) {
	let {id} = useParams();
	let {type} = useParams();

	const [name, setname] = useState("");
	const [surname, setsurname] = useState("");
	const [email, setemail] = useState("");
	const [phone, setphone] = useState("");
	const [IsCompany, setIsCompany] = useState(false);
	const [message, setmessage] = useState(
		type === "test-drive" ? "Sono interessato al test drive." : "",
	);
	const [privacy, setPrivacy] = useState(false);
	const [newsletter, setNewsletter] = useState(false);
	const [is_robot, set_is_robot] = useState(false);
	const [is_robot_general, set_is_robot_general] = useState(false);
	const [token, setToken] = useState("");
	const [loading, setLoading] = useState(false);

	const [success, setSuccess] = useState(false);
	const [errMsg, setErrMsg] = useState("");

	const [schedule, setSchedule] = useState([]);
	const {lang} = useContext(AuthContext);

	// const getMapsAndSchedule = async () => {
	// 	try {
	// 		const response = await axios.get(Globals.GETWORKSCHEDULE + "&lang=" + lang);
	// 		setSchedule(response.data.work_schedule);
	// 	} catch (err) {
	// 		console.log(err);
	// 	}
	// };

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (token === "") {
			setErrMsg(Globals.language.errorLabel.acceptRecaptcha[lang]);
			return false;
		}

		try {
			setLoading(true);
			let body = {
				id: id,
				nome: name,
				cognome: surname,
				email: email,
				cellulare: phone,
				// IsCompany: IsCompany === "true" ? true : false,
				messaggio: message,
				privacy: privacy,
				newsletter: newsletter,
				token: token,
			};
			let formdata = new FormData();

			formdata.append("body", JSON.stringify(body));

			var response = await axios.postForm(
				Globals.POST_PREVENTIVO + "&lang=" + lang,
				formdata,
				{
					headers: {"Content-Type": "multipart/form-data"},
					withCredentials: true,
				},
			);

			// TODO: remove console.logs before deployment
			// console.log(JSON.stringify(response?.data));
			//console.log(JSON.stringify(response))

			if (response?.data?.error === true) {
				setErrMsg(response.data.message);

				setLoading(false);
				setSuccess(false);
			} else {
				//clear state and controlled inputs
				setLoading(false);
				setSuccess(true);
			}
		} catch (err) {
			// console.log(err);
			setLoading(false);
			setSuccess(false);
			setErrMsg(Globals.language.errorLabel.serverError[lang]);
		}
	};

	// useEffect(() => {
	// if (location.pathname === "/") {
	// 	set_is_robot_general(true);
	// } else {
	// 	set_is_robot(true);
	// }

	// if (props.general === true) {
	// 	getMapsAndSchedule();
	// }
	// return () => {
	// 	set_is_robot(false);
	// 	set_is_robot_general(false);
	// };
	// }, [lang]);

	const v2Callback = (token) => {
		if (typeof token === "string") {
			setToken(token);
		} else if (typeof token === "boolean" && !token) {
			setErrMsg(Globals.language.errorLabel.recaptchaExpired[lang]);
			setToken("");
		} else if (token instanceof Error) {
			setErrMsg(Globals.language.errorLabel.serverError[lang]);
			setToken("");
		}
	};

	if (props.general) {
		return (
			<>
				{loading ? (
					<Loading />
				) : success ? (
					<div className='p-2'>
						{" "}
						<Card style={{fontSize: "25px", textAlign: "center"}}>
							<i
								className='fa  fa-check-circle'
								style={{fontSize: "50px", color: "green"}}></i>
							<h2>{Globals.language.contacts.sendSuccess[lang]}</h2>
							<div>{Globals.language.contacts.sendTextSuccess[lang]}</div>
						</Card>
					</div>
				) : (
					<Form
						onSubmit={(e) => {
							handleSubmit(e);
						}}>
						<div className='d-inline '>
							<Form.Group className='mb-1 col-12 ' controlId='formBasicEmail'>
								{/* <Form.Label>{Globals.language.contacts.nameForm[lang]}</Form.Label> */}
								<Form.Control
									type='name'
									placeholder="Nome"
									value={name}
									onChange={(e) => setname(e.target.value)}
									required
								/>
							</Form.Group>
							<Form.Group className='mb-1 col-12' controlId='formBasicEmail'>
								{/* <Form.Label>{Globals.language.contacts.surnameForm[lang]}</Form.Label> */}
								<Form.Control
									type='surname'
									placeholder="Cognome"
									value={surname}
									onChange={(e) => setsurname(e.target.value)}
									required
								/>
							</Form.Group>
						</div>
						<div className='d-inline '>
							<Form.Group className='mb-1 col-12 '>
								{/* <Form.Label>{Globals.language.contacts.emailForm[lang]}</Form.Label> */}
								<Form.Control
									type='email'
									placeholder="Email"
									value={email}
									onChange={(e) => setemail(e.target.value)}
									required
								/>
							</Form.Group>
							<Form.Group className='mb-1 col-12 ' controlId='formBasicEmail'>
								{/* <Form.Label>{Globals.language.contacts.phoneForm[lang]}</Form.Label> */}
								<Form.Control
									type='phone'
									placeholder="Cellulare"
									value={phone}
									onChange={(e) => setphone(e.target.value)}
									required
								/>
							</Form.Group>
						</div>
						<Form.Group className='mb-2' controlId='formBasicPassword'>
							{/* <Form.Label>{Globals.language.contacts.messaggeForm[lang]}</Form.Label> */}
							<Form.Control
								type='text'
								placeholder="Messaggio"
								as='textarea'
								value={message}
								onChange={(e) => setmessage(e.target.value)}
								required
							/>
						</Form.Group>
						<Form.Group className='mb-3' controlId='formBasicCheckbox'>
							<Form.Check
								type='checkbox'
								label={Globals.language.contacts.privacyForm[lang]}
								onChange={(e) =>
									e.target.value === "on" ? setPrivacy(true) : setPrivacy(false)
								}
								required
							/>
						</Form.Group>
						{/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label={Globals.language.contacts.newsletterForm[lang]}
                  onChange={(e) =>
                    e.target.value === "on"
                      ? setNewsletter(true)
                      : setNewsletter(false)
                  }
                />
              </Form.Group> */}

						<Form.Group className='position-relative' controlId='formBasicCheckbox'>
							<ReCaptchaV2
								callback={v2Callback}
								theme={EReCaptchaV2Theme.Light}
								size={EReCaptchaV2Size.Normal}
								id='my-id'
								data-test-id='my-test-id'
								tabindex={0}
							/>
						</Form.Group>

						<Button
							className='d-flex justify-content-center m-auto my-2'
							style={{
								backgroundColor: "#003187",
								border: "1px solid #003187",
							}}
							type='submit'>
							Invia{" "}
						</Button>
						{errMsg && <p> {errMsg} </p>}
					</Form>
				)}
			</>
		);
	}
}
export default Formpreventivo;
