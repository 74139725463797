import React, { useState, useEffect, useContext } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Soccorso from "../images/soccorso.jpg";
import "../scss/cardcar.scss";
import Card from "react-bootstrap/Card";
import Contact from "./Contact";
function CardCar(props) {
  const [modalShow, setModalShow] = React.useState(false);
  const { data } = props;
  return (
    <div style={{ backgroundColor: "#f4f4f4" }} id="officina">
      <div className="py-3 container-fluid">
        <div>
          <h2 className="divider donotcross" contenteditable>
            Scelte per voi
          </h2>

          {/* <h1>Officina e carrozeria </h1>
          <p className="title-fascia-officina"></p> */}
          <div className="container-fluid">
            <ul className="cards">
              {data.map((item) => {
                return (
                  <li className="card">
                    <Card className="d-flex align-items-stretch">
                      <Card.Img variant="top" src={item.src} />
                      <Card.Body className="card-body">
                        <div className="card-body d-flex flex-column">
                          <Card.Title className="card-title">
                            <div className="d-flex">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item.brand_description,
                                }}
                              />{" "}
                              -
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item.model_description,
                                }}
                              />
                            </div>
                          </Card.Title>
                          <Card.Text className="card-text mb-4">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item.version_description,
                              }}
                            />
                          </Card.Text>
                          <Card.Text className="card-text text-center text-danger">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item.private_price,
                              }}
                            />
                          </Card.Text>
                          <Button
                            className="btn-datail mt-auto align-item-bottom"
                            onClick={() => setModalShow(true)}
                          >
                            Prenota ora
                          </Button>
                        </div>
                      </Card.Body>
                    </Card>
                  </li>
                );
              })}
            </ul>
          </div>
          <Modal
            show={modalShow}
            onHide={() => setModalShow(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Prenotazione servizio officina
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Contact />
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => setModalShow(false)}>Chiudi</Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default CardCar;
