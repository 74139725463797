import React from "react";
import Globals from "../parameters/globals";
import SEO from "../components/Seo";

function MyLepanto() {
	return (
		<div
			className='container-fluid position-relative'
			style={{
				position: "relative",
				top: "130px",
				width: "100%",
				height: "950px",
				overflow: "hidden",
			}}>
			<SEO
				title={" My Lepanto | Lepanto 2 "}
				description={
					"Scopri tutti i servizi che la APP di lepanto gestisci scadenze, consulta il nostro catalogo ed altro ancora "
				}
				keyword={" my lepanto "}
				noIndex={true}

			/>
			<iframe
				className='iframe'
				style={{
					position: "relative",
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					width: "100%",
					minHeight: "800px",
					height: "auto",
					border: "none",
					margin: 0,
					padding: 0,
				}}
				src={Globals.MylepantoUrl}
				title='Area Admin'></iframe>
		</div>
	);
}

export default MyLepanto;
