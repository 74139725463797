import React, {useState, useEffect, useContext} from "react";
import axios from "axios";
import Globals from "../parameters/globals";
import GeneralContext from "../context/AuthProvider";
import SEO from "../components/Seo";

function PrivacyPolicy() {
	const [error, setError] = useState(false);
	const [message, setMessage] = useState("");
	const [privacyPolicy, setPrivacyPolicy] = useState([]);
	const {lang} = useContext(GeneralContext);

	useEffect(() => {
		getPageLegal();
	}, [lang]);

	const getPageLegal = async () => {
		setError(false);
		setMessage("");

		try {
			const response = await axios.get(Globals.GETLEGALPAGE, {
				headers: {"Content-Type": "application/json"},
				withCredentials: true,
			});

			if (response?.data?.error === true) {
				setError(true);
				setMessage(response?.data?.error);
			} else {
				window.scrollTo(0, 0);
				setPrivacyPolicy(
					lang === "it"
						? response?.data?.privacy.htmlcontents_it
						: lang === "en"
						? response?.data?.privacy.htmlcontents_en
						: response?.data?.privacy.htmlcontents_si,
				);
			}
		} catch (err) {
			setError(true);
			setMessage(err);
		}
	};

	return (
		<>
			{error ? (
				<section className='text-center'>
					{" "}
					<h2> {message}</h2>
				</section>
			) : (
				<div
					className='container'
					style={{marginTop: "140px", marginBottom: "100px"}}>
					<SEO
						title={" Note Legali | Lepanto 2 "}
						description={
							" Tutti i diritti sul materiale contenuto nel sito sono riservati a Lepanto 2  "
						}
						keyword={" note legali "}
						noIndex={true}

					/>
					{/* <Helmet>
						<meta charSet='utf-8' />
						<link rel='canonical' href='https://www.gravner.it/privacy' />
					</Helmet> */}
					<div dangerouslySetInnerHTML={{__html: privacyPolicy}} />
				</div>
			)}
		</>
	);
}

export default PrivacyPolicy;
