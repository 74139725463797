import React, {useState, useEffect, useContext} from "react";
import axios from "axios";
import Globals from "../parameters/globals";
// import { Helmet } from "react-helmet";
import AuthContext from "../context/AuthProvider";
import SEO from "../components/Seo";

function CondizioniUtilizzo() {
	const [error, setError] = useState(false);
	const [message, setMessage] = useState("");
	const [condizioniUtilizzo, setCondizioniUtilizzo] = useState([]);
	const {lang} = useContext(AuthContext);

	useEffect(() => {
		getCondizioniUtilizzo();
	}, [lang]);

	const getCondizioniUtilizzo = async () => {
		setError(false);
		setMessage("");

		try {
			const response = await axios.get(Globals.GETCONDIZIONIUTILIZZO, {
				headers: {"Content-Type": "application/json"},
				withCredentials: true,
			});

			if (response?.data?.error === true) {
				setError(true);
				setMessage(response?.data?.error);
			} else {
				window.scrollTo(0, 0);
				setCondizioniUtilizzo(
					lang === "it"
						? response?.data?.condizioni_utilizzo.htmlcontents_it
						: lang === "en"
						? response?.data?.condizioni_utilizzo.htmlcontents_en
						: response?.data?.condizioni_utilizzo.htmlcontents_si,
				);
			}
		} catch (err) {
			setError(true);
			setMessage(err);
		}
	};

	return (
		<>
			{error ? (
				<section className='text-center'>
					{" "}
					<h2> {message}</h2>
				</section>
			) : (
				<div
					className='container'
					style={{marginTop: "140px", marginBottom: "100px", minHeight: "600px"}}>
					<SEO
						title={"  Condizioni d'utilizzo | Lepanto 2  "}
						description={
							"  Questo sito appartiene a Lepanto 2 srl, l'utilizzo di questo sito comporta l'accettazione termini e condizioni  "
						}
						keyword={"   condizioni utilizzo   "}
						noIndex={true}

					/>

					<div dangerouslySetInnerHTML={{__html: condizioniUtilizzo}} />
				</div>
			)}
		</>
	);
}

export default CondizioniUtilizzo;
