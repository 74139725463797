import React, {useState} from "react";
import "../scss/Banner.css";
import {Modal, Card, Button} from "react-bootstrap";
import img1 from "../images/1.jpg";

const Banner = ({text, src, title, show, button_text, button_link, id}) => {
	const [active, setActive] = useState(show);

	const saveOnLocalStorageOnClose = () => {
		localStorage.setItem("banner_id", id);
		setActive(false);
	};

	return (
		<Modal
			show={active}
			text={text}
			onHide={() => saveOnLocalStorageOnClose()}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered>
			<Modal.Header closeButton></Modal.Header>
			<Card className='text-center'>
				<Card.Img variant='top' src={src} />
				<Card.Body>
					<Card.Title>{title} </Card.Title>
					<Card.Text>{text}</Card.Text>
					{/* <Button variant='primary'>{button_text}</Button> */}
				</Card.Body>
			</Card>
		</Modal>
	);
};

export default Banner;
