import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Globals from "../parameters/globals";
// import { Helmet } from "react-helmet";
import AuthContext from "../context/AuthProvider";

import SEO from "../components/Seo";
function CondizioniAcquisto() {
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [condizioniAcquisto, setCondizioniAcquisto] = useState([]);
  const { lang } = useContext(AuthContext);

  useEffect(() => {
    getCondizioniAcquisto();
  }, [lang]);

  const getCondizioniAcquisto = async () => {
    setError(false);
    setMessage("");

    try {
      const response = await axios.get(Globals.GETCONDIZIONIACQUISTO, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });

      if (response?.data?.error === true) {
        setError(true);
        setMessage(response?.data?.error);
      } else {
        window.scrollTo(0, 0);
        setCondizioniAcquisto(
          lang === "it"
            ? response?.data?.condizioni_acquisto.htmlcontents_it
            : lang === "en"
            ? response?.data?.condizioni_acquisto.htmlcontents_en
            : response?.data?.condizioni_acquisto.htmlcontents_si
        );
      }
    } catch (err) {
      setError(true);
      setMessage(err);
    }
  };

  return (
    <>
      {error ? (
        <section className="text-center">
          {" "}
          <h2> {message}</h2>
        </section>
      ) : (
        <div
          className="container"
          style={{ marginTop: "140px", marginBottom: "100px" }}
        >
          <SEO
            title={"   Condizioni generali d'acquisto  | Lepanto 2  "}
            description={
              "  Le Condizioni Generali, unitamente alle Condizioni Speciali, formano il contratto di vendita   "
            }
            keyword={"    condizioni vendita   "}
            noIndex={true}
          />
          <div dangerouslySetInnerHTML={{ __html: condizioniAcquisto }} />
        </div>
      )}
    </>
  );
}

export default CondizioniAcquisto;
