import React, { useState, useEffect } from "react";
// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import "../scss/carouselcard.scss";
import Carousels from "react-bootstrap/Carousel";
import useWindowSize from "use-window-size-v2";
import Carousel from "react-grid-carousel";
import { useNavigate } from "react-router-dom";
import { RightArrow } from "./Officina";
const numberFormat = (value) =>
new Intl.NumberFormat('it-IT', {
  // style: 'currency',
  currency: 'EUR'
}).format(value);

const CarouselCard = (props) => {
  const { data } = props;
  const [deviceType, setDeviceType] = useState("");
  const { width, height } = useWindowSize(100); // wait 100ms for the resize events
  const navigate = useNavigate();

  const MyDot = ({ isActive }) => (
    <span
      style={{
        display: "inline-block",
        height: isActive ? "8px" : "5px",
        width: isActive ? "8px" : "5px",
        background: "#003187",
        borderRadius: "50%",
      }}
    ></span>
  );

  const LeftArrow = ({}) => (
    <button
      className="btn btn-light text-light sc-bwzfXH kVtMtA border-0"
      // style={{ backgroundColor: "#003187", opacity: 1, height: "50px" }}
      style={
        data.length <= 4 && width > 1300
          ? { opacity: 0 }
          : { backgroundColor: "#003187", opacity: 1, height: "50px" }
      } // disabled={isFirstItemVisible}
    >
      <i className="fa fa-arrow-left"></i>
    </button>
  );

  const LeftRight = ({}) => (
    <button
      className="btn btn-light text-light sc-bwzfXH bhRnqO border-0"
      // style={{ backgroundColor: "#003187", opacity: 1, height: "50px" }}
      style={
        data.length <= 4 && width > 1300
          ? { opacity: 0 }
          : { backgroundColor: "#003187", opacity: 1, height: "50px" }
      } // disabled={isFirstItemVisible}
    >
      <i className="fa fa-arrow-right"></i>
    </button>
  );

  const LeftArrowGallery = ({ isActive }) => (
    <button
      className="btn btn-light sc-bwzfXH kVtMtA border-0"
      // style={{ backgroundColor: "#003187", opacity: 1, height: "50px" }}
      style={
        //  { backgroundColor: "#003187", opacity: 1, height: "50px" }
        {
          border: "1px solid #003187",
          backgroundColor: "#eee",
          color: "#003187",
          opacity: 1,
          height: "50px",
        }
      } // disabled={isFirstItemVisible}
    >
      <i className="fa fa-arrow-left"></i>
    </button>
  );

  const LeftRightGallery = ({ isActive }) => (
    <button
      className="btn btn-light sc-bwzfXH bhRnqO border-0"
      // style={{ backgroundColor: "#003187", opacity: 1, height: "50px" }}
      style={{
        border: "1px solid #003187",
        backgroundColor: "#eee",
        color: "#003187",
        opacity: 1,
        height: "50px",
      }} // disabled={isFirstItemVisible}
    >
      <i className="fa fa-arrow-right"></i>
    </button>
  );

  // const [windowDimenion, detectHW] = useState({
  //   winWidth: window.innerWidth,
  //   winHeight: window.innerHeight,
  // });

  // const detectSize = () => {
  //   detectHW({
  //     winWidth: window.innerWidth,
  //     winHeight: window.innerHeight,
  //   });
  // };
  // useEffect(() => {
  //     if(windowDimenion < 464){
  //     setDeviceType("mobile")
  //   }
  //   window.addEventListener("resize", detectSize);

  //   return () => {
  //     window.removeEventListener("resize", detectSize);
  //   };
  // }, [windowDimenion]);

  useEffect(() => {
    if (width < 464) {
      setDeviceType("mobile");
    } else if (width > 464 && width < 1024) {
      setDeviceType("tablet");
    } else {
      setDeviceType("desktop");
    }
  }, [width]);

  const responsive = [
    {
      breakpoint: 750,
      cols: 1,
      rows: 1,
      gap: 10,
      loop: true,
      autoplay: 4500,
      scrollSnap: true,
    },
    {
      breakpoint: 1000,
      cols: 1,
      rows: 1,
      gap: 10,
      loop: true,
      autoplay: 4500,
      scrollSnap: false,
    },
    {
      breakpoint: 1300,
      cols: 1,
      rows: 1,
      gap: 10,
      loop: true,
      autoplay: 4500,
      scrollSnap: false,
    },
    {
      breakpoint: 500,
      cols: 1,
      rows: 1,
      gap: 10,
      loop: true,
      autoplay: 4500,
    },
  ];
  const responsiveImage = [
    {
      breakpoint: 750,
      cols: 1,
      rows: 1,
    },
    {
      breakpoint: 1000,
      cols: 1,
      rows: 1,
    },
    {
      breakpoint: 1300,
      cols: 1,
      rows: 1,
    },
    {
      breakpoint: 500,
      cols: 1,
      rows: 1,
    },
  ];

  //   const CustomRightArrow = ({ onClick, ...rest }) => {
  //     const {
  //       onMove,
  //       carouselState: { currentSlide, deviceType }
  //     } = rest;
  //     // onMove means if dragging or swiping in progress.
  //     return <button onClick={() => onClick()} />;
  //   };

  return (
    <div style={{ backgroundColor: "#f4f4f4" }}>
      <div className="py-3 container-fluid">
        <div>
          <h2 className="divider donotcross" contenteditable>
            Scelte per voi
          </h2>

          {/* <h1>Officina e carrozeria </h1>
          <p className="title-fascia-officina"></p> */}
          <div className="container-fluid">
            <Carousel
              responsiveLayout={responsive}
              cols={4}
              rows={1}
              gap={10}
              loop={true}
              autoplay={4500}
              dot={MyDot}
              scrollSnap={true}
              showDots={true}
              arrowLeft={LeftArrow}
              arrowRight={LeftRight}
              hideArrow={false}
              mobileBreakpoint={0}
              // onMove={false}
              // swipeable={true}
              // draggable={true}
              // showDots={true}
              // responsive={responsive}
              // ssr={true} // means to render carousel on server-side.
              // infinite={true}
              // // autoPlay={true}
              // // focusOnSelect={true}
              // shouldResetAutoplay={false}
              // minimumTouchDrag={80}
              // autoPlay={false}
              // autoPlaySpeed={3500}
              // centerMode={deviceType !== "mobile" ? true : false}
              // keyBoardControl={true}
              // customTransition="transform 1500ms ease-in-out"
              // transitionDuration={1500}
              // containerClass="react-multi-carousel-list"
              // // removeArrowOnDeviceType={["tablet", "mobile"]}
              // deviceType={deviceType}
              // dotListClass="custom-dot-list-style"
              // itemClass="carousel-item-padding-40-px"
              // sliderClass="react-multi-carousel-track"
              // additionalTransfrom={0}
            >
              {data.map((item) => {
                return (
                  <Carousel.Item>
                    <div className="mx-2 my-5">
                      {
                        //    <Card className="d-flex align-items-stretch">
                        //   <Card.Img variant="top" src={item.src} />
                        //   <Card.Body className="card-body-car">
                        //     <div className="d-flex flex-column">
                        //       <Card.Title className="card-title-car mx-auto mb-auto">
                        //         <div className="d-flex  mb-auto col-md-12">
                        //           <p
                        //             dangerouslySetInnerHTML={{
                        //               __html: item.brand_description,
                        //             }}
                        //           />{" "}
                        //           -
                        //           <p
                        //             dangerouslySetInnerHTML={{
                        //               __html: item.model_description,
                        //             }}
                        //           />
                        //         </div>
                        //       </Card.Title>
                        //       <Card.Text className="card-text-car mx-auto mb-auto">
                        //         <p
                        //           dangerouslySetInnerHTML={{
                        //             __html: item.version_description,
                        //           }}
                        //         />
                        //       </Card.Text>
                        //       <Card.Text className="card-text-car text-center text-danger price-car ">
                        //       <div className="d-flex justify-content-center">
                        //         <p
                        //           dangerouslySetInnerHTML={{
                        //             __html: item.private_price,
                        //           }}
                        //         />
                        //         <p>€</p>
                        //         </div>
                        //       </Card.Text>
                        //       <Button className="btn-datail mt-auto align-item-bottom">
                        //         Scopri di più
                        //       </Button>
                        //     </div>
                        //   </Card.Body>
                        // </Card>

                        // Seconda

                        <div className="card-car container">
                          <Carousel
                            mobileBreakpoint={0}
                            cols={1}
                            rows={1}
                            gap={10}
                            loop
                            arrowLeft={LeftArrowGallery}
                            arrowRight={LeftRightGallery}
                            // shouldResetAutoplay={true}
                            // responsive={multi}
                            showDots
                          >
                            {item.src.map((obj) => {
                              return (
                                <Carousel.Item>
                                  <img
                                    draggable={false}
                                    className="d-block w-100"
                                    src={obj.href_immagine}
                                    alt="images cars detail"
                                  />
                                </Carousel.Item>
                              );
                            })}
                          </Carousel>

                          <div className="card-car__title ">
                            <h2 className="title-card-car">
                              {item.brand_description} -{" "}
                              {item.model_description}{" "}
                            </h2>
                          </div>
                          {/* <div className="card-car__sub">
                          <small>New York</small>
                        </div> */}
                          <div className="card-car__desc flexible">
                            <large>{item.version_description}</large>
                          </div>
                          <div className="card-car__item text-center text-danger price-car">
                            <div className="d-flex justify-content-center">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: numberFormat(item.private_price),
                                }}
                              />
                              <p>€</p>
                            </div>
                          </div>
                          <div className="card-car__footer">
                            {/* <Button className="btn-datail w-100">
                              Scopri di più
                            </Button> */}
                            <Button
                              className="btn-datail w-100"
                              onClick={() =>
                                navigate(
                                  "/dettaglio-auto/" +
                                    item.id +
                                    "/" +
                                    item.brand_description +
                                    "/" +
                                    "dettaglio"
                                )
                              }
                            >
                              Scopri di più
                            </Button>
                          </div>
                        </div>
                      }
                    </div>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarouselCard;
