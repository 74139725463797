import axios from "axios";
import React, {useState, useEffect, useRef, useContext} from "react";
import Loading from "../components/Loading";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Globals from "../parameters/globals";
import AuthContext from "../context/AuthProvider";
import {useNavigate} from "react-router-dom";
import SEO from "../components/Seo";

import "../scss/contact.scss";

import {
	EReCaptchaV2Size,
	EReCaptchaV2Theme,
	ReCaptchaV2,
} from "react-recaptcha-x";

function Register() {
	const {lang, auth} = useContext(AuthContext);
	const navigate = useNavigate();
	const [name, setname] = useState("");
	const [surname, setsurname] = useState("");
	const [email, setemail] = useState("");
	const [phone, setphone] = useState("");

	//valori in caso di commerciante
	const [company, setcompany] = useState("");
	const [vatNumber, setVatNumber] = useState("");

	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const [isPrivate, setIsPrivate] = useState(true);
	const [privacy, setPrivacy] = useState(false);
	const [token, setToken] = useState("");
	const [loading, setLoading] = useState(false);

	const [success, setSuccess] = useState(false);
	const [errMsg, setErrMsg] = useState("");

	const handleSubmit = async (e, id) => {
		e.preventDefault();

		if (token === "") {
			setErrMsg(Globals.language.errorLabel.acceptRecaptcha[lang]);
			return false;
		}

		try {
			setLoading(true);

			let url = "";
			let body = {};

			let formdata = new FormData();

			url = Globals.REGISTER;

			const v1 = password;
			const v2 = confirmPassword;

			if (v1 !== v2) {
				setErrMsg(Globals.language.register.errorPassword[lang]);
				return;
			}

			if (!privacy) {
				setErrMsg("Devi accettare il trattamento dei dati!");
				return;
			}

			body = {
				nome: name,
				cognome: surname,
				email: email,
				cellulare: phone,
				ragione_sociale: company,
				partita_iva: vatNumber,
				password: password,
				user_subgroup: isPrivate ? "privato" : "commerciante",

				token: token,
			};

			formdata.append("body", JSON.stringify(body));

			const response = await axios.postForm(url, formdata, {
				headers: {"Content-Type": "application/json"},
				withCredentials: true,
			});
			// TODO: remove console.logs before deployment
			// console.log(JSON.stringify(response?.data));
			//console.log(JSON.stringify(response))

			if (response?.data?.error === true) {
				setErrMsg(response.data.message);

				setLoading(false);
				setSuccess(false);
			} else {
				//clear state and controlled inputs
				setLoading(false);
				setSuccess(true);
			}
		} catch (err) {
			setLoading(false);
			setSuccess(false);
			setErrMsg(Globals.language.errorLabel.serverError[lang]);
		}
	};

	//controllo generale di riempimento del Recaptcha
	const v2Callback = (token) => {
		if (typeof token === "string") {
			setToken(token);
		} else if (typeof token === "boolean" && !token) {
			setErrMsg(Globals.language.errorLabel.recaptchaExpired[lang]);
			setToken("");
		} else if (token instanceof Error) {
			setErrMsg(Globals.language.errorLabel.serverError[lang]);
			setToken("");
		}
	};

	useEffect(() => {
		//nel caso un'utente passi dal login ma è già loggato verrà renderizzato alla pagina
		//di competenza in base al ruolo

		if (Object.keys(auth).length !== 0) {
			if (auth?.roles === "admin" || auth?.roles === "superadmin") {
				navigate("/admin-area");
			} else {
				navigate("/my-lepanto");
			}
		}
	}, [auth]);

	return (
		<>
			{loading ? (
				<Loading />
			) : success ? (
				// in caso di successo =>
				<div className='d-flex justify-content-center p-5'>
					<SEO
						title={"  Registra un account | Lepanto 2 "}
						description={" Registrati e crea un account  "}
						keyword={" registrati "}
						noIndex={true}

					/>{" "}
					<Card style={{fontSize: "25px"}}>
						<i
							className='fa fa-check-circle text-center'
							style={{fontSize: "50px", color: "green"}}></i>
						<p style={{fontSize: "40px", color: "green", textAlign: "center"}}>
							Richiesta inoltrata con successo{" "}
						</p>
						<div className='text-center'>
							<strong>
								Le abbiamo inviato una email, segua il link indicato per confermare la
								sue richiesta ENTRO 2 GIORNI.{" "}
							</strong>
						</div>
					</Card>
				</div>
			) : (
				<div className='d-flex justify-content-center m-5'>
					<Form
						onSubmit={(e) => {
							handleSubmit(e);
						}}>
						<h1> Registrati</h1>
						<p> Inserisci i tuoi dati per richiedere l'accesso all'area riservata!</p>
						<SEO
							title={"  Registra un account | Lepanto 2 "}
							description={" Registrati e crea un account  "}
							keyword={" registrati "}
						/>{" "}
						<Form.Group className='mb-3' controlId='formBasicEmail'>
							<Form.Label>Scegli tipo di account: </Form.Label>
							<Form.Check
								type={"radio"}
								label={"Commerciante"}
								name='type'
								onClick={() => setIsPrivate(false)}
							/>

							<Form.Check
								type={"radio"}
								label={"Privato"}
								name='type'
								onClick={() => setIsPrivate(true)}
								defaultChecked
							/>
						</Form.Group>
						{!isPrivate && (
							<>
								<Form.Group className='mb-3 ' controlId='formBasicEmail'>
									<Form.Label>Ragione sociale</Form.Label>
									<Form.Control
										type='text'
										className='border border-success'
										placeholder={"Inserisci ragione sociale"}
										value={company}
										onChange={(e) => setcompany(e.target.value)}
									/>
								</Form.Group>

								<Form.Group className='mb-3' controlId='formBasicEmail'>
									<Form.Label>P.iva</Form.Label>
									<Form.Control
										className='border border-success'
										type='text'
										placeholder={"Inserisci p.iva"}
										value={vatNumber}
										onChange={(e) => setVatNumber(e.target.value)}
									/>
								</Form.Group>
							</>
						)}
						<Form.Group className='mb-3' controlId='formBasicEmail'>
							<Form.Label>{Globals.language.contacts.nameForm[lang]}</Form.Label>
							<Form.Control
								type='text'
								placeholder={Globals.language.contacts.nameFormPlaceholder[lang]}
								value={name}
								onChange={(e) => setname(e.target.value)}
								required
							/>
						</Form.Group>
						<Form.Group className='mb-3' controlId='formBasicEmail'>
							<Form.Label>{Globals.language.contacts.surnameForm[lang]}</Form.Label>
							<Form.Control
								type='text'
								placeholder={Globals.language.contacts.surnameFormPlaceholder[lang]}
								value={surname}
								onChange={(e) => setsurname(e.target.value)}
								required
							/>
						</Form.Group>
						<Form.Group className='mb-3' controlId='formBasicEmail'>
							<Form.Label>{Globals.language.contacts.emailForm[lang]}</Form.Label>
							<Form.Control
								type='email'
								placeholder={Globals.language.contacts.emailFormPlaceholder[lang]}
								value={email}
								onChange={(e) => setemail(e.target.value)}
								required
							/>
						</Form.Group>
						<Form.Group className='mb-3' controlId='formBasicEmail'>
							<Form.Label>{Globals.language.contacts.phoneForm[lang]}</Form.Label>
							<Form.Control
								type='phone'
								placeholder={Globals.language.contacts.phoneFormPlaceholder[lang]}
								value={phone}
								onChange={(e) => setphone(e.target.value)}
								required
							/>
						</Form.Group>
						<Form.Group className='mb-3' controlId='formBasicEmail'>
							<Form.Label>Password</Form.Label>
							<Form.Control
								type='password'
								placeholder={"Inserisci una password"}
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								required
							/>
						</Form.Group>
						<Form.Group className='mb-3' controlId='formBasicEmail'>
							<Form.Label>Conferma password</Form.Label>
							<Form.Control
								type='password'
								placeholder={"Conferma password"}
								value={confirmPassword}
								onChange={(e) => setConfirmPassword(e.target.value)}
								required
							/>
						</Form.Group>
						<Form.Group className='mb-3' controlId='formBasicCheckbox'>
							<Form.Check
								type='checkbox'
								label={Globals.language.contacts.privacyForm[lang]}
								onChange={(e) =>
									e.target.value === "on" ? setPrivacy(true) : setPrivacy(false)
								}
								required
							/>
						</Form.Group>
						<Form.Group className='position-relative' controlId='formBasicCheckbox'>
							<ReCaptchaV2
								callback={v2Callback}
								theme={EReCaptchaV2Theme.Light}
								size={EReCaptchaV2Size.Normal}
								id='my-id'
								data-test-id='my-test-id'
								tabindex={0}
							/>
						</Form.Group>
						<Button
							className='d-flex justify-content-center m-auto'
							style={{
								backgroundColor: "#003187",
								border: "1px solid #003187",
							}}
							type='submit'>
							Registrati
						</Button>
						{errMsg && <p> {errMsg} </p>}
					</Form>
				</div>
			)}
		</>
	);
}

export default Register;
