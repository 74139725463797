import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import FormRequest from "./FormRequest";
import SEO from "./Seo";

function GeneralModal(props) {
	const {show, title, text, id_article, id_form, onHide, type, metaKeyword} =
		props;

	return (
		<Modal
			show={show}
			onHide={() => onHide()}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered>
			<SEO
				title={metaKeyword?.title + " | Lepanto 2"}
				description={metaKeyword?.description}
				keyword={metaKeyword?.keyword}
				noIndex={true}

			/>
			<Modal.Header closeButton></Modal.Header>
			<div className='text-center mx-auto '>
				<Modal.Title
					closeButton
					id='contained-modal-title-vcenter'
					className='font-weight-bold'
					style={{fontWeight: "600"}}
					dangerouslySetInnerHTML={{__html: title}}
				/>
			</div>
			<Modal.Body>
				<p className='text-center' dangerouslySetInnerHTML={{__html: text}} />
				<FormRequest formId={id_form} article={id_article} type_service={type} />
				{/* <Contact /> */}
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={() => onHide()}>Chiudi</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default GeneralModal;
