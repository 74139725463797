import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Globals from "../parameters/globals";
import axios from "axios";
import Loading from "../components/Loading";
import "../scss/cardetails.scss";
import Carousel from "react-grid-carousel";
import ImageGallery from "react-image-gallery";
import Contact from "../components/Contact";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useWindowSize from "use-window-size-v2";
import SEO from "../components/Seo";

import {
  MDBCol,
  MDBRow,
  MDBContainer,
  MDBAccordion,
  MDBAccordionItem,
} from "mdb-react-ui-kit";
import Formpreventivo from "../components/FormPreventivo";
function CarDetails() {
  const myRef = useRef(null);
  let { id } = useParams();
  let { type } = useParams();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [gallery, setGallery] = useState([]);
  const navigate = useNavigate();
  const targetRef = useRef(null);

  const [stickyClass, setStickyClass] = useState("");
  const [border, setBorder] = useState("");
  const navigateTo = (path) => {
    navigate(path);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };
  const handleImageLoad = () => {
    setLoading(false);
  };

  const handleImageError = () => {
    setLoading(false);
    // Gestisci l'errore del caricamento dell'immagine se necessario
  };

  const [height, setHeight] = useState(0);
  // const [width, setWidth] = useState(0);

  const { width } = useWindowSize(100); // wait 100ms for the resize events

  const formatYear = (item) => {
    let d = new Date(item);
    let year = d.getFullYear();
    return year;
  };
  const formatDay = (item) => {
    let d = new Date(item);
    let day = "" + d.getDate();
    if (day.length < 2) {
      day = "0" + day;
    }

    return day;
  };
  const formatMonth = (item) => {
    let d = new Date(item);
    let month = d.getMonth() < 12 ? "" + (d.getMonth() + 1) : 1;
    if (month.length < 2) month = "0" + month;
    return month;
  };

  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimenion]);
  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);
  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;

      setHeight(windowHeight);

      // window height changed for the demo
      windowHeight > 40 ? setStickyClass("sticky-nav") : setStickyClass("");
    }
  };

  const borderSelected = (testDrive) => {
    if (width > 1000) {
      setBorder("border-selected-card");
      window.scrollTo(0, 0);
      setTimeout(() => {
        setBorder("");
      }, 2500);
    } else {
      myRef.current.scrollIntoView();
    }
  };
  useEffect(() => {
    if (type === "test-drive") {
      borderSelected();
    }
  }, []);

  useEffect(() => {

    setTimeout(() => {
      if (type === "test-drive") {
        handleButtonClick();
      }    }, 450);
   
  }, []);

  const handleButtonClick = () => {
    if (width < 1300 && type === "test-drive") {
      const offset = -100; // Numero di pixel da alzare
      const top = targetRef.current.getBoundingClientRect().top + window.pageYOffset + offset;
      window.scrollTo({ top, behavior: 'smooth' });    }
  };

  const openPrintPdfSale = (id) => {
    window.open(Globals.OPENCARPRINTSALE + id, "titlebar=no").focus();
  };
  const [car, setCar] = useState({
    id: "",
    title: "",
    brand_description: "",
    model_description: "",
    version_description: "",
    description_description: "",
    accessori_serie: [],
    optional: [],
    subtitle: "",
    gallery: [],
    type: "",
    price: "",
    body: "",
    initial_registration: "",
    mileage: "",
    fueltype: "",
    horsepower: "",
    kilowatt: "",
    trasmission: "",
    color_description: "",
    consumption_combined: "",
    general_inspection: "",
    capacity: "",
    gears: "",
    cylinders: "",
    consumption_urban: "",
    consumption_extra_urban: "",
    emission_co2_liquid: "",
    seats: "",
    emission_class: "",
    doors: "",
    accessors: "",
    notes: "",
    status: "",
  });

  const numberFormat = (value) =>
    new Intl.NumberFormat("it-IT", {
      //   style: "currency",
      currency: "EUR",
    }).format(value);

  const getCar = async () => {
    try {
      setLoading(true);
      setError(false);
      setMessage("");

      let body = {
        id: id,
      };

      let formdata = new FormData();

      formdata.append("body", JSON.stringify(body));

      const response = await axios.postForm(Globals.GETCAR, formdata, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });

      let data = response?.data;

      if (data?.error === true) {
        setLoading(false);
        setError(true);
        setMessage(data?.message);
      } else {
        setLoading(false);
        setError(false);
        setMessage("");
        // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        navigateTo();

        let v = data?.veicolo;

        setCar({
          ...car,
          id: v.id,
          title: v.title,
          brand_description: v.brand_description,
          model_description: v.model_description,
          version_description: v.version_description,
          description_description: v.description_description,
          accessori_serie: data.accessori_serie,
          optional: data.optional,
          subtitle: v.subtitle,
          v: data.gallery,
          type: v.type,
          price: v.private_price,
          body: v.body,
          initial_registration: v.initial_registration,
          mileage: v.mileage,
          fueltype: v.fuel_type,
          horsepower: v.horsepower,
          kilowatt: v.kilowatt,
          trasmission: v.transmission,
          color_description: v.color_description,
          general_inspection: v.general_inspection,
          capacity: v.capacity,
          gears: v.gear_type,
          cylinders: v.cylinders,
          consumption_urban: v.consumption_urban,
          consumption_extra_urban: v.consumption_extra_urban,
          consumption_combined: v.consumption_extra_urban,
          emission_co2_liquid: v.emission_co2_liquid,
          seats: v.seats,
          emission_class: v.emission_class,
          doors: v.doors,
          accessors: "",
          notes: v.notes,
          status: v.status,
        });
        setGallery(response?.data?.gallery);
      }
    } catch (err) {
      setLoading(false);
      setError(true);
      setMessage(err);
    }
  };

  useEffect(() => {
    getCar();
  }, []);

  if (!error) {
    return loading ? (
      <Loading />
    ) : (
      <div className="container">
        <SEO
          title={
            car.title !== ""
              ? car.title
              : car.description_description + "| Lepanto 2 "
          }
          description={
            "Vendita auto usata - km : " +
            car.mileage +
            ", - alimentazione:  " +
            car.fueltype
          }
          keyword={" auto usate Verona "}
        />
        <h1 className="text-center py-3">
          <span className="flex-start bg-secondary ">
            <button
              onClick={() => navigate(-2)}
              className="btn-back-fix"
              style={{ cursor: "pointer" }}
            >
              <i className="fa fa-arrow-left"></i>
            </button>
          </span>

          {car.title !== "" ? car.title : car.description_description}
          <p className="separator"></p>
        </h1>
        <div className="d-flex responsive-car-details">
          <div className={"col-md-7 col-sm-12"}>
            <ImageGallery
              showBullets={true}
              items={gallery}
              onImageLoad={handleImageLoad}
              onImageError={handleImageError}
              thumbnailPosition={"left"}
              showFullscreenButton={true}
              autoPlay
              // loading={<Loading />}
              slideDuration={500}
              slideInterval={4000}
            />
            <MDBContainer className="col-md-12 my-2">
              <Card
                className="mx-auto text-center "
                style={{ backgroundColor: "#003187", borderRadius: "5px" }}
              >
                {" "}
                <div className="container p-2">
                  <div className="row">
                    <div className="col-xl-3 col-md-6">
                      <p className="card-subtitle-details">Tipo</p>
                      <div className="p-1 ">
                        <strong className="card-title-details ">
                          {car.type}{" "}
                        </strong>
                      </div>
                    </div>
                    <div className="col-xl-3 col-md-6">
                      <p className="card-subtitle-details">Chilometraggio</p>
                      <div className="p-1">
                        <strong className="card-title-details">
                          {" "}
                          {car.mileage} km{" "}
                        </strong>
                      </div>
                    </div>
                    <div className="col-xl-3 col-md-6">
                      <p className="card-subtitle-details">Prezzo</p>
                      <div className="p-1">
                        <strong className="card-title-details">
                          {" "}
                          {numberFormat(car.price)} €
                        </strong>
                      </div>
                    </div>
                    <div className="col-xl-3 col-md-6">
                      <p className="card-subtitle-details">Anno</p>
                      <div className="p-1">
                        <strong className="card-title-details">
                          {" "}
                          {formatDay(car.initial_registration)}-
                          {formatMonth(car.initial_registration)}-
                          {formatYear(car.initial_registration)}
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>

              <div>
                <div className="grey-bg container-fluid">
                  <section>
                    <div className="row my-4">
                      <div className="col-xl-4 col-sm-6 col-12">
                        <div className="card-detail-car">
                          <div className="card-content">
                            <div className="card-body">
                              <div className="media d-flex">
                                <div className="align-self-center">
                                  <i className="fa fa-icon-pencil "></i>
                                </div>
                                <div className="media-body text-center align-content-center">
                                  <span>Carrozzeria</span>

                                  <h3>{car.body}</h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-sm-6 col-12">
                        <div className="card-detail-car">
                          <div className="card-content">
                            <div className="card-body">
                              <div className="media d-flex">
                                <div className="align-self-center">
                                  <i className="icon-speech warning font-large-2 float-left"></i>
                                </div>
                                <div className="media-body text-center">
                                  <span>Alimentazione</span>

                                  <h3>{car.fueltype}</h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xl-4 col-sm-6 col-12">
                        <div className="card-detail-car">
                          <div className="card-content">
                            <div className="card-body">
                              <div className="media d-flex">
                                <div className="align-self-center">
                                  <i className="icon-pointer danger font-large-2 float-left"></i>
                                </div>
                                <div className="media-body text-center">
                                  <span>Trasmissione</span>

                                  <h3>{car.trasmission}</h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-sm-6 col-12">
                        <div className="card-detail-car">
                          <div className="card-content">
                            <div className="card-body">
                              <div className="media d-flex">
                                <div className="align-self-center">
                                  <i className="icon-pointer danger font-large-2 float-left"></i>
                                </div>
                                <div className="media-body text-center">
                                  <span>Colore esterno</span>

                                  <h5>{car.color_description}</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-sm-6 col-12">
                        <div className="card-detail-car">
                          <div className="card-content">
                            <div className="card-body">
                              <div className="media d-flex">
                                <div className="align-self-center">
                                  <i className="icon-pointer danger font-large-2 float-left"></i>
                                </div>
                                <div className="media-body text-center">
                                  <span>Consumo (Combinato)</span>

                                  <h4>
                                    {car.consumption_combined} l / 100 km{" "}
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-sm-6 col-12">
                        <div className="card-detail-car">
                          <div className="card-content">
                            <div className="card-body">
                              <div className="media d-flex">
                                <div className="align-self-center">
                                  <i className="icon-pointer danger font-large-2 float-left"></i>
                                </div>
                                <div className="media-body text-center">
                                  <span>Data Revisione</span>

                                  <h3>
                                    {car.general_inspection !== ""
                                      ? car.general_inspection
                                      : "-"}
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-sm-6 col-12">
                        <div className="card-detail-car">
                          <div className="card-content">
                            <div className="card-body">
                              <div className="media d-flex">
                                <div className="align-self-center">
                                  <i className="icon-pointer danger font-large-2 float-left"></i>
                                </div>
                                <div className="media-body text-center">
                                  <span>Cilindrata</span>

                                  <h3>{car.capacity} cm3</h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-sm-6 col-12">
                        <div className="card-detail-car">
                          <div className="card-content">
                            <div className="card-body">
                              <div className="media d-flex">
                                <div className="align-self-center">
                                  <i className="icon-pointer danger font-large-2 float-left"></i>
                                </div>
                                <div className="media-body text-center">
                                  <span>Marce</span>

                                  <h3>{car.gears}</h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-sm-6 col-12">
                        <div className="card-detail-car">
                          <div className="card-content">
                            <div className="card-body">
                              <div className="media d-flex">
                                <div className="align-self-center">
                                  <i className="icon-pointer danger font-large-2 float-left"></i>
                                </div>
                                <div className="media-body text-center">
                                  <span>Cilindri</span>

                                  <h3>{car.cylinders}</h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xl-4 col-sm-6 col-12">
                        <div className="card-detail-car">
                          <div className="card-content">
                            <div className="card-body">
                              <div className="media d-flex">
                                <div className="align-self-center">
                                  <i className="icon-pointer danger font-large-2 float-left"></i>
                                </div>
                                <div className="media-body text-center">
                                  <span>Posti a sedere</span>

                                  <h3>{car.seats}</h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-sm-6 col-12">
                        <div className="card-detail-car">
                          <div className="card-content">
                            <div className="card-body">
                              <div className="media d-flex">
                                <div className="align-self-center">
                                  <i className="icon-pointer danger font-large-2 float-left"></i>
                                </div>
                                <div className="media-body text-center">
                                  <span>Classe emissioni</span>

                                  <h3>{car.emission_class}</h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xl-4 col-sm-6 col-12">
                        <div className="card-detail-car">
                          <div className="card-content">
                            <div className="card-body">
                              <div className="media d-flex">
                                <div className="align-self-center">
                                  <i className="icon-pointer danger font-large-2 float-left"></i>
                                </div>
                                <div className="media-body text-center">
                                  <span>Porte</span>

                                  <h3>{car.doors}</h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xl-6 col-sm-6 col-12">
                        <div className="card-detail-car">
                          <div className="card-content">
                            <div className="card-body">
                              <div className="media d-flex">
                                <div className="align-self-center">
                                  <i className="icon-pointer danger font-large-2 float-left"></i>
                                </div>
                                <div className="media-body text-center">
                                  <span>Emissioni CO2</span>

                                  <h3>
                                    {car.emission_co2_liquid} g/km (combinato)
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-sm-6 col-12">
                        <div className="card-detail-car">
                          <div className="card-content">
                            <div className="card-body">
                              <div className="media d-flex">
                                <div className="align-self-center">
                                  <i className="icon-graph success font-large-2 float-left"></i>
                                </div>
                                <div className="media-body text-center">
                                  <span>Potenza</span>

                                  <h3>
                                    {car.kilowatt} Kw (
                                    {(car.kilowatt * 1.36).toFixed(2)}(Cv))
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-12 col-sm-12 col-12">
                        <div className="card-detail-car-extra">
                          <div className="card-content">
                            <div className="card-body">
                              <div className="media d-flex">
                                <div className="align-self-center">
                                  <i className="icon-pointer danger font-large-2 float-left"></i>
                                </div>
                                <div className="media-body text-center">
                                  <span>Consumo carburante </span>

                                  <h4 className="my-1">
                                    <p>
                                      {car.consumption_urban} l/100 km (urbano)
                                    </p>
                                    <p>
                                      {car.consumption_extra_urban} l/100 km
                                      (extraurbano)
                                    </p>
                                    <p>
                                      {car.consumption_combined} l/100 km
                                      (combinato)
                                    </p>
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>

              <div className="container">
                <div className="row">
                  <div className="col-md-6 p-2">
                    <button
                      className="btn btn-outline w-100 bottone-detail-cars"
                      style={{ border: "2px solid #003187" }}
                      onClick={() => borderSelected("test-drive")}
                    >
                      Test drive
                    </button>
                  </div>
                  <div className="col-md-6 p-2" ref={myRef}>
                    {" "}
                    <button
                      className="btn btn-outline w-100 bottone-detail-cars"
                      style={{ border: "2px solid #003187" }}
                      onClick={() => borderSelected("")}
                    >
                      Chiedi info
                    </button>
                  </div>
                  <div className="col-md-12 p-2">
                    <button
                      className="btn btn-link w-100 text-decoration-none"
                      onClick={() => openPrintPdfSale(car.id)}
                    >
                      <i className="fa fa-print mx-2"></i>
                      Stampa
                    </button>
                  </div>
                  {/* <div className="col-md-6 p-2">
                    {" "}
                    <btn className="btn btn-danger w-100">Valuta usato</btn>
                  </div> */}
                </div>
              </div>
            </MDBContainer>{" "}
          </div>
          <div className={`col-md-5 mx-auto sticky px-2 py-1 ${border}`}  ref={targetRef}>
            <Card style={{ width: "100%" }} className="px-2 py-1">
              <div>
                <div
                  style={{
                    backgroundColor: "#003187",
                    borderTopRightRadius: "5px",
                    borderTopLeftRadius: "5px",
                  }}
                >
                  <div className="px-2 py-2 text-light">
                    <h2
                      className="p-2 text-light"
                      style={{ fontSize: "35px", lineHeight: "1" }}
                    >
                      {" "}
                      <p
                        className="text-light"
                        style={{ fontSize: "16px", lineHeight: "0" }}
                      >
                        Acquistala subito per
                      </p>
                      {numberFormat(car.price)} €{" "}
                    </h2>

                    <p
                      className="text-light mx-2 py-2"
                      style={{
                        fontSize: "18px",
                        lineHeight: "0",
                        fontWeight: "600",
                      }}
                    >
                      Veicolo: {car.status}
                    </p>
                  </div>
                </div>
                <div className="px-2 py-1">
                  <h2 className="p-2 text-dark title-test-drive">
                    Contattaci per un test drive o un preventivo{" "}
                  </h2>
                  <Formpreventivo general={true} />{" "}
                </div>
              </div>
            </Card>
          </div>
        </div>
        <div className="container-fluid my-5">
          <MDBAccordion alwaysOpen borderless initialActive={1}>
            <MDBAccordionItem collapseId={1} headerTitle=" Altri dettagli ">
              {car.notes}
            </MDBAccordionItem>
            <MDBAccordionItem collapseId={2} headerTitle="Allestimento">
              <div className="container d-flex">
                <p
                  dangerouslySetInnerHTML={{
                    __html: car.accessori_serie,
                  }}
                />
              </div>

              <p
                dangerouslySetInnerHTML={{
                  __html: car.optional,
                }}
              />
            </MDBAccordionItem>
          </MDBAccordion>
          <div className="py-5">
            <Contact general={true} />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div style={{ minHeight: "350px", fontWeight: 600 }}>
        <Card className="w-50 p-5 text-center mx-auto my-5">
          Il veicolo potrebbe essere stato venduto o non essere momentaneamente
          disponibile.
        </Card>
      </div>
    );
  }
}

export default CarDetails;
