import React from "react";
import {Helmet} from "react-helmet";
export default function SEO({title, description, keyword, noIndex}) {
	return (
		<Helmet>
			{/* Standard metadata tags */}

			<title>{title}</title>
			<meta name='description' content={description} />
			<meta name='keywords' content={keyword} />
			{noIndex && <meta name="robots" content="noindex" />}

			{/* End standard metadata tags */}
		</Helmet>
	);
}
