import React, { useState, useEffect, useContext } from "react";
import "../scss/officina.scss";
import Contact from "./Contact";
function LavoraConNoi() {
  return (
    <div style={{ backgroundColor: "#f4f4f4" }} id="lavoraconnoi">
      <div className="py-3 container-fluid">
        <div>
          <h2 className="divider donotcross" contenteditable>
            Lavora con noi
          </h2>
          <div className="container">
          <Contact lavora={true} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LavoraConNoi;
