import axios from "axios";
import {useEffect, useState, useContext} from "react";
import {useLocation, Navigate, Outlet} from "react-router-dom";
import Globals from "../parameters/globals";
import Loading from "./Loading";
import AuthContext from "../context/AuthProvider";

const RequireAuth = ({allowedRoles}) => {
	const {auth, setAuth, lang} = useContext(AuthContext);
	const [loading, setLoading] = useState(true);

	const checkLogin = async () => {
		let myAccessToken = localStorage.getItem("accessToken");

		if (myAccessToken !== "") {
			try {
				let formdata = new FormData();
				formdata.append("body", myAccessToken);

				const response = await axios.post(Globals.CHECKLOGIN, formdata, {
					withCredentials: true,
				});

				if (response?.data?.is_logged) {
					let accessToken = myAccessToken;
					let roles = response?.data?.roles;
					let user = response?.data?.username;
					setAuth({user, accessToken, roles});
					setLoading(false);
				} else {
					localStorage.removeItem("accessToken");
					setAuth({});
					setLoading(false);

					return false;
				}
			} catch (err) {
				setLoading(false);
				setAuth({});
			}
		}

		setLoading(false);
	};

	useEffect(() => {
		checkLogin();
	}, []);

	const location = useLocation();
	let role = auth?.roles?.toString();

	if (loading) {
		return <Loading />;
	} else {
		return allowedRoles.includes(role) ? (
			<Outlet />
		) : auth?.user ? (
			<Navigate to='/unauthorized' state={{from: location}} replace />
		) : (
			<Navigate to='/login' state={{from: location}} replace />
		);
	}
};

export default RequireAuth;
