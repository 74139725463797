import axios from "axios";
import React, {useState, useEffect, useRef} from "react";
import Loading from "../components/Loading";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Globals from "../parameters/globals";
import "../scss/contact.scss";

import {
	EReCaptchaV2Size,
	EReCaptchaV2Theme,
	ReCaptchaV2,
} from "react-recaptcha-x";

function FormRequest(props) {
	const {formId, article, type_service} = props;

	const [name, setname] = useState("");
	const [surname, setsurname] = useState("");
	const [email, setemail] = useState("");
	const [phone, setphone] = useState("");
	const [message, setmessage] = useState("");
	const [company, setcompany] = useState("");

	const [privacy, setPrivacy] = useState(false);
	const [newsletter, setNewsletter] = useState(false);
	const [sms, setSms] = useState(false);

	//case 1 value declaration
	const [brand, setBrand] = useState("");
	const [model, setModel] = useState("");
	const [registrationMonth, setRegistrationMonth] = useState("");
	const [registrationYear, setRegistrationYear] = useState("");
	const [mileage, setMileage] = useState("");
	const [fuel, setFuel] = useState("");
	const [capacity, setCapacity] = useState("");
	const [color, setColor] = useState("");
	const [carService, setCarService] = useState(""); //select
	const [numberOwners, setNumberOwners] = useState(""); //select
	const [value, setValue] = useState("");
	const [accessories, setAccessories] = useState("");
	const [defects, setDefects] = useState("");
	const [photo, setPhoto] = useState([]);

	//case 11 rent
	const [typeRent, setTypeRent] = useState([]);
	const [selectRentType, setSelectedRentType] = useState("");
	const [startRent, setStartRent] = useState("");
	const [endRent, setEndRent] = useState("");
	const [expectedKm, setexpectedKm] = useState("");

	const [is_robot, set_is_robot] = useState(false);
	const [is_robot_general, set_is_robot_general] = useState(false);
	const [token, setToken] = useState("");
	const [loading, setLoading] = useState(false);

	//case 7 book
	const [groupSchedule, setGroupSchedule] = useState([]);
	const [schedule, setSchedule] = useState([]);
	const [bookingDate, setBookingDate] = useState([]);

	const [success, setSuccess] = useState(false);
	const [errMsg, setErrMsg] = useState("");

	//case 8 long rent
	const [cap, setCap] = useState("");
	const [city, setCity] = useState("");
	const [province, setProvince] = useState("");
	const [street, setStreet] = useState("");
	const [typeCompany, setTypeCompany] = useState("");
	const [secondPhone, setSecondPhone] = useState(""); //fisso
	const [fax, setFax] = useState(""); //fisso
	const [iva, setIva] = useState(""); //fisso
	const [taxCode, setTaxCode] = useState(""); //fisso

	const [durationRent, setDurationRent] = useState({});
	const [GroupRent, setGroupRent] = useState([]);

	const [expectedAnnualMileage, setExpectedAnnualMileage] = useState("");
	const [firstBrand, setFirstBrand] = useState("");
	const [secondBrand, setSecondBrand] = useState("");
	const [firstModel, setFirstModel] = useState("");
	const [secondModel, setSecondModel] = useState("");
	const [numberOfVehicles, setNumberOfVehicles] = useState("");

	//solo per il noleggio
	const [blockSend, setBlockSend] = useState(true);

	// useEffect(() => {
	// 	checkRentAvailabity();
	// }, [blockSend]);

	let lang = "it";

	const handleSubmit = async (e, id) => {
		e.preventDefault();

		if (token === "") {
			setErrMsg(Globals.language.errorLabel.acceptRecaptcha[lang]);
			return false;
		}

		try {
			setLoading(true);

			let url = "";
			let body = {};

			let formdata = new FormData();

			switch (parseInt(formId)) {
				// richiesta quotazione
				case 1:
					url = Globals.QUOTATION_REQUEST;

					body = {
						name: name,
						surname: surname,
						email: email,
						phone: phone,
						company: company,
						privacy: privacy,
						newsletter: newsletter === true ? 1 : 0,
						sms: sms === true ? 1 : 0,
						article: article,
						type_service: type_service,
						form_id: formId,
						token: token,

						marca: brand,
						modello: model,
						mese_immatricolazione: registrationMonth,
						anno_immatricolazione: registrationYear,
						chilometri: mileage,
						alimentazione: fuel,
						cilindrata: capacity,
						id_tagliandi: carService,
						id_proprietari: numberOwners,
						colore: color,
						valore: value,
						accessori: accessories,
						difetti: defects,

						foto:
							photo !== ""
								? {
										name: photo.name,
										size: photo.size,
										type: photo.type,
								  }
								: "",
					};

					formdata.append("foto", photo);

					break;

				// richiesta quotazione noleggio
				case 5:
					url = Globals.RENTALQUOTATIONREQUEST;

					body = {
						name: name,
						surname: surname,
						email: email,
						cellulare: phone,
						tipo_attivita: typeCompany,
						ragione_sociale: company,
						citta: city,
						provincia: province,
						cap: cap,
						via: street,
						telefono: secondPhone,
						fax: fax,
						partita_iva: iva,
						codice_fiscale: taxCode,
						id_durata_noleggio: durationRent,
						km_annui: expectedAnnualMileage,
						marca_1: firstBrand,
						modello_1: firstModel,
						marca_2: secondBrand,
						modello_2: secondModel,
						numero_vetture: numberOfVehicles,
						note: message,

						privacy: privacy,
						newsletter: newsletter === true ? 1 : 0,
						sms: sms === true ? 1 : 0,
						article: article,
						type_service: type_service,
						form_id: formId,
						token: token,
					};

					break;

				// prenotazione standard
				case 7:
					url = Globals.BOOKING_REQUEST;

					body = {
						name: name,
						surname: surname,
						email: email,
						phone: phone,
						company: company,
						note: message,
						marca: brand,
						modello: model,

						booking_selected: schedule.id,
						date: bookingDate,

						privacy: privacy,
						newsletter: newsletter === true ? 1 : 0,
						sms: sms === true ? 1 : 0,
						article: article,
						type_service: type_service,
						form_id: formId,
						token: token,
					};
					break;

				// noleggio
				case 11:
					url = Globals.RENT_REQUEST;

					if (selectRentType === "") {
						return false;
					}

					body = {
						name: name,
						surname: surname,
						email: email,
						phone: phone,
						expectedKm: expectedKm,
						type: selectRentType,
						start_date: startRent,
						end_date: endRent,

						privacy: privacy,
						newsletter: newsletter === true ? 1 : 0,
						sms: sms === true ? 1 : 0,
						article: article,
						type_service: type_service,
						form_id: formId,
						token: token,
					};

					break;

				//default: richiesta semplice
				default:
					url = Globals.SIMPLE_REQUEST;

					body = {
						name: name,
						surname: surname,
						email: email,
						phone: phone,
						company: company,
						message: message,
						privacy: privacy,
						newsletter: newsletter === true ? 1 : 0,
						sms: sms === true ? 1 : 0,
						article: article,
						type_service: type_service,
						form_id: formId,
						token: token,

						newsletter: newsletter === true ? 1 : 0,
						sms: sms === true ? 1 : 0,
						article: article,
						type_service: type_service,
						form_id: formId,
						token: token,
					};
					break;
			}

			formdata.append("body", JSON.stringify(body));

			const response = await axios.postForm(url, formdata, {
				headers: {"Content-Type": "application/json"},
				withCredentials: true,
			});
			// TODO: remove console.logs before deployment
			// console.log(JSON.stringify(response?.data));
			//console.log(JSON.stringify(response))

			if (response?.data?.error === true) {
				setErrMsg(response.data.message);

				setLoading(false);
				setSuccess(false);
			} else {
				//clear state and controlled inputs
				setLoading(false);
				setSuccess(true);
			}
		} catch (err) {
			setLoading(false);
			setSuccess(false);
			setErrMsg(Globals.language.errorLabel.serverError[lang]);
		}
	};

	//controllo generale di riempimento del Recaptcha
	const v2Callback = (token) => {
		if (typeof token === "string") {
			setToken(token);
		} else if (typeof token === "boolean" && !token) {
			setErrMsg(Globals.language.errorLabel.recaptchaExpired[lang]);
			setToken("");
		} else if (token instanceof Error) {
			setErrMsg(Globals.language.errorLabel.serverError[lang]);
			setToken("");
		}
	};

	//SELECT Call per ottenere la durata del noleggio
	const getRentDuration = async () => {
		try {
			setLoading(true);
			const response = await axios.get(Globals.GETRENTDURATION, {
				headers: {"Content-Type": "application/json"},
				withCredentials: true,
			});

			if (response?.data?.error === true) {
				setErrMsg(response?.data?.message);
			} else {
				setGroupRent(response?.data?.lista);
			}
			setLoading(false);
		} catch (error) {
			setErrMsg(error);
			setLoading(false);
			return false;
		}
	};

	//SELECT Call per ottenere i tipi di noleggio
	const getTypeRent = async () => {
		try {
			setLoading(true);
			const response = await axios.get(
				Globals.GETTYPERENT + "&id_article=" + article,
				{
					headers: {"Content-Type": "application/json"},
					withCredentials: true,
				},
			);

			if (response?.data?.error === true) {
				setErrMsg(response?.data?.message);
			} else {
				setTypeRent(response?.data?.lista);
			}
			setLoading(false);
		} catch (error) {
			setErrMsg(error);
			setLoading(false);
			return false;
		}
	};

	//SELECT Call per ottenere le fascie orarie
	const getBookingSchedule = async () => {
		try {
			setLoading(true);

			let body = {};

			let formdata = new FormData();

			const response = await axios.get(Globals.GETSCHEDULEBOOK, {
				headers: {"Content-Type": "application/json"},
				withCredentials: true,
			});

			if (response?.data?.error === true) {
				setErrMsg(response?.data?.message);
			} else {
				setGroupSchedule(response?.data?.lista);
			}
			setLoading(false);
		} catch (error) {
			setErrMsg(error);
			setLoading(false);
			return false;
		}
	};

	//Call di controllo disponibilità noleggio
	const checkRentAvailabity = async () => {
		try {
			setLoading(true);
			setBlockSend(false);
			setErrMsg("");

			let body = {
				tipologie_listino_noleggio_id: selectRentType,
				data_inizio: startRent,
				data_fine: endRent,
			};

			let formdata = new FormData();

			formdata.append("body", JSON.stringify(body));

			const response = await axios.postForm(
				Globals.CHECKRENTAVALABILITY,
				formdata,
				{
					headers: {"Content-Type": "application/json"},
					withCredentials: true,
				},
			);

			if (response?.data?.error === true) {
				setErrMsg(response?.data?.message);
				setBlockSend(true);
			} else {
				setBlockSend(false);
			}
			setLoading(false);
		} catch (error) {
			setErrMsg(error);
			setLoading(false);
			return false;
		}
	};

	//useEffect per il riempimento delle select selezionabili nelle form
	//in base al parametro formId
	useEffect(() => {
		if (parseInt(formId) === 11) {
			getTypeRent();
		}
		if (parseInt(formId) === 7) {
			getBookingSchedule();
		}
		if (parseInt(formId) === 5) {
			getRentDuration();
		}
	}, [formId]);

	//useEffect solo per il noleggio
	//se vengono inseriti uno dei tre valori, verrà effettuato un controllo
	//la chiamata viene effettuata solo i tre valori sono riempiti
	useEffect(() => {
		if (
			parseInt(formId) === 11 &&
			startRent !== "" &&
			endRent !== "" &&
			typeRent !== "" &&
			selectRentType !== 0 &&
			selectRentType !== ""
		) {
			checkRentAvailabity();
		}
	}, [startRent, endRent, typeRent]);

	//in base al formId ricevuto nelle props, cambia la form
	var form = <div></div>;
	switch (parseInt(formId)) {
		// chiamata segnalazione h24
		case 0:
			form = (
				<div className='text-center'>
					<Button
						style={{width: "50%"}}
						onClick={() => window.open("tel:+39045500852")}
						variant='danger'>
						Chiamaci
					</Button>
				</div>
			);
			break;

		// richiesta quotazione
		case 1:
			form = (
				<Form
					onSubmit={(e) => {
						handleSubmit(e, formId);
					}}>
					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>{Globals.language.contacts.nameForm[lang]}</Form.Label>
						<Form.Control
							type='text'
							placeholder={Globals.language.contacts.nameFormPlaceholder[lang]}
							value={name}
							onChange={(e) => setname(e.target.value)}
							required
						/>
					</Form.Group>
					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>{Globals.language.contacts.surnameForm[lang]}</Form.Label>
						<Form.Control
							type='text'
							placeholder={Globals.language.contacts.surnameFormPlaceholder[lang]}
							value={surname}
							onChange={(e) => setsurname(e.target.value)}
							required
						/>
					</Form.Group>
					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>{Globals.language.contacts.emailForm[lang]}</Form.Label>
						<Form.Control
							type='email'
							placeholder={Globals.language.contacts.emailFormPlaceholder[lang]}
							value={email}
							onChange={(e) => setemail(e.target.value)}
							required
						/>
					</Form.Group>
					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>Ragione sociale</Form.Label>
						<Form.Control
							type='text'
							placeholder={"Inserisci ragione sociale"}
							value={company}
							onChange={(e) => setcompany(e.target.value)}
						/>
					</Form.Group>
					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>{Globals.language.contacts.phoneForm[lang]}</Form.Label>
						<Form.Control
							type='phone'
							placeholder={Globals.language.contacts.phoneFormPlaceholder[lang]}
							value={phone}
							onChange={(e) => setphone(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>Marca auto</Form.Label>
						<Form.Control
							type='text'
							placeholder={"Inserisci marca auto"}
							value={brand}
							onChange={(e) => setBrand(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>Modello auto</Form.Label>
						<Form.Control
							type='text'
							placeholder={"Inserisci modello auto"}
							value={model}
							onChange={(e) => setModel(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>Mese immatricolazione</Form.Label>
						<Form.Control
							type='text'
							placeholder={"Inserisci mese di immatricolazione"}
							value={registrationMonth}
							onChange={(e) => setRegistrationMonth(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>Anno di immatricolazione</Form.Label>
						<Form.Control
							type='text'
							placeholder={"Inserisci anno di immatricolazione"}
							value={registrationYear}
							onChange={(e) => setRegistrationYear(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>Chilometri</Form.Label>
						<Form.Control
							type='text'
							placeholder={"Inserisci chilometraggio"}
							value={mileage}
							onChange={(e) => setMileage(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>Alimentazione</Form.Label>
						<Form.Control
							type='text'
							placeholder={"Inserisci il tipo di alimentazione"}
							value={fuel}
							onChange={(e) => setFuel(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>Cilindrata</Form.Label>
						<Form.Control
							type='text'
							placeholder={"Inserisci Cilindrata"}
							value={capacity}
							onChange={(e) => setCapacity(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group className='mb-3'>
						<Form.Label>Tagliandi</Form.Label>
						<Form.Select onChange={(e) => setCarService(e.target.value)}>
							<option value={1} defaultChecked>
								Tutti in concessionaria
							</option>
							<option value={2}>Solo fino a 30.000Km</option>
							<option value={3}>Solo fino a 60.000Km</option>
							<option value={4}>Solo fino a 90.000Km</option>
							<option value={5}>Solo fino a 120.000Km</option>
							<option value={6}>Mai tagliandata</option>
						</Form.Select>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>Colore</Form.Label>
						<Form.Control
							type='text'
							placeholder={"Inserisci colore auto"}
							value={color}
							onChange={(e) => setColor(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group className='mb-3'>
						<Form.Label>Numero proprietari </Form.Label>

						<Form.Select onChange={(e) => setNumberOwners(e.target.value)}>
							<option value={1} defaultChecked>
								Unico
							</option>
							<option value={2}>Secondo</option>
							<option value={3}>Terzi</option>
							<option value={4}>Molti proprietari</option>
						</Form.Select>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>Indicazione valore</Form.Label>
						<Form.Control
							type='text'
							placeholder={"Inserisci valore in euro"}
							value={value}
							onChange={(e) => setValue(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicPassword'>
						<Form.Label>Accessori </Form.Label>
						<Form.Control
							type='text'
							placeholder={"Inserisci accessori"}
							as='textarea'
							value={accessories}
							onChange={(e) => setAccessories(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicPassword'>
						<Form.Label>Difetti </Form.Label>
						<Form.Control
							type='text'
							placeholder={"Inserisci difetti"}
							as='textarea'
							value={defects}
							onChange={(e) => setDefects(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicPassword'>
						<Form.Label>Foto auto</Form.Label>
						<Form.Control
							type='file'
							name='photo'
							id='phoyo'
							accept='image/*'
							data-placeholder={"Inserisci un eventuale foto"}
							onChange={(e) => setPhoto(e.target.files[0])}
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicCheckbox'>
						<Form.Check
							type='checkbox'
							label={Globals.language.contacts.privacyForm[lang]}
							onChange={(e) =>
								e.target.value === "on" ? setPrivacy(true) : setPrivacy(false)
							}
							required
						/>
					</Form.Group>
					<Form.Group className='mb-3' controlId='formBasicCheckbox'>
						<Form.Check
							type='checkbox'
							label={Globals.language.contacts.newsletterForm[lang]}
							onChange={(e) =>
								e.target.value === "on" ? setNewsletter(true) : setNewsletter(false)
							}
						/>
					</Form.Group>
					<Form.Group className='mb-3' controlId='formBasicCheckbox'>
						<Form.Check
							type='checkbox'
							label={"Desidero iscrivermi al servizio sms"}
							onChange={(e) =>
								e.target.value === "on" ? setSms(true) : setSms(false)
							}
						/>
					</Form.Group>

					<Form.Group className='position-relative' controlId='formBasicCheckbox'>
						<ReCaptchaV2
							callback={v2Callback}
							theme={EReCaptchaV2Theme.Light}
							size={EReCaptchaV2Size.Normal}
							id='my-id'
							data-test-id='my-test-id'
							tabindex={0}
						/>
					</Form.Group>

					<Button
						className='d-flex justify-content-center m-auto'
						style={{
							backgroundColor: "#003187",
							border: "1px solid #003187",
						}}
						type='submit'>
						{Globals.language.contacts.sendButtonForm[lang]}
					</Button>
					{errMsg && <p> {errMsg} </p>}
				</Form>
			);
			break;

		// richiesta quotazione noleggio
		case 5:
			form = (
				<Form
					onSubmit={(e) => {
						handleSubmit(e, formId);
					}}>
					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>Tipo attività</Form.Label>
						<Form.Control
							type='text'
							placeholder={"Inserisci il tipo di attività"}
							value={typeCompany}
							onChange={(e) => setTypeCompany(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>{Globals.language.contacts.surnameForm[lang]}</Form.Label>
						<Form.Control
							type='text'
							placeholder={Globals.language.contacts.surnameFormPlaceholder[lang]}
							value={surname}
							onChange={(e) => setsurname(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>{Globals.language.contacts.nameForm[lang]}</Form.Label>
						<Form.Control
							type='text'
							placeholder={Globals.language.contacts.nameFormPlaceholder[lang]}
							value={name}
							onChange={(e) => setname(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>Ragione sociale</Form.Label>
						<Form.Control
							type='text'
							placeholder={"Inserisci ragione sociale"}
							value={company}
							onChange={(e) => setcompany(e.target.value)}
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>Città</Form.Label>
						<Form.Control
							type='text'
							placeholder={"Inserisci città"}
							value={city}
							onChange={(e) => setCity(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>Provincia</Form.Label>
						<Form.Control
							type='text'
							placeholder={"Inserisci provincia"}
							value={province}
							onChange={(e) => setProvince(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>C.A.P.</Form.Label>
						<Form.Control
							type='text'
							placeholder={"Inserisci CAP"}
							value={cap}
							onChange={(e) => setCap(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>Via</Form.Label>
						<Form.Control
							type='text'
							placeholder={"Inserisci via"}
							value={street}
							onChange={(e) => setStreet(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>Cellulare</Form.Label>
						<Form.Control
							type='phone'
							placeholder={"Inserisci il numero di cellulare"}
							value={phone}
							onChange={(e) => setphone(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>Telefono fisso</Form.Label>
						<Form.Control
							type='phone'
							placeholder={"Inserisci telefono fisso"}
							value={secondPhone}
							onChange={(e) => setSecondPhone(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>Fax</Form.Label>
						<Form.Control
							type='phone'
							placeholder={"Inserisci fax"}
							value={fax}
							onChange={(e) => setFax(e.target.value)}
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>Email</Form.Label>
						<Form.Control
							type='phone'
							placeholder={"Inserisci email"}
							value={email}
							onChange={(e) => setemail(e.target.value)}
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>Partita IVA</Form.Label>
						<Form.Control
							type='text'
							placeholder={"Inserisci partita iva"}
							value={iva}
							onChange={(e) => setIva(e.target.value)}
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>Inserisci codice fiscale</Form.Label>
						<Form.Control
							type='text'
							placeholder={"Inserisci codice fiscale"}
							value={taxCode}
							onChange={(e) => setTaxCode(e.target.value)}
						/>
					</Form.Group>

					<Form.Group className='mb-3'>
						<Form.Label>Durata Noleggio </Form.Label>
						<Form.Select onChange={(e) => setDurationRent(e.target.value)}>
							{GroupRent.map((item) => {
								return <option value={item.id}>{item.value}</option>;
							})}
						</Form.Select>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>Km annui previsti</Form.Label>
						<Form.Control
							type='text'
							placeholder={"Inserisci codice fiscale"}
							value={expectedAnnualMileage}
							onChange={(e) => setExpectedAnnualMileage(e.target.value)}
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>Marca auto 1</Form.Label>
						<Form.Control
							type='text'
							placeholder={"Inserisci marca auto 1 "}
							value={firstBrand}
							onChange={(e) => setFirstBrand(e.target.value)}
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>Modello auto 1</Form.Label>
						<Form.Control
							type='text'
							placeholder={"Inserisci modello auto 1 "}
							value={firstModel}
							onChange={(e) => setFirstModel(e.target.value)}
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>Marca auto 2</Form.Label>
						<Form.Control
							type='text'
							placeholder={"Inserisci marca auto 2 "}
							value={secondBrand}
							onChange={(e) => setSecondBrand(e.target.value)}
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>Modello auto 2</Form.Label>
						<Form.Control
							type='text'
							placeholder={"Inserisci modello auto 2 "}
							value={secondModel}
							onChange={(e) => setSecondModel(e.target.value)}
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>Numero vetture</Form.Label>
						<Form.Control
							type='text'
							placeholder={"Numero di vetture "}
							value={numberOfVehicles}
							onChange={(e) => setNumberOfVehicles(e.target.value)}
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicPassword'>
						<Form.Label>Note </Form.Label>
						<Form.Control
							type='text'
							placeholder={"Inserisci note"}
							as='textarea'
							value={message}
							onChange={(e) => setmessage(e.target.value)}
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicCheckbox'>
						<Form.Check
							type='checkbox'
							label={Globals.language.contacts.privacyForm[lang]}
							onChange={(e) =>
								e.target.value === "on" ? setPrivacy(true) : setPrivacy(false)
							}
							required
						/>
					</Form.Group>
					<Form.Group className='mb-3' controlId='formBasicCheckbox'>
						<Form.Check
							type='checkbox'
							label={Globals.language.contacts.newsletterForm[lang]}
							onChange={(e) =>
								e.target.value === "on" ? setNewsletter(true) : setNewsletter(false)
							}
						/>
					</Form.Group>
					<Form.Group className='mb-3' controlId='formBasicCheckbox'>
						<Form.Check
							type='checkbox'
							label={"Desidero iscrivermi al servizio sms"}
							onChange={(e) =>
								e.target.value === "on" ? setSms(true) : setSms(false)
							}
						/>
					</Form.Group>

					<Form.Group className='position-relative' controlId='formBasicCheckbox'>
						<ReCaptchaV2
							callback={v2Callback}
							theme={EReCaptchaV2Theme.Light}
							size={EReCaptchaV2Size.Normal}
							id='my-id'
							data-test-id='my-test-id'
							tabindex={0}
						/>
					</Form.Group>

					<Button
						className='d-flex justify-content-center m-auto'
						style={{
							backgroundColor: "#003187",
							border: "1px solid #003187",
						}}
						type='submit'>
						{Globals.language.contacts.sendButtonForm[lang]}
					</Button>
					{errMsg && <p> {errMsg} </p>}
				</Form>
			);

			break;

		// prenotazione standard
		case 7:
			form = (
				<Form
					onSubmit={(e) => {
						handleSubmit(e, formId);
					}}>
					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>{Globals.language.contacts.nameForm[lang]}</Form.Label>
						<Form.Control
							type='text'
							placeholder={Globals.language.contacts.nameFormPlaceholder[lang]}
							value={name}
							onChange={(e) => setname(e.target.value)}
							required
						/>
					</Form.Group>
					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>{Globals.language.contacts.surnameForm[lang]}</Form.Label>
						<Form.Control
							type='text'
							placeholder={Globals.language.contacts.surnameFormPlaceholder[lang]}
							value={surname}
							onChange={(e) => setsurname(e.target.value)}
							required
						/>
					</Form.Group>
					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>{Globals.language.contacts.emailForm[lang]}</Form.Label>
						<Form.Control
							type='email'
							placeholder={Globals.language.contacts.emailFormPlaceholder[lang]}
							value={email}
							onChange={(e) => setemail(e.target.value)}
							required
						/>
					</Form.Group>
					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>Ragione sociale</Form.Label>
						<Form.Control
							type='text'
							placeholder={"Inserisci ragione sociale"}
							value={company}
							onChange={(e) => setcompany(e.target.value)}
						/>
					</Form.Group>
					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>{Globals.language.contacts.phoneForm[lang]}</Form.Label>
						<Form.Control
							type='phone'
							placeholder={Globals.language.contacts.phoneFormPlaceholder[lang]}
							value={phone}
							onChange={(e) => setphone(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>Marca auto</Form.Label>
						<Form.Control
							type='text'
							placeholder={"Inserisci marca auto"}
							value={brand}
							onChange={(e) => setBrand(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>Modello auto</Form.Label>
						<Form.Control
							type='text'
							placeholder={"Inserisci modello auto"}
							value={model}
							onChange={(e) => setModel(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group className='mb-3'>
						<Form.Label>Data preferita </Form.Label>
						<Form.Control
							type='date'
							value={bookingDate}
							onChange={(e) => setBookingDate(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group className='mb-3'>
						<Form.Label>Fascia oraria </Form.Label>
						<Form.Select onChange={(e) => setSchedule(e.target.value)}>
							{groupSchedule.map((item) => {
								return <option value={item.id}>{item.value}</option>;
							})}
						</Form.Select>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicPassword'>
						<Form.Label>Note </Form.Label>
						<Form.Control
							type='text'
							placeholder={"Inserisci note"}
							as='textarea'
							value={message}
							onChange={(e) => setmessage(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicCheckbox'>
						<Form.Check
							type='checkbox'
							label={Globals.language.contacts.privacyForm[lang]}
							onChange={(e) =>
								e.target.value === "on" ? setPrivacy(true) : setPrivacy(false)
							}
							required
						/>
					</Form.Group>
					<Form.Group className='mb-3' controlId='formBasicCheckbox'>
						<Form.Check
							type='checkbox'
							label={Globals.language.contacts.newsletterForm[lang]}
							onChange={(e) =>
								e.target.value === "on" ? setNewsletter(true) : setNewsletter(false)
							}
						/>
					</Form.Group>
					<Form.Group className='mb-3' controlId='formBasicCheckbox'>
						<Form.Check
							type='checkbox'
							label={"Desidero iscrivermi al servizio sms"}
							onChange={(e) =>
								e.target.value === "on" ? setSms(true) : setSms(false)
							}
						/>
					</Form.Group>

					<Form.Group className='position-relative' controlId='formBasicCheckbox'>
						<ReCaptchaV2
							callback={v2Callback}
							theme={EReCaptchaV2Theme.Light}
							size={EReCaptchaV2Size.Normal}
							id='my-id'
							data-test-id='my-test-id'
							tabindex={0}
						/>
					</Form.Group>

					{/* <Form.Group className='position-relative' controlId='formBasicCheckbox'>
					<ReCaptchaV2
						callback={v2Callback}
						theme={EReCaptchaV2Theme.Light}
						size={EReCaptchaV2Size.Normal}
						id='my-id'
						data-test-id='my-test-id'
						tabindex={0}
					/>
				</Form.Group> */}

					<Button
						className='d-flex justify-content-center m-auto'
						style={{
							backgroundColor: "#003187",
							border: "1px solid #003187",
						}}
						type='submit'>
						{Globals.language.contacts.sendButtonForm[lang]}
					</Button>
					{errMsg && <p> {errMsg} </p>}
				</Form>
			);
			break;

		// noleggio
		case 11:
			form = (
				<Form
					onSubmit={(e) => {
						handleSubmit(e, formId);
					}}>
					<Form.Group className='mb-3'>
						<Form.Label>Tipologia </Form.Label>

						<Form.Select onChange={(e) => setSelectedRentType(e.target.value)}>
							<option value={0} defaultChecked>
								Seleziona un'opzione
							</option>
							{typeRent?.map((item) => {
								return <option value={item.id}>{item.nome}</option>;
							})}
						</Form.Select>
					</Form.Group>

					<Form.Group className='mb-3'>
						<Form.Label>Inizio noleggio </Form.Label>

						<Form.Control
							type='date'
							placeholder={"Seleziona data noleggio"}
							value={startRent}
							onChange={(e) => setStartRent(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group className='mb-3'>
						<Form.Label>Fine noleggio </Form.Label>

						<Form.Control
							type='date'
							placeholder={"Seleziona data noleggio"}
							value={endRent}
							onChange={(e) => setEndRent(e.target.value)}
							required
						/>
					</Form.Group>

					{parseInt(selectRentType) !== 1 && (
						<Form.Group className='mb-3' controlId='formBasicEmail'>
							<Form.Label>Chilometraggio previsto</Form.Label>
							<Form.Control
								type='text'
								placeholder={"Inserisci il chilometraggio previsto"}
								value={expectedKm}
								onChange={(e) => setexpectedKm(e.target.value)}
								required
							/>
						</Form.Group>
					)}

					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>{Globals.language.contacts.nameForm[lang]}</Form.Label>
						<Form.Control
							type='text'
							placeholder={Globals.language.contacts.nameFormPlaceholder[lang]}
							value={name}
							onChange={(e) => setname(e.target.value)}
							required
						/>
					</Form.Group>
					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>{Globals.language.contacts.surnameForm[lang]}</Form.Label>
						<Form.Control
							type='text'
							placeholder={Globals.language.contacts.surnameFormPlaceholder[lang]}
							value={surname}
							onChange={(e) => setsurname(e.target.value)}
							required
						/>
					</Form.Group>
					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>{Globals.language.contacts.emailForm[lang]}</Form.Label>
						<Form.Control
							type='email'
							placeholder={Globals.language.contacts.emailFormPlaceholder[lang]}
							value={email}
							onChange={(e) => setemail(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>{Globals.language.contacts.phoneForm[lang]}</Form.Label>
						<Form.Control
							type='phone'
							placeholder={Globals.language.contacts.phoneFormPlaceholder[lang]}
							value={phone}
							onChange={(e) => setphone(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicCheckbox'>
						<Form.Check
							type='checkbox'
							label={Globals.language.contacts.privacyForm[lang]}
							onChange={(e) =>
								e.target.value === "on" ? setPrivacy(true) : setPrivacy(false)
							}
							required
						/>
					</Form.Group>
					<Form.Group className='mb-3' controlId='formBasicCheckbox'>
						<Form.Check
							type='checkbox'
							label={Globals.language.contacts.newsletterForm[lang]}
							onChange={(e) =>
								e.target.value === "on" ? setNewsletter(true) : setNewsletter(false)
							}
						/>
					</Form.Group>
					<Form.Group className='mb-3' controlId='formBasicCheckbox'>
						<Form.Check
							type='checkbox'
							label={"Desidero iscrivermi al servizio sms"}
							onChange={(e) =>
								e.target.value === "on" ? setSms(true) : setSms(false)
							}
						/>
					</Form.Group>

					<Form.Group className='position-relative' controlId='formBasicCheckbox'>
						<ReCaptchaV2
							callback={v2Callback}
							theme={EReCaptchaV2Theme.Light}
							size={EReCaptchaV2Size.Normal}
							id='my-id'
							data-test-id='my-test-id'
							tabindex={0}
						/>
					</Form.Group>

					<Button
						className='d-flex justify-content-center m-auto'
						style={{
							backgroundColor: "#003187",
							border: "1px solid #003187",
						}}
						type='submit'
						disabled={blockSend}>
						{Globals.language.contacts.sendButtonForm[lang]}
					</Button>
					{errMsg && <strong className='text-danger'> {errMsg} </strong>}
				</Form>
			);

			break;

		//richiesta semplice
		case 8:
			form = (
				<Form
					onSubmit={(e) => {
						handleSubmit(e, formId);
					}}>
					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>{Globals.language.contacts.nameForm[lang]}</Form.Label>
						<Form.Control
							type='text'
							placeholder={Globals.language.contacts.nameFormPlaceholder[lang]}
							value={name}
							onChange={(e) => setname(e.target.value)}
							required
						/>
					</Form.Group>
					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>{Globals.language.contacts.surnameForm[lang]}</Form.Label>
						<Form.Control
							type='text'
							placeholder={Globals.language.contacts.surnameFormPlaceholder[lang]}
							value={surname}
							onChange={(e) => setsurname(e.target.value)}
							required
						/>
					</Form.Group>
					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>{Globals.language.contacts.emailForm[lang]}</Form.Label>
						<Form.Control
							type='email'
							placeholder={Globals.language.contacts.emailFormPlaceholder[lang]}
							value={email}
							onChange={(e) => setemail(e.target.value)}
							required
						/>
					</Form.Group>
					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>Ragione sociale</Form.Label>
						<Form.Control
							type='text'
							placeholder={"Inserisci ragione sociale"}
							value={company}
							onChange={(e) => setcompany(e.target.value)}
						/>
					</Form.Group>
					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>{Globals.language.contacts.phoneForm[lang]}</Form.Label>
						<Form.Control
							type='phone'
							placeholder={Globals.language.contacts.phoneFormPlaceholder[lang]}
							value={phone}
							onChange={(e) => setphone(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group className='mb-3' controlId='formBasicPassword'>
						<Form.Label>Note </Form.Label>
						<Form.Control
							type='text'
							placeholder={"Inserisci eventuali note"}
							as='textarea'
							value={message}
							onChange={(e) => setmessage(e.target.value)}
							required
						/>
					</Form.Group>
					<Form.Group className='mb-3' controlId='formBasicCheckbox'>
						<Form.Check
							type='checkbox'
							label={Globals.language.contacts.privacyForm[lang]}
							onChange={(e) =>
								e.target.value === "on" ? setPrivacy(true) : setPrivacy(false)
							}
							required
						/>
					</Form.Group>
					<Form.Group className='mb-3' controlId='formBasicCheckbox'>
						<Form.Check
							type='checkbox'
							label={Globals.language.contacts.newsletterForm[lang]}
							onChange={(e) =>
								e.target.value === "on" ? setNewsletter(true) : setNewsletter(false)
							}
						/>
					</Form.Group>
					<Form.Group className='mb-3' controlId='formBasicCheckbox'>
						<Form.Check
							type='checkbox'
							label={"Desidero iscrivermi al servizio sms"}
							onChange={(e) =>
								e.target.value === "on" ? setSms(true) : setSms(false)
							}
						/>
					</Form.Group>

					<Form.Group className='position-relative' controlId='formBasicCheckbox'>
						<ReCaptchaV2
							callback={v2Callback}
							theme={EReCaptchaV2Theme.Light}
							size={EReCaptchaV2Size.Normal}
							id='my-id'
							data-test-id='my-test-id'
							tabindex={0}
						/>
					</Form.Group>

					<Button
						className='d-flex justify-content-center m-auto'
						style={{
							backgroundColor: "#003187",
							border: "1px solid #003187",
						}}
						type='submit'>
						{Globals.language.contacts.sendButtonForm[lang]}
					</Button>
					{errMsg && <p> {errMsg} </p>}
				</Form>
			);
			break;
		default:
			form = <div> </div>;
			break;
	}

	return (
		<>
			{loading ? (
				<Loading />
			) : success ? (
				// in caso di successo =>
				<div className='p-2 text-center'>
					{" "}
					<Card style={{fontSize: "25px"}}>
						<i
							className='fa fa-check-circle'
							style={{fontSize: "50px", color: "green"}}></i>
						{Globals.language.contacts.sendSuccess[lang]}
						<div>{Globals.language.contacts.sendTextSuccess[lang]}</div>
					</Card>
				</div>
			) : (
				form
			)}
		</>
	);
}

export default FormRequest;
