import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import Loading from "../components/Loading";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Globals from "../parameters/globals";
import "../scss/contact.scss";
import { useLocation } from "react-router-dom";
import {
	EReCaptchaV2Size,
	EReCaptchaV2Theme,
	ReCaptchaV2,
} from "react-recaptcha-x";
import { useContext } from "react";
import AuthProvider from "../context/AuthProvider";

function Contact(props) {
  const [name, setname] = useState("");
  const [surname, setsurname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [IsCompany, setIsCompany] = useState(false);
  const [message, setmessage] = useState("");
  const [privacy, setPrivacy] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const [is_robot, set_is_robot] = useState(false);
  const [is_robot_general, set_is_robot_general] = useState(false);
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [curriculum, setCurriculum] = useState();

  const [success, setSuccess] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const [schedule, setSchedule] = useState([]);
  const { lang } = useContext(AuthProvider);

  // const getMapsAndSchedule = async () => {
  // 	try {
  // 		const response = await axios.get(Globals.GETWORKSCHEDULE + "&lang=" + lang);
  // 		setSchedule(response.data.work_schedule);
  // 	} catch (err) {
  // 		console.log(err);
  // 	}
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let derivazione = props.general ? "Homepage" : props.from;

    if (token === "") {
    	setErrMsg(Globals.language.errorLabel.acceptRecaptcha[lang]);
    	return false;
    }

    try {
      setLoading(true);
      let body = {
        name: name,
        surname: surname,
        email: email,
        phone: phone,
        IsCompany: IsCompany === "true" ? true : false,
        message: message,
        privacy: privacy,
        newsletter: newsletter,
        derivazione: derivazione,
        curriculum: props.lavora
          ? {
              name: curriculum.name,
              size: curriculum.size,
              type: curriculum.type,
            }
          : "",
        token: token,
      };
      let formdata = new FormData();

      formdata.append("body", JSON.stringify(body));
      if (props.lavora) {
        formdata.append("curriculum", curriculum);
      }

      if (props.lavora) {
        var response = await axios.post(
          Globals.WORKWITHUS + "&lang=" + lang,
          formdata,
          {
            headers: { "Content-Type": "multipart/form-data" },
            withCredentials: true,
          }
        );
      } else {
        var response = await axios.post(
          Globals.CONTACT + "&lang=" + lang,
          formdata
          // {
          // 	headers: { "Content-Type": "application/json" },
          // 	withCredentials: true,
          // }
        );
      }
      // TODO: remove console.logs before deployment
      // console.log(JSON.stringify(response?.data));
      //console.log(JSON.stringify(response))

      if (response?.data?.error === true) {
        setErrMsg(response.data.message);

        setLoading(false);
        setSuccess(false);
      } else {
        //clear state and controlled inputs
        setLoading(false);
        setSuccess(true);
      }
    } catch (err) {
      setLoading(false);
      setSuccess(false);
      setErrMsg(Globals.language.errorLabel.serverError[lang]);
    }
  };

  // useEffect(() => {
  // if (location.pathname === "/") {
  // 	set_is_robot_general(true);
  // } else {
  // 	set_is_robot(true);
  // }

  // if (props.general === true) {
  // 	getMapsAndSchedule();
  // }
  // return () => {
  // 	set_is_robot(false);
  // 	set_is_robot_general(false);
  // };
  // }, [lang]);

  const v2Callback = (token) => {
  	if (typeof token === "string") {
  		setToken(token);
  	} else if (typeof token === "boolean" && !token) {
  		setErrMsg(Globals.language.errorLabel.recaptchaExpired[lang]);
  		setToken("");
  	} else if (token instanceof Error) {
  		setErrMsg(Globals.language.errorLabel.serverError[lang]);
  		setToken("");
  	}
  };

  if (props.general) {
    return (
      <div className="container-fluid py-3" id="contact">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm text-start">
              <h2 className="divider donotcross" contenteditable>
                Contattaci
              </h2>
              {/* <h1>Contattaci </h1>
              <p className="title-fascia-officina"></p> */}
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 col-sm-12">
              {loading ? (
                <Loading />
              ) : success ? (
                <div className="p-2">
                  {" "}
                  <Card style={{ fontSize: "25px", textAlign: "center" }}>
                    <i
                      className="fa fa-check-circle"
                      style={{ fontSize: "50px", color: "green" }}
                    ></i>
                    <h2>{Globals.language.contacts.sendSuccess[lang]}</h2>
                    <div>{Globals.language.contacts.sendTextSuccess[lang]}</div>
                  </Card>
                </div>
              ) : (
                <Form
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                >
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      {Globals.language.contacts.nameForm[lang]}
                    </Form.Label>
                    <Form.Control
                      type="name"
                      placeholder={
                        Globals.language.contacts.nameFormPlaceholder[lang]
                      }
                      value={name}
                      onChange={(e) => setname(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      {Globals.language.contacts.surnameForm[lang]}
                    </Form.Label>
                    <Form.Control
                      type="surname"
                      placeholder={
                        Globals.language.contacts.surnameFormPlaceholder[lang]
                      }
                      value={surname}
                      onChange={(e) => setsurname(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      {Globals.language.contacts.emailForm[lang]}
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder={
                        Globals.language.contacts.emailFormPlaceholder[lang]
                      }
                      value={email}
                      onChange={(e) => setemail(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      {Globals.language.contacts.phoneForm[lang]}
                    </Form.Label>
                    <Form.Control
                      type="phone"
                      placeholder={
                        Globals.language.contacts.phoneFormPlaceholder[lang]
                      }
                      value={phone}
                      onChange={(e) => setphone(e.target.value)}
                      required
                    />
                  </Form.Group>
                  {/* <Form.Group className="mb-3">
                    <Form.Select onChange={(e) => setIsCompany(e.target.value)}>
                      <option value={false}>
                        {Globals.language.contacts.privateForm[lang]}
                      </option>
                      <option value={true}>
                        {Globals.language.contacts.companyForm[lang]}
                      </option>
                    </Form.Select>
                  </Form.Group> */}
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>
                      {Globals.language.contacts.messaggeForm[lang]}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      as="textarea"
                      placeholder={
                        Globals.language.contacts.messaggeFormPlaceholder[lang]
                      }
                      value={message}
                      onChange={(e) => setmessage(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      label={Globals.language.contacts.privacyForm[lang]}
                      onChange={(e) =>
                        e.target.value === "on"
                          ? setPrivacy(true)
                          : setPrivacy(false)
                      }
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      label={Globals.language.contacts.newsletterForm[lang]}
                      onChange={(e) =>
                        e.target.value === "on"
                          ? setNewsletter(true)
                          : setNewsletter(false)
                      }
                    />
                  </Form.Group>

                  <Form.Group
										className='position-relative'
										controlId='formBasicCheckbox'>
										<ReCaptchaV2
											callback={v2Callback}
											theme={EReCaptchaV2Theme.Light}
											size={EReCaptchaV2Size.Normal}
											id='my-id'
											data-test-id='my-test-id'
											tabindex={0}
										/>
									</Form.Group>

                  <Button
                    // variant="danger"
                    className="d-flex justify-content-center m-auto my-2"
                    style={{
                      backgroundColor: "#003187",
                      border: "1px solid #003187",
                    }}
                    type="submit"
                  >
                    {Globals.language.contacts.sendButtonForm[lang]}
                  </Button>
                  {errMsg && <p> {errMsg} </p>}
                </Form>
              )}
            </div>

            <div className="col-xl-6 col-sm-12 ">
              <div className="card my-2 mx-1 color-card-contact w-100">
                <div className="text-center">
                  <h3>AREE DI CONTATTO </h3>
                </div>

                <div className="my-5 responsive-information-contact">
                  <div className="text-center">
                    <i
                      className="fa fa-geo-alt"
                      style={{ fontSize: "30px", color: "#cd3426" }}
                    ></i>
                  </div>
                  <div className="col-md-4">
                    <article className="border-about-us min-vh-25">
                      <div className="text-start mx-3">
                        <strong> AREA DIREZIONALE </strong>
                        <p>
                          <a
                            className="btn btn-link p-0"
                            href="mailto:info@lepanto.it"
                          >
                            {" "}
                            info@lepanto.it
                          </a>
                        </p>
                        <strong>COMMERCIALE </strong>
                        <p className="mb-0">Mirko</p>
                        <p className="mb-0">Andrea</p>
                        <p>
                          <a
                            className="btn btn-link p-0"
                            href="mailto:lepanto@lepanto.it"
                          >
                            {" "}
                            lepanto@lepanto.it
                          </a>
                        </p>
                        <strong>AMMINISTRAZIONE </strong>
                        <p className="mb-0">Sonia</p>
                        <p className="mb-0">Nadia</p>
                        <p className="mb-0">Monica</p>
                        <p className="mb-0">Chiara</p>
                        <p>
                          <a
                            className="btn btn-link p-0"
                            href="mailto:amministrazione@lepanto.it"
                          >
                            {" "}
                            amministrazione@lepanto.it
                          </a>
                        </p>
                      </div>
                    </article>
                  </div>
                  <div className="col-md-4">
                    <article className=" border-about-us min-vh-25">
                      <div className="text-start mx-3">
                        <strong>DEPOSITO GIUDIZIARIO </strong>
                        <p className="mb-0">Fabio</p>
                        <p>
                          <a
                            className="btn btn-link p-0"
                            href="mailto:lepanto2@lepanto.it"
                          >
                            {" "}
                            lepanto2@lepanto.it
                          </a>
                        </p>
                        <strong>AREA ASSISTENZA 24H</strong>
                        <p className="mb-0">Daniele</p>
                        <p>
                          <a
                            className="btn btn-link p-0"
                            href="mailto:filiale@lepanto.it"
                          >
                            {" "}
                            filiale@lepanto.it
                          </a>
                        </p>
                        <strong>FRONTOFFICE</strong>
                        <div className="d-flex">
                          <div className="col-md-6">
                            <p className="mb-0">Cinzia</p>
                            <p>
                              <a
                                className="btn btn-link p-0"
                                href="mailto:frontoffice@lepanto.it"
                              >
                                {" "}
                                frontoffice@lepanto.it
                              </a>
                            </p>
                          </div>
                          <div className="col-md-6"></div>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="text-center">
                    {/* col-md-1  */}
                    <i
                      className="bi bi-clock"
                      style={{ fontSize: "30px", color: "#cd3426" }}
                    ></i>
                  </div>
                  <div className="col-md-4">
                    <article className="border-about-us min-vh-25">
                      <div className="text-start mx-3 ">
                        <strong>OFFICINA CARROZZERIA </strong>

                        <p className="mb-0">Marco</p>
                        <p className="mb-0">Nadia</p>
                        <p className="mb-0">Mario</p>
                        <p>
                          <a
                            className="btn btn-link p-0"
                            href="mailto:officina@lepanto.it"
                          >
                            {" "}
                            officina@lepanto.it
                          </a>
                          <a
                            className="btn btn-link p-0"
                            href="mailto:carrozzeria@lepanto.it"
                          >
                            {" "}
                            carrozzeria@lepanto.it
                          </a>
                          <a
                          style={{maxWidth:"180px"}}
                            className="btn btn-link p-0"
                            href="mailto:assistenzaflotte@lepanto.it "
                          >
                            {" "}
                            assistenzaflotte@lepanto.it
                          </a>
                        </p>
                        <p>
                          <strong> FORNITORI </strong>
                          <p className="mb-0">Andrea</p>
                          <p>
                            <a
                              className="btn btn-link p-0"
                              href="mailto:lepanto@lepanto.it"
                            >
                              {" "}
                              lepanto@lepanto.it
                            </a>
                          </p>
                        </p>
                        <p>
                          <strong> SOCCORSO 24h </strong>
                          <p className="mb-0">Ali</p>
                          <p>
                            <a
                              className="btn btn-link p-0"
                              href="mailto:filiale@lepanto.it"
                            >
                              {" "}
                              filiale@lepanto.it
                            </a>
                          </p>
                        </p>
                        <p>
                          <strong> SERVIZIO GRU </strong>
                          <p className="mb-0"> Daniele</p>
                          <p>
                            <a
                              className="btn btn-link p-0"
                              href="mailto:filiale@lepanto.it"
                            >
                              {" "}
                              filiale@lepanto.it
                            </a>
                          </p>
                        </p>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
              <div>
                <h1>Dove siamo </h1>
                <p className="title-fascia-officina"></p>
              </div>
              <div
                id="map-container-google-1"
                className="z-depth-1-half map-container mb-2"
                style={{ height: "500px" }}
              >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d390.0909651232467!2d10.961072620326537!3d45.40567082186852!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4781e1a1a940a671%3A0xaff7a8dc01fb294e!2sLepanto%202%20srl%20%E2%80%93%20Officina%20Carrozzeria%20auto%20Verona!5e0!3m2!1sit!2sit!4v1681380497595!5m2!1sit!2sit"
                  width="600"
                  height="450"
                  frameborder="0"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  aria-hidden="false"
                  tabindex="0"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (props.lavora) {
    return (
      <>
        {loading ? (
          <Loading />
        ) : success ? (
          <div className="p-2">
            {" "}
            <Card style={{ fontSize: "25px" }}>
              <i
                className="fa fa-check-circle text-center"
                style={{ fontSize: "50px", color: "green" }}
              ></i>
              <div className="text-center">
              <h2>{Globals.language.contacts.sendSuccess[lang]}</h2>
              <div>{Globals.language.contacts.sendTextSuccess[lang]}.</div>
              </div>
            </Card>
          </div>
        ) : (
          <Form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                {Globals.language.contacts.nameForm[lang]}
              </Form.Label>
              <Form.Control
                type="name"
                placeholder={
                  Globals.language.contacts.nameFormPlaceholder[lang]
                }
                value={name}
                onChange={(e) => setname(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                {Globals.language.contacts.surnameForm[lang]}
              </Form.Label>
              <Form.Control
                type="surname"
                placeholder={
                  Globals.language.contacts.surnameFormPlaceholder[lang]
                }
                value={surname}
                onChange={(e) => setsurname(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                {Globals.language.contacts.emailForm[lang]}
              </Form.Label>
              <Form.Control
                type="email"
                placeholder={
                  Globals.language.contacts.emailFormPlaceholder[lang]
                }
                value={email}
                onChange={(e) => setemail(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                {Globals.language.contacts.phoneForm[lang]}
              </Form.Label>
              <Form.Control
                type="phone"
                placeholder={
                  Globals.language.contacts.phoneFormPlaceholder[lang]
                }
                value={phone}
                onChange={(e) => setphone(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Select onChange={(e) => setIsCompany(e.target.value)}>
                <option value={false}>
                  {Globals.language.contacts.privateForm[lang]}
                </option>
                <option value={true}>
                  {Globals.language.contacts.companyForm[lang]}
                </option>
              </Form.Select>
            </Form.Group>
            <div>
              <Form.Control
                type="file"
                name="curriculum"
                id="curriculum"
                accept="application/pdf"
                data-placeholder={
                  Globals.language.contacts.nameFormCurriculum[lang]
                }
                onChange={(e) => setCurriculum(e.target.files[0])}
                required
              />
            </div>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>
                {Globals.language.contacts.messaggeForm[lang]}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={
                  Globals.language.contacts.messaggeFormPlaceholder[lang]
                }
                as="textarea"
                value={message}
                onChange={(e) => setmessage(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label={Globals.language.contacts.privacyForm[lang]}
                onChange={(e) =>
                  e.target.value === "on" ? setPrivacy(true) : setPrivacy(false)
                }
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label={Globals.language.contacts.newsletterForm[lang]}
                onChange={(e) =>
                  e.target.value === "on"
                    ? setNewsletter(true)
                    : setNewsletter(false)
                }
              />
            </Form.Group>

            <Form.Group className='position-relative' controlId='formBasicCheckbox'>
							<ReCaptchaV2
								callback={v2Callback}
								theme={EReCaptchaV2Theme.Light}
								size={EReCaptchaV2Size.Normal}
								id='my-id'
								data-test-id='my-test-id'
								tabindex={0}
							/>
						</Form.Group>

            <Button
              className="d-flex justify-content-center m-auto"
              style={{
                backgroundColor: "#003187",
                border: "1px solid #003187",
              }}
              type="submit"
            >
              {Globals.language.contacts.sendButtonForm[lang]}
            </Button>
            {errMsg && <p> {errMsg} </p>}
          </Form>
        )}
      </>
    );
  } else {
    return (
      <>
        {loading ? (
          <Loading />
        ) : success ? (
          <div className="p-2">
            {" "}
            <Card style={{ fontSize: "25px", textAlign: "center" }}>
              <i
                className="fa  fa-check-circle"
                style={{ fontSize: "50px", color: "green" }}
              ></i>
              <h2>{Globals.language.contacts.sendSuccess[lang]}</h2>
              <div>{Globals.language.contacts.sendTextSuccess[lang]}</div>
            </Card>
          </div>
        ) : (
          <Form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <div className="d-flex ">
              <Form.Group className="mb-1 col-6 " controlId="formBasicEmail">
                <Form.Label>
                  {Globals.language.contacts.nameForm[lang]}
                </Form.Label>
                <Form.Control
                  type="name"
                  placeholder={
                    Globals.language.contacts.nameFormPlaceholder[lang]
                  }
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group
                className="mb-1 col-6 mx-1"
                controlId="formBasicEmail"
              >
                <Form.Label>
                  {Globals.language.contacts.surnameForm[lang]}
                </Form.Label>
                <Form.Control
                  type="surname"
                  placeholder={
                    Globals.language.contacts.surnameFormPlaceholder[lang]
                  }
                  value={surname}
                  onChange={(e) => setsurname(e.target.value)}
                  required
                />
              </Form.Group>
            </div>
            <div className="d-flex ">
            <Form.Group className=" col-6 ">
              <Form.Label>
                {Globals.language.contacts.emailForm[lang]}
              </Form.Label>
              <Form.Control
                type="email"
                placeholder={
                  Globals.language.contacts.emailFormPlaceholder[lang]
                }
                value={email}
                onChange={(e) => setemail(e.target.value)}
                required
              />
              </Form.Group>
              <Form.Group className="mb-1 col-6 mx-1" controlId="formBasicEmail">
                <Form.Label>
                  {Globals.language.contacts.phoneForm[lang]}
                </Form.Label>
                <Form.Control
                  type="phone"
                  placeholder={
                    Globals.language.contacts.phoneFormPlaceholder[lang]
                  }
                  value={phone}
                  onChange={(e) => setphone(e.target.value)}
                  required
                />
              </Form.Group>
             
            </div>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>
                {Globals.language.contacts.messaggeForm[lang]}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={
                  Globals.language.contacts.messaggeFormPlaceholder[lang]
                }
                as="textarea"
                value={message}
                onChange={(e) => setmessage(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label={Globals.language.contacts.privacyForm[lang]}
                onChange={(e) =>
                  e.target.value === "on" ? setPrivacy(true) : setPrivacy(false)
                }
                required
              />
            </Form.Group>
            {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label={Globals.language.contacts.newsletterForm[lang]}
                onChange={(e) =>
                  e.target.value === "on"
                    ? setNewsletter(true)
                    : setNewsletter(false)
                }
              />
            </Form.Group> */}

            <Form.Group className='position-relative' controlId='formBasicCheckbox'>
							<ReCaptchaV2
								callback={v2Callback}
								theme={EReCaptchaV2Theme.Light}
								size={EReCaptchaV2Size.Normal}
								id='my-id'
								data-test-id='my-test-id'
								tabindex={0}
							/>
						</Form.Group>

            <Button
              className="d-flex justify-content-center m-auto my-2"
              style={{
                backgroundColor: "#003187",
                border: "1px solid #003187",
              }}
              type="submit"
            >
              {Globals.language.contacts.sendButtonForm[lang]}
            </Button>
            {errMsg && <p> {errMsg} </p>}
          </Form>
        )}
      </>
    );
  }
}
export default Contact;
