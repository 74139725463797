import {useEffect, useContext, useState} from "react";
import {Routes, Route, useLocation} from "react-router-dom";
import Homepage from "./pages/Homepage";
import Navbar from "./components/Navbar";
import ScrollButton from "./components/ScrollButton";
import CarSale from "./pages/CarSale";
import CarDetails from "./pages/CarDetails";
import Footer from "./components/Footer";
import {ReCaptchaProvider} from "react-recaptcha-x";
import Globals from "./parameters/globals";
import ErrorPage from "./components/ErrorPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CondizioniUtilizzo from "./pages/CondizioniUtilizzo";
import CondizioniAcquisto from "./pages/CondizioniAcquisto";
import Credits from "./pages/Credits";
import Disclaimer from "./pages/Disclaimer";
import Cookiebar from "./components/Cookiebar";
import MyLepanto from "./pages/MyLepanto";
import Login from "./pages/Login";
import RequireAuth from "./components/RequireAuth";
import axios from "axios";
import AuthContext from "./context/AuthProvider";
import AdminArea from "./pages/AdminArea";
import Register from "./pages/Register";
import AccountActivated from "./pages/AccountActivated";
import CertificatoRina from "./pages/CertificatoRina";
import Banner from "./components/Banner";
import { Helmet } from 'react-helmet';
function modifyCookie() {
	document.getElementById("container").style.display = "block";
}

function App() {
	const {setAuth} = useContext(AuthContext);
	const [redirectList, setRedirectList] = useState("");
	const location = useLocation();

	const getRedirectList = async () => {
		try {
			const response = await axios.get(Globals.CHECKREDIRECT301, {
				headers: {"Content-Type": "application/json"},
				withCredentials: true,
			});
			setRedirectList(response?.data?.redirect);
		} catch (err) {
			setRedirectList("");
		}
	};

	const checkRedirectList = async () => {
		if (redirectList.length > 0 && redirectList !== "") {
			let redirect_element =
				redirectList.find(
					(element) => element?.old_url === window.location.href,
				) !== undefined
					? redirectList.find((element) => element?.old_url === window.location.href)
					: false;

			if (redirect_element !== false) {
				updateRedirectTable(redirect_element);
			}
		}
	};

	const updateRedirectTable = async (redirect_element) => {
		try {
			let formdata = new FormData();
			let body = {
				id: redirect_element.id,
			};

			formdata.append("body", JSON.stringify(body));
			const response = await axios.post(Globals.UPDATEREDIRECTCOUNTER, formdata, {
				withCredentials: true,
			});

			if (response?.data?.error === false) {
				window.location.replace(redirect_element.new_url);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const ROLES = {
		User: "privato",
		Admin: "admin",
		SuperAdmin: "superadmin",
		Commerciante: "commerciante",
	};
	useEffect(() => {
		checkRedirectList();
	}, [location, redirectList]);

	const checkLogin = async () => {
		let myAccessToken = localStorage.getItem("accessToken");
		if (myAccessToken !== "") {
			try {
				let formdata = new FormData();
				formdata.append("body", myAccessToken);

				const response = await axios.post(Globals.CHECKLOGIN, formdata, {
					withCredentials: true,
				});

				let accessToken = myAccessToken;
				let roles = response?.data?.roles;
				let user = response?.data?.username;
				setAuth({user, accessToken, roles});
			} catch (err) {
				setAuth({});
				localStorage.setItem("accessToken", "");
			}
		}
	};

	useEffect(() => {
		checkLogin();
		getRedirectList();
	}, []);

	return (			  
		<ReCaptchaProvider
			siteKeyV2={Globals.recaptcha_v2_key}
			langCode='it'
			hideV3Badge={false}>
			<>
				<Navbar />
				<Banner message={"È ora di cambiare le ruote"} />
				<Routes>
					<Route path='/' element={<Homepage />} />
					<Route path='vendita' element={<CarSale />} />
					<Route path='/home' element={<Homepage />} />
					<Route path='privacy-policy' element={<PrivacyPolicy />} />
					<Route path='condizioni-utilizzo' element={<CondizioniUtilizzo />} />
					<Route path='condizioni-acquisto' element={<CondizioniAcquisto />} />
					<Route path='credits' element={<Credits />} />
					<Route path='disclaimer' element={<Disclaimer />} />
					<Route path='certificazioni' element={<CertificatoRina />} />

					<Route path='login' element={<Login />} />

					<Route path='dettaglio-auto'>
						<Route path=':id/:slug/:type' element={<CarDetails />} />
					</Route>
					<Route path='*' element={<ErrorPage />} />

					<Route
						element={<RequireAuth allowedRoles={[ROLES.User, ROLES.Commerciante]} />}>
						<Route path='my-lepanto' element={<MyLepanto />} />
					</Route>
					<Route
						element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.SuperAdmin]} />}>
						<Route path='admin-area' element={<AdminArea />} />
					</Route>
					<Route path='register' element={<Register />} />
					<Route path='/account-attivato' element={<AccountActivated />} />
				</Routes>

				<ScrollButton />
				<Cookiebar />
				<Footer modifyCookie={modifyCookie.bind()} />
			</>
		</ReCaptchaProvider>

	);
}

export default App;
