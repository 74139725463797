import React, { useState, useEffect, useContext } from "react";
import "../scss/footer.scss";
import Globals from "../parameters/globals";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import AuthContext from "../context/AuthProvider";
import axios from "axios";
import useWindowSize from "use-window-size-v2";

function Footer(props) {
  const { auth } = useContext(AuthContext);
  const { width, height } = useWindowSize(100); // wait 100ms for the resize events

  let modifyCookie = props.modifyCookie || [];
  const [activeCookiebar, setActiveCookiebar] = useState(0);

  const getCookiebar = async () => {
    try {
      const response = await axios.get(Globals.GETFOOTERCOOKIE, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      setActiveCookiebar(parseInt(response.data.active_cookie[0].cookie_bar));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCookiebar();
  }, []);
  return (
    <div style={{ backgroundColor: "#f4f4f4" }}>
      {width > 1300 ? (
        <div className="py-3 container-fluid">
          <a
            href="mailto:info@lepanto.it"
            target="_blank"
            className="btn-email-pulse-fix"
          >
            <i className="fa fa-envelope-o"></i>
          </a>
          <a
            href="tel:+39045513777"
            target="_blank"
            className="btn-phone-pulse-fix"
          >
            <i className="fa fa-phone"></i>
          </a>
          <a
            href="tel:+39045500852"
            target="_blank"
            className="btn-sos-pulse-fix"
          >
            SOS
          </a>
          <a
            href="https://wa.me/+393403725000"
            target="_blank"
            className="btn-whatsapp-pulse-fix"
          >
            <i className="fa fa-whatsapp"></i>
          </a>

          <a
            href="tel:+39045500852"
            target="_blank"
            className="btn-sos-pulse-fix"
          >
            SOS
          </a>
        </div>
      ) : (
        <div class="wrapper-call">
          <input type="checkbox" />
          <div class="fab">
            <div className="d-flex">
              {/* Per tornare alla visualizzazione con la + bsta commentare fino a riga 97 e aggiustare le proporzioni con il css  */}

              <div className="d-flex curve-ico">
                <div className="col-4 hamburger-1 mt-3">
                  <i className="fa fa-info" style={{ fontSize: "16px" }}></i>
                </div>
                <div className="col-4 hamburger-2 mt-0">
                  <i className="fa fa-phone" style={{ fontSize: "16px" }}></i>
                </div>
                <div className="col-4 hamburger-3 mt-3">
                  <i
                    className="fa fa-envelope-o"
                    style={{ fontSize: "16px" }}
                  ></i>
                </div>
              </div>
              <div>
                <div className="col-12 mt-4">
                  <i
                    className="fa fa-whatsapp"
                    style={{ fontSize: "30px" }}
                  ></i>
                </div>
              </div>
            </div>
          </div>

          {/* <div class="fab"></div> */}

          <div class="fac">
            <a
              href="https://wa.me/+393403725000"
              target="_blank"
              className="btn-whatsapp-pulse-fix"
            >
              <i className="fa fa-whatsapp"></i>
            </a>

            <a
              href="tel:045 513 777"
              target="_blank"
              className="btn-phone-pulse-fix"
            >
              <i className="fa fa-phone"></i>
            </a>

            <a
              href="tel:+39045500852"
              target="_blank"
              className="btn-sos-pulse-fix"
            >
              SOS
            </a>

            <a
              href="mailto:info@lepanto.it"
              target="_blank"
              className="btn-email-pulse-fix"
            >
              <i className="fa fa-envelope-o"></i>
            </a>
          </div>
        </div>
      )}
      <div className="container-fluid p-0">
        <footer
          className="text-white text-center text-lg-center"
          style={{ backgroundColor: "#003187" }}
        >
          <div className="container-fluid py-2">
            <div className="row mt-4">
              <div className="col-lg-4 col-md-12 mb-4 mb-md-0">
                <h5 className="text-uppercase mb-4">Lepanto 2 Srl</h5>

                <p>
                  Officina, carrozzeria, soccorso stradale e vendita auto a
                  Verona.
                </p>

                <div className="mt-4">
                  <a
                    onClick={() =>
                      window.open("https://www.facebook.com/lepanto2")
                    }
                    style={{ cursor: "pointer" }}
                    type="button"
                    className="btn btn-floating btn-primary btn-lg mx-2"
                  >
                    <i className="fa fa-facebook-f"></i>
                  </a>
                  <a
                    onClick={() =>
                      window.open("https://www.instagram.com/lepanto_2srl/")
                    }
                    style={{ cursor: "pointer" }}
                    type="button"
                    className="btn btn-floating btn-primary btn-lg mx-2"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                  <a
                    onClick={() =>
                      window.open(
                        "https://www.linkedin.com/company/lepanto-2-srl/"
                      )
                    }
                    style={{ cursor: "pointer" }}
                    type="button"
                    className="btn btn-floating btn-primary btn-lg mx-2"
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                  <a
                    onClick={() =>
                      window.open(
                        "https://www.youtube.com/channel/UCnv4xYNsTHoX4Y47lZxWpvA"
                      )
                    }
                    style={{ cursor: "pointer" }}
                    type="button"
                    className="btn btn-floating btn-primary btn-lg mx-2"
                  >
                    <i className="fa fa-youtube"></i>
                  </a>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
                <h5 className="text-uppercase mb-4 pb-1 text-center">
                  Contatti rapidi
                </h5>
                <div className="d-flex flex-column justufy-content-center align-content-center align-items-center">
                  <ul className="fa-ul " style={{ marginLeft: "1.65em" }}>
                    <li className="mb-3">
                      <span className="fa-li">
                        <i className="fa fa-home"></i>
                      </span>
                      <span className="ms-0">
                        LEPANTO 2 srl Via Enrico Fermi, 31, angolo Strada La
                        Rizza 37136 Verona, VR
                      </span>
                    </li>
                    <li className="mb-3">
                      <span className="fa-li">
                        <i className="fa fa-envelope"></i>
                      </span>
                      <a
                        style={{ cursor: "pointer", textDecoration: "none" }}
                        className="text-light"
                        href="mailto:info@lepanto.it"
                      >
                        <span className="ms-0">info@lepanto.it</span>
                      </a>
                    </li>
                    <li className="mb-3">
                      <span className="fa-li">
                        <i className="fa fa-phone"></i>
                      </span>
                      <a
                        style={{ cursor: "pointer", textDecoration: "none" }}
                        className="text-light"
                        href="tel:+39 045 513 777"
                      >
                        <span className="ms-0">+39 045 513 777 </span>
                      </a>
                    </li>
                    {/* <li className="mb-3">
                    <span className="fa-li">
                      <i className="fa fa-print"></i>
                    </span>
                    <span className="ms-2">+ 01 234 567 89</span>
                  </li> */}
                  </ul>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 mb-4 mb-md-0 text-center">
                <div className="d-flex flex-column justufy-content-center align-content-center align-items-center">
                  {/* <table className="table text-center text-white">
                  <tbody className="font-weight-normal">
                    <tr>
                      <td>Lun - Mar:</td>
                      <td>8am - 9pm</td>
                    </tr>
                    <tr>
                      <td>Ven - Sab:</td>
                      <td>8am - 1am</td>
                    </tr>
                    <tr>
                      <td>Domenica:</td>
                      <td>9am - 10pm</td>
                    </tr>
                  </tbody>
                </table> */}
                  <ul className="fa-ul">
                    <li className="mb-1">
                      <Link
                        to="privacy-policy"
                        style={{ textDecoration: "none" }}
                      >
                        <span className="ms-2 text-light">Privacy</span>
                      </Link>
                    </li>
                    <Link
                      to="condizioni-utilizzo"
                      style={{ textDecoration: "none" }}
                    >
                      <li className="mb-1">
                        <span className="ms-2 text-light">
                          {" "}
                          Condizioni d'utilizzo
                        </span>
                      </li>
                    </Link>
                    <Link
                      to="condizioni-acquisto"
                      style={{ textDecoration: "none" }}
                    >
                      <li className="mb-1">
                        <span className="ms-2 text-light">
                          Condizioni d'acquisto
                        </span>
                      </li>
                    </Link>
                    <Link to="disclaimer" style={{ textDecoration: "none" }}>
                      <li className="mb-1">
                        <span className="ms-2 text-light">
                          Email disclaimer
                        </span>
                      </li>
                    </Link>
                    <Link to="credits" style={{ textDecoration: "none" }}>
                      <li className="mb-1">
                        <span className="ms-2 text-light"> Credits</span>
                      </li>
                    </Link>
                    <Link
                      // to="certificato-rina"
                      to="certificazioni"
                      style={{ textDecoration: "none" }}
                    >
                      <li className="mb-1">
                        <span className="ms-2 text-light">
                          {" "}
                          {/* Certificato RINA */}
                          Certificazioni
                        </span>
                      </li>
                    </Link>

                    {Object.keys(auth).length !== 0 &&
                      (auth?.roles === "admin" ||
                        auth?.roles === "superadmin") && (
                        <li className="mb-1">
                          <span className="ms-2 ">
                            {" "}
                            <a
                              href={Globals.loginUrl}
                              className="text-light"
                              style={{ textDecoration: "none" }}
                              target="_blank"
                            >
                              {" "}
                              Login <i className="mx-1 fa fa-external-link"></i>
                            </a>{" "}
                          </span>
                        </li>
                      )}

                    <li>
                      {activeCookiebar !== 0 ? (
                        <>
                          <Button
                            className="mx-2 btn btn-link p-0 bg-transparent text-light"
                            style={{ fontSize: "18px", textDecoration: "none" }}
                            onClick={modifyCookie}
                          >
                            Cookie
                          </Button>
                        </>
                      ) : (
                        <div></div>
                      )}
                      {/* <a href='#'>{Globals.language.footer.cookie[lang]}</a> */}
                    </li>

                    {/* <li className="mb-1">
                      <span className="ms-2">Cookie</span>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="text-center p-3">
            Lepanto 2 Srl - Società a responsabilità limitata | Registro Imprese
            Verona REA -184172 | Partita Iva 01560690230 Sede legale: Lepanto 2
            Srl - Via Enrico Fermi, 31 – 37135 Verona (VR) - Italia | Tel.: 045
            513 777 | PEC: lepanto2.srl@pec.it Capitale deliberato € 100.000,00
            - Capitale versato € 100.000,00 <br></br> © 2023 Copyright Lepanto2
            srl
            {/* <a className="text-white" href="https://mdbootstrap.com/">
              MDBootstrap.com
            </a> */}
          </div>
        </footer>
      </div>
    </div>
  );
}

export default Footer;
