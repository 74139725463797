import "../scss/error404.scss";
// import { Helmet } from "react-helmet";
import AuthContext from "../context/AuthProvider";
import React, {useContext} from "react";
import Globals from "../parameters/globals";
import SEO from "./Seo";

const Error = () => {
	const {lang} = useContext(AuthContext);

	return (
		<div className='error-404'>
			<SEO
				title={" Pagina non trovata "}
				description={
					" La pagina che stai cercando non è presente oppure è stata rimossa. Contitnua a navigare sul sito di Lepanto 2. "
				}
				keyword={" 404 "}
				noIndex={true}

			/>
			<div>
				<div
					style={{
						backgroundColor: "transparent",
						width: "100%",
						height: "100px",
					}}></div>
				<div
					className='row w-100'
					style={{marginTop: "200px", marginBottom: "150px"}}>
					<div className='col-md-12'>
						<div className='error-template'>
							<h1>Oops!</h1>
							<h2>404 Not Found</h2>
							<div className='error-details'>
								{Globals.language.errorLabel.errorPagelabel[lang]}
							</div>
							<div className='error-actions'>
								<a
									href='/'
									className='btn text-light btn-lg'
									style={{backgroundColor: "#003187"}}>
									<span className='glyphicon glyphicon-home'></span>
									{Globals.language.errorLabel.comeBackHome[lang]}
								</a>
							</div>
						</div>
					</div>
				</div>
				<div
					style={{
						backgroundColor: "transparent",
						width: "100%",
						height: "100px",
					}}></div>
			</div>
		</div>
	);
};

export default Error;
