import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Globals from "../parameters/globals";
// import { Helmet } from "react-helmet";
import AuthContext from "../context/AuthProvider";
import SEO from "../components/Seo";
function Credits() {
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [credits, setCredits] = useState([]);
  const { lang } = useContext(AuthContext);

  useEffect(() => {
    getCredits();
  }, [lang]);

  const getCredits = async () => {
    setError(false);
    setMessage("");
    try {
      const response = await axios.get(Globals.CREDITS, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });

      if (response?.data?.error === true) {
        setError(true);
        setMessage(response?.data?.error);
      } else {
        window.scrollTo(0, 0);

        setCredits(
          lang === "it"
            ? response?.data?.credits.htmlcontents_it
            : lang === "en"
            ? response?.data?.credits.htmlcontents_en
            : response?.data?.credits.htmlcontents_si
        );
      }
    } catch (err) {
      setError(true);
      setMessage(err);
    }
  };

  return (
    <>
      {error ? (
        <section className="text-center">
          {" "}
          <h2> {message}</h2>
        </section>
      ) : (
        <div
          className="container"
          style={{
            marginTop: "140px",
            marginBottom: "100px",
            minHeight: "600px",
          }}
        >
          <SEO
            title={"  Credits | Lepanto 2 "}
            description={
              " Grafica, sviluppo web e contenuti del sito Lepanto 2 srl  "
            }
            keyword={"  credits  "}
            noIndex={true}
          />

          <div
            style={{
              margin: "0 auto",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
              alignItems: "center",
            }}
            dangerouslySetInnerHTML={{ __html: credits }}
          />
        </div>
      )}
    </>
  );
}

export default Credits;
