import React, {useContext} from "react";
import {Link} from "react-router-dom";
import {Card} from "react-bootstrap";
import AuthContext from "../context/AuthProvider";

function AccountActivated() {
	const {lang} = useContext(AuthContext);

	return (
		<div className='container py-5' style={{marginTop: "180px"}}>
			<Card>
				<div className='row text-center d-flex justify-content-center'>
					<div className='col-sm-6 col-sm-offset-3'>
						<h2 style={{color: "#0fad00"}}>Registrazione inviata</h2>
						<i
							className='fa fa-check-circle-fill'
							style={{fontSize: "50px", color: "green"}}></i>
						<p style={{fontSize: "20px", color: "#5C5C5C"}}>
							La sua richiesta è stata inoltrata e verrà vagliata il prima possibile.
							Le verrà recapitata una email quando l'attivazione sarà completata
						</p>
						<button className='btn btn-success btn-lg'>
							<Link className='text-light' to='/home'>
								{" "}
								Clicca qui per andare alla home
							</Link>
						</button>
					</div>
				</div>
			</Card>
		</div>
	);
}

export default AccountActivated;
