import React, { useState, useEffect, useContext } from "react";
import imageBgCard from "../images/image-1.webp";
import Soccorso from "../images/soccorso.jpg";
import Meccanici from "../images/meccanici.jpg";
import Autogru from "../images/autogru.jpg";
import { motion, Variants } from "framer-motion";
import "../scss/cardhome.scss";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";

function CardHome(props) {
  // const [isOpen, setIsOpen] = useState(true);
  // function toggle() {
  //   setIsOpen((isOpen) => !isOpen);
  // }
  const { data } = props;

  const cardVariants = {
    offscreen: {
      y: 300,
    },
    onscreen: {
      y: 0,
      rotate: 0,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8,
      },
    },
  };
  const scrollToIdElement = (element) => {
    const hash = element;
    if (hash !== null) {
      if (hash.indexOf("#") === 0) {
        setTimeout(() => {
          const id = hash.replace("#", "");
          const yOffset = -150;
          const element = document.getElementById(id);
          const y =
            element?.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({ top: y, behavior: "smooth" });
        }, 0);
      } else {
        window.location.href = element;
      }
    }
  };
  return (
    <div>
      {/* <div className="text-center p-1">
              <btn className="btn btn-danger" onClick={toggle} > {isOpen ? "Prima opzione": "Seconda opzione"}</btn>
              </div> */}
      <div className="py-1">
        <div className="container-fluid ">
          {/* {isOpen ? ( */}
          <div className="row hidden-md-up">
            {data.map((item) => {
              return (
                <motion.div
                  className="col-xl-6"
                  variants={cardVariants}
                  initial="offscreen"
                  animate="onscreen"
                  // whileInView="onscreen"
                  // viewport={{ once: true, amount: 0 }}
                >
                  {/* <div className="card"> */}
                  <div className="card-block">
                    <div className="blog-card alt">
                      <div className="meta">
                        <div>
                          <img
                            className="photo"
                            width="100%"
                            height="100%"
                            src={item.src}
                            alt="Immagini servizi offerti"
                          ></img>
                        </div>

                        {/* style={{
                        backgroundImage: "url(" + item.src + ")",
                        width: "100%",
                        height: "100%",
                      }} */}
                        {/* <ul className="details">
                      <li className="author">
                        <a href="#">Title</a>
                      </li>
                      <li className="date">July. 15, 2015</li>
                      <li className="tags">
                        <ul>
                          <li>
                            <a href="#">Titolo dettaglio</a>
                          </li>
                          <li>
                            <a href="#">Desc dettaglio</a>
                          </li>
                          <li>
                            <a href="#">Info</a>
                          </li>
                        </ul>
                      </li>
                    </ul> */}
                      </div>
                      <div className="description">
                        <h1>{item.title}</h1>
                        <h2 style={{ fontSize: "15px" }}>{item.sub_title}</h2>
                        <p style={{ fontSize: "14px" }}>{item.description}</p>
                        <p className="read-more">
                          <a
                            onClick={() => scrollToIdElement(item.button_link)}
                            className="btn btn-primary"
                          >
                            {item.button_title}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                  <div></div>
                </motion.div>
              );
            })}
            {/* <motion.div
              className="col-lg-6 col-md-6 col-sm-12"
              variants={cardVariants}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0 }}
            >
              {" "}
              <div className="card-block">
                <div className="blog-card alt">
                  <div className="meta">
                    <div
                      className="photo"
                      style={{
                        backgroundImage: "url(" + Meccanici + ")",
                        width: "100%",
                        height: "100%",
                      }}
                    ></div>
                    <ul className="details">
                      <li className="author">
                        <a href="#">Title</a>
                      </li>
                      <li className="date">July. 15, 2015</li>
                      <li className="tags">
                        <ul>
                          <li>
                            <a href="#">Titolo dettaglio</a>
                          </li>
                          <li>
                            <a href="#">Desc dettaglio</a>
                          </li>
                          <li>
                            <a href="#">Info</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="description">
                    <h1>Officina e carrozzeria</h1>
                    <h2>Lorem ipsum dolor sit amet</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Ad eum dolorum architecto obcaecati enim dicta
                      praesentium, quam nobis! Neque ad aliquam facilis numquam.
                      Veritatis, sit.
                    </p>
                    <p className="read-more">
                      <a className="btn btn-primary" href="#">
                        Scopri di più
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>
            <motion.div
              className="col-lg-6 col-md-6 col-sm-12"
              variants={cardVariants}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0 }}
            >
              {" "}
              <div className="card-block">
                <div className="blog-card alt">
                  <div className="meta">
                    <div
                      className="photo"
                      style={{
                        backgroundImage: "url(" + Soccorso + ")",
                        width: "100%",
                        height: "100%",
                      }}
                    ></div>
                    <ul className="details">
                      <li className="author">
                        <a href="#">Title</a>
                      </li>
                      <li className="date">July. 15, 2015</li>
                      <li className="tags">
                        <ul>
                          <li>
                            <a href="#">Titolo dettaglio</a>
                          </li>
                          <li>
                            <a href="#">Desc dettaglio</a>
                          </li>
                          <li>
                            <a href="#">Info</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="description">
                    <h1>Soccorso H24</h1>
                    <h2>Lorem ipsum dolor sit amet</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Ad eum dolorum architecto obcaecati enim dicta
                      praesentium, quam nobis! Neque ad aliquam facilis numquam.
                      Veritatis, sit.
                    </p>
                    <p className="read-more">
                      <a className="btn btn-primary" href="#">
                        Scopri di più
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>
            <motion.div
              className="col-lg-6 col-md-6 col-sm-12"
              variants={cardVariants}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0 }}
            >
              {" "}
              <div className="card-block">
                <div className="blog-card alt">
                  <div className="meta">
                    <div
                      className="photo"
                      style={{
                        backgroundImage: "url(" + Autogru + ")",
                        width: "100%",
                        height: "100%",
                      }}
                    ></div>
                    <ul className="details">
                      <li className="author">
                        <a href="#">Title</a>
                      </li>
                      <li className="date">July. 15, 2015</li>
                      <li className="tags">
                        <ul>
                          <li>
                            <a href="#">Titolo dettaglio</a>
                          </li>
                          <li>
                            <a href="#">Desc dettaglio</a>
                          </li>
                          <li>
                            <a href="#">Info</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="description">
                    <h1>Lepanto to move</h1>
                    <h2>Lorem ipsum dolor sit amet</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Ad eum dolorum architecto obcaecati enim dicta
                      praesentium, quam nobis! Neque ad aliquam facilis numquam.
                      Veritatis, sit.
                    </p>
                    <p className="read-more">
                      <a className="btn btn-primary" href="#">
                        Scopri di più
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </motion.div> */}
          </div>
          {/* ): ( */}
          {/* )} */}
        </div>
      </div>
    </div>
  );
}

export default CardHome;
