import React, {useState, useEffect, useContext} from "react";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Globals from "../parameters/globals";
import Form from "react-bootstrap/Form";
import Loading from "../components/Loading";
import AuthContext from "../context/AuthProvider";

import Dropdown from "react-bootstrap/Dropdown";
import SEO from "./Seo";

import {
	EReCaptchaV2Size,
	EReCaptchaV2Theme,
	ReCaptchaV2,
} from "react-recaptcha-x";

function BookingService(props) {
	const {reservation, modalShow, onHide} = props;
	const {auth} = useContext(AuthContext);

	const [activeForm, setActiveForm] = useState(false);
	const [idBooking, setIdBooking] = useState(null);
	const [titleBooking, setTitleBooking] = useState(null);
	const [textBooking, setTextBooking] = useState(null);

	const [name, setname] = useState("");
	const [surname, setsurname] = useState("");
	const [email, setemail] = useState("");
	const [phone, setphone] = useState("");
	const [message, setmessage] = useState("");
	const [company, setcompany] = useState("");
	const [brand, setBrand] = useState("");
	const [model, setModel] = useState("");
	const [date, setDate] = useState("");

	const [groupSchedule, setGroupSchedule] = useState([]);
	const [schedule, setSchedule] = useState("1");

	const [privacy, setPrivacy] = useState(false);
	const [newsletter, setNewsletter] = useState(false);
	const [sms, setSms] = useState(false);

	const [token, setToken] = useState("");
	const [loading, setLoading] = useState(false);

	const [success, setSuccess] = useState(false);
	const [errMsg, setErrMsg] = useState("");

	const [listVehicles, setListVehicles] = useState([]);
	const [selectedVehicles, setSelectedVehicles] = useState("");

	const [deactiveForm, setDeactiveForm] = useState(false);

	let lang = "it";

	//form di prenotazione standard
	//tutte e 4 le scelte portano alla stessa form
	//cambiano solo le relative label

	//controllo generale di riempimento del Recaptcha
	const v2Callback = (token) => {
		if (typeof token === "string") {
			setToken(token);
		} else if (typeof token === "boolean" && !token) {
			setErrMsg(Globals.language.errorLabel.recaptchaExpired[lang]);
			setToken("");
		} else if (token instanceof Error) {
			setErrMsg(Globals.language.errorLabel.serverError[lang]);
			setToken("");
		}
	};

	//ad ogni attivazione della form devono togliersi
	//i vari messaggi di errore/sucesso
	useEffect(() => {
		setSuccess(false);
		setErrMsg("");
	}, [activeForm]);

	//nel caso l'utente sia loggato e sia un privato oppure un commerciante
	useEffect(() => {
		setListVehicles([]);

		if (
			Object.keys(auth).length !== 0 &&
			(auth?.roles !== "admin" || auth?.roles !== "superadmin")
		) {
			getMyVehicles();
		}
	}, [auth]);

	//Chiamata di controllo dei veicoli utente
	const getMyVehicles = async () => {
		try {
			setLoading(true);

			let body = {
				accessToken: auth?.accessToken,
			};

			let formdata = new FormData();
			formdata.append("body", JSON.stringify(body));

			const response = await axios.postForm(Globals.GETMYVEHICLES, formdata, {
				withCredentials: true,
			});

			if (response?.data?.error === true) {
				setErrMsg(response?.data?.message);
			} else {
				setListVehicles(response?.data?.lista);
				setname(response?.data?.info_user.nome);
				setsurname(response?.data?.info_user.cognome);
				setemail(response?.data?.info_user.email);
				setphone(response?.data?.info_user.mobile);
				setcompany(response?.data?.info_user.ragione_sociale);

				setDeactiveForm(true);
			}
			setLoading(false);
		} catch (error) {
			setErrMsg(error);
			setLoading(false);
			return false;
		}
	};

	//SELECT Call per ottenere le fascie orarie
	const getBookingSchedule = async () => {
		try {
			setLoading(true);

			const response = await axios.get(Globals.GETSCHEDULEBOOK, {
				headers: {"Content-Type": "application/json"},
				withCredentials: true,
			});

			if (response?.data?.error === true) {
				setErrMsg(response?.data?.message);
			} else {
				setGroupSchedule(response?.data?.lista);
			}
			setLoading(false);
		} catch (error) {
			setErrMsg(error);
			setLoading(false);
			return false;
		}
	};

	//ad ogni cambiamento dell'idBooking si otterrà
	//i dati al riguardo delle fasce orario
	useEffect(() => {
		getBookingSchedule();
	}, [idBooking]);

	//funzione di attivazione, memorizzazione e comparsa della form
	const activeBookingForm = (id, title, text) => {
		setTitleBooking(title);
		setIdBooking(id);
		setActiveForm(true);
		setTextBooking(text);
	};

	//metodo di chiusura form e reset variables
	const closeForm = () => {
		setActiveForm(false);
		setIdBooking(null);
		setTitleBooking("");
		setSuccess(false);
		setErrMsg("");

		onHide();
	};

	//metodo di scelta del relativo veicolo dalla dropdown
	const setCurrentVehicleChosen = (item) => {
		setSelectedVehicles(item);
		setModel(item.modello);
		setBrand(item.marca);
	};

	//metodo di invio user request al server
	const handleSubmit = async (e, id) => {
		e.preventDefault();

		let url = "";
		let body = {};

		let formdata = new FormData();

		if (token === "") {
			setErrMsg(Globals.language.errorLabel.acceptRecaptcha[lang]);
			return false;
		}

		try {
			url = Globals.YOURBOOKINGREQUEST;

			body = {
				name: name,
				surname: surname,
				email: email,
				cellulare: phone,
				ragione_sociale: company,
				marca: brand,
				modello: model,
				data_preferita: date,
				id_fascia_oraria: schedule,
				note: message,

				privacy: privacy,
				newsletter: newsletter === true ? 1 : 0,
				sms: sms === true ? 1 : 0,

				ref_id: id,
				ref_title: titleBooking,
				token: token,
			};

			formdata.append("body", JSON.stringify(body));

			const response = await axios.postForm(url, formdata, {
				headers: {"Content-Type": "application/json"},
				withCredentials: true,
			});

			if (response?.data?.error === true) {
				setErrMsg(response.data.message);

				setLoading(false);
				setSuccess(false);
			} else {
				//clear state and controlled inputs
				setLoading(false);
				setSuccess(true);

				// setname("");
				// setsurname("");
				// setemail("");
				// setBrand("");
				// setcompany("");

				// setSchedule("1");
			}
		} catch (error) {
			setLoading(false);
			setSuccess(false);
			setErrMsg(Globals.language.errorLabel.serverError[lang]);
		}
	};

	return (
		<Modal
			show={modalShow}
			onHide={() => onHide()}
			size='xl'
			aria-labelledby='example-custom-modal-styling-title'
			centered>
			<Modal.Header closeButton>
				<Modal.Title id='contained-modal-title-vcenter'>
					La tua officina prenotati
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{!activeForm ? (
					<Row xs={1} md={4} className='g-4'>
						{reservation.map((item) => (
							<Col>
								<Card className='d-flex align-items-stretch'>
									<Card.Img variant='top' src={item.src} />
									<Card.Body className='card-body'>
										<div className='card-body d-flex flex-column'>
											<Card.Title className='card-title'>
												<p dangerouslySetInnerHTML={{__html: item.titolo}} />
											</Card.Title>
											<Card.Text className='card-text mb-4'>
												<p
													dangerouslySetInnerHTML={{
														__html: item.riassunto,
													}}
												/>
											</Card.Text>
											<Button
												className='btn-datail mt-auto align-item-bottom'
												onClick={() => activeBookingForm(item.id, item.titolo, item.testo)}>
												Prenota ora
											</Button>
										</div>
									</Card.Body>
								</Card>
							</Col>
						))}
					</Row>
				) : (
					<div>
						<Button
							style={{backgroundColor: "#003187"}}
							onClick={() => setActiveForm(false)}>
							<i className='fa fa-arrow-left'></i> indietro
						</Button>
						<h1
							className='text-center'
							dangerouslySetInnerHTML={{
								__html: "Form di prenotazione : " + titleBooking,
							}}
						/>

						<p
							className='text-start'
							dangerouslySetInnerHTML={{
								__html: textBooking,
							}}
						/>

						{loading ? (
							<Loading />
						) : success ? (
							// in caso di successo =>
							<div className='p-2 text-center'>
								{" "}
								<Card style={{fontSize: "25px"}}>
									<i
										className='fa fa-check-circle'
										style={{fontSize: "50px", color: "green"}}></i>
									{Globals.language.contacts.sendSuccess[lang]}
									<div>{Globals.language.contacts.sendTextSuccess[lang]}</div>
								</Card>
							</div>
						) : (
							<Form
								onSubmit={(e) => {
									handleSubmit(e, idBooking);
								}}>
								<SEO
									title={"Prenotazioni | Lepanto 2 "}
									description={
										" Prenota sul sito, scegli l'intervento nella data e ora a te più comodi "
									}
									keyword={" gommista "}
								/>
								{listVehicles.length !== 0 && (
									<div className='text-center p-5'>
										<Dropdown>
											<Dropdown.Toggle variant='primary' id='dropdown-basic'>
												{selectedVehicles !== "" ? (
													<>
														<strong>
															SELEZIONATO : {selectedVehicles.marca} -{" "}
															{selectedVehicles.modello} - {selectedVehicles.targa}{" "}
														</strong>
													</>
												) : (
													"Seleziona veicolo"
												)}
											</Dropdown.Toggle>

											<Dropdown.Menu>
												{listVehicles.map((item) => {
													return (
														<Dropdown.Item onClick={() => setCurrentVehicleChosen(item)}>
															{item.marca} - {item.modello} - {item.targa}
														</Dropdown.Item>
													);
												})}
											</Dropdown.Menu>
										</Dropdown>
									</div>
								)}
								{!deactiveForm && (
									<>
										{" "}
										<Form.Group className='mb-3' controlId='formBasicEmail'>
											<Form.Label>{Globals.language.contacts.nameForm[lang]}</Form.Label>
											<Form.Control
												type='text'
												placeholder={Globals.language.contacts.nameFormPlaceholder[lang]}
												value={name}
												onChange={(e) => setname(e.target.value)}
												required
											/>
										</Form.Group>
										<Form.Group className='mb-3' controlId='formBasicEmail'>
											<Form.Label>
												{Globals.language.contacts.surnameForm[lang]}
											</Form.Label>
											<Form.Control
												type='text'
												placeholder={Globals.language.contacts.surnameFormPlaceholder[lang]}
												value={surname}
												onChange={(e) => setsurname(e.target.value)}
												required
											/>
										</Form.Group>
										<Form.Group className='mb-3' controlId='formBasicEmail'>
											<Form.Label>{Globals.language.contacts.emailForm[lang]}</Form.Label>
											<Form.Control
												type='email'
												placeholder={Globals.language.contacts.emailFormPlaceholder[lang]}
												value={email}
												onChange={(e) => setemail(e.target.value)}
												required
											/>
										</Form.Group>
										<Form.Group className='mb-3' controlId='formBasicEmail'>
											<Form.Label>Ragione sociale</Form.Label>
											<Form.Control
												type='text'
												placeholder={"Inserisci ragione sociale"}
												value={company}
												onChange={(e) => setcompany(e.target.value)}
											/>
										</Form.Group>
										<Form.Group className='mb-3' controlId='formBasicEmail'>
											<Form.Label>{Globals.language.contacts.phoneForm[lang]}</Form.Label>
											<Form.Control
												type='phone'
												placeholder={Globals.language.contacts.phoneFormPlaceholder[lang]}
												value={phone}
												onChange={(e) => setphone(e.target.value)}
												required
											/>
										</Form.Group>
									</>
								)}

								<Form.Group className='mb-3' controlId='formBasicEmail'>
									<Form.Label>Marca auto</Form.Label>
									<Form.Control
										type='text'
										placeholder={"Inserisci marca auto"}
										value={brand}
										onChange={(e) => setBrand(e.target.value)}
										required
									/>
								</Form.Group>

								<Form.Group className='mb-3' controlId='formBasicEmail'>
									<Form.Label>Modello auto</Form.Label>
									<Form.Control
										type='text'
										placeholder={"Inserisci modello auto"}
										value={model}
										onChange={(e) => setModel(e.target.value)}
										required
									/>
								</Form.Group>

								<Form.Group className='mb-3'>
									<Form.Label>Data preferita </Form.Label>
									<Form.Control
										type='date'
										value={date}
										onChange={(e) => setDate(e.target.value)}
										required
									/>
								</Form.Group>

								<Form.Group className='mb-3'>
									<Form.Label>Fascia oraria </Form.Label>
									<Form.Select onChange={(e) => setSchedule(e.target.value)}>
										{groupSchedule.map((item) => {
											return <option value={item.id}>{item.value}</option>;
										})}
									</Form.Select>
								</Form.Group>

								<Form.Group className='mb-3' controlId='formBasicPassword'>
									<Form.Label>Note </Form.Label>
									<Form.Control
										type='text'
										placeholder={"Inserisci note"}
										as='textarea'
										value={message}
										onChange={(e) => setmessage(e.target.value)}
									/>
								</Form.Group>

								<Form.Group className='mb-3' controlId='formBasicCheckbox'>
									<Form.Check
										type='checkbox'
										label={Globals.language.contacts.privacyForm[lang]}
										onChange={(e) =>
											e.target.value === "on" ? setPrivacy(true) : setPrivacy(false)
										}
										required
									/>
								</Form.Group>
								<Form.Group className='mb-3' controlId='formBasicCheckbox'>
									<Form.Check
										type='checkbox'
										label={Globals.language.contacts.newsletterForm[lang]}
										onChange={(e) =>
											e.target.value === "on" ? setNewsletter(true) : setNewsletter(false)
										}
									/>
								</Form.Group>
								<Form.Group className='mb-3' controlId='formBasicCheckbox'>
									<Form.Check
										type='checkbox'
										label={"desidero iscrivermi al servizio sms"}
										onChange={(e) =>
											e.target.value === "on" ? setSms(true) : setSms(false)
										}
									/>
								</Form.Group>

								<Form.Group className='position-relative' controlId='formBasicCheckbox'>
									<ReCaptchaV2
										callback={v2Callback}
										theme={EReCaptchaV2Theme.Light}
										size={EReCaptchaV2Size.Normal}
										id='my-id'
										data-test-id='my-test-id'
										tabindex={0}
									/>
								</Form.Group>

								<Button
									className='d-flex justify-content-center m-auto'
									style={{
										backgroundColor: "#003187",
										border: "1px solid #003187",
									}}
									type='submit'>
									{Globals.language.contacts.sendButtonForm[lang]}
								</Button>
								{errMsg && <p> {errMsg} </p>}
							</Form>
						)}
					</div>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={() => closeForm()}> Chiudi</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default BookingService;
