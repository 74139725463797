import { toUnitless } from "@mui/material/styles/cssUtils";
import React, {useRef, useLayoutEffect} from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import "../scss/officina.scss";
import Card from "react-bootstrap/Card";
import { Button } from "react-bootstrap";
import GeneralModal from "./GeneralModal";

// const getItems = () =>
//   Array(20)
//     .fill(0)
//     .map((_, ind) => ({ id: `element-${ind}` }));

function Officina(props) {
  var { data } = props;

  //   const [items, setItems] = React.useState(getItems);
  const [selected, setSelected] = React.useState([]);
  //   const [position, setPosition] = React.useState(0);

  const isItemSelected = (id) => !!selected.find((el) => el === id);

  const handleClick =
    (id) =>
    ({ getItemById, scrollToItem }) => {
      const itemSelected = isItemSelected(id);

      setSelected((currentSelected) =>
        itemSelected
          ? currentSelected.filter((el) => el !== id)
          : currentSelected.concat(id)
      );
    };

  return (
    <div style={{ backgroundColor: "#f4f4f4" }} id="officina">
      <div className="py-3 container-fluid">
        <div>
          <h2 className="divider donotcross" contenteditable>
            Officina e carrozzeria
          </h2>
        </div>
        <ScrollMenu
        //   wrapperClassName="cards"
          LeftArrow={LeftArrow}
          RightArrow={RightArrow}
          itemClassName="card"
        >
          {data.map(
            ({
              id,
              titolo,
              src,
              testo,
              riassunto,
              meta_description,
              meta_keywords,
              id_form_contatto,
              meta_title,
            }) => (
              <CardModify
                itemId={id} // NOTE: itemId is required for track items
                title={titolo}
                src={src}
                testo={testo}
                riassunto={riassunto}
                key={id}
                id_form_contatto={id_form_contatto}
                meta_title={meta_title}
                meta_keywords={meta_keywords}
                meta_description={meta_description}
                onClick={handleClick(id)}
                selected={isItemSelected(id)}
              />
            )
          )}
        </ScrollMenu>
      </div>
    </div>
  );
}

// function LeftArrow() {
//   const { isFirstItemVisible, scrollPrev } =
//     React.useContext(VisibilityContext);

//   return (
//     <button
//       className="btn btn-light text-light"
//       style={{ backgroundColor: "#003187", opacity: 1, height: "50px" }}
//       disabled={isFirstItemVisible}
//       onClick={() => scrollPrev()}
//     >
//       <i className="fa fa-arrow-left"></i>
//     </button>
//   );
// }

export function LeftArrow() {
  const {
    getPrevElement,
    isFirstItemVisible,
    scrollToItem,
    visibleElements,
    initComplete,
  } = React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );
  React.useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleElements.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleElements]);

  // NOTE: for scroll 1 item
  const clickHandler = () => scrollToItem(getPrevElement(), "smooth", "start");
  return (
    <button
      className="btn btn-light text-light"
      // style={{ backgroundColor: "#003187", opacity: 1, height: "50px" }}
      style={
        disabled
          ? { opacity: 0, }
          : { backgroundColor: "#003187", opacity: 1, height: "50px" }
      }
      // disabled={isFirstItemVisible}
      disabled={disabled}
      onClick={clickHandler}
    >
      <i className="fa fa-arrow-left"></i>
    </button>

    // <button disabled={disabled} onClick={clickHandler}>
    //   Left
    // </button>
  );
}

// function RightArrow() {
//   const { isLastItemVisible, scrollNext,visibleElements } = React.useContext(VisibilityContext);

//   return (
//     <button
//       className="btn text-light"
//       style={{ backgroundColor: "#003187", opacity: 1, height: "50px" }}
//       disabled={isLastItemVisible}
//       onClick={() => scrollNext()}
//     >
//       <i className="fa fa-arrow-right"></i>
//     </button>
//   );
// }

export function RightArrow() {
  const { getNextElement, isLastItemVisible, scrollToItem, visibleElements } =
    React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !visibleElements.length && isLastItemVisible
  );
  React.useEffect(() => {
    if (visibleElements.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleElements]);

  const clickHandler = () => scrollToItem(getNextElement(), "smooth", "end");
  return (
    <button
      className="btn text-light"
      // style={{ backgroundColor: "#003187", opacity: 1, height: "50px" }}
      style={
        disabled
          ? { opacity: 0, }
          : { backgroundColor: "#003187", opacity: 1, height: "50px" }
      }
      disabled={disabled}
      onClick={clickHandler}
    >
      <i className="fa fa-arrow-right"></i>
    </button>
    // <button disabled={disabled} onClick={clickHandler}>
    //   Right
    // </button>
  );
}

function CardModify({
  onClick,
  selected,
  title,
  itemId,
  src,
  testo,
  riassunto,
  id_form_contatto,
  meta_description,
  meta_keywords,
  meta_title,
  id,
}) {
  const visibility = React.useContext(VisibilityContext);

  const [modalShow, setModalShow] = React.useState(false);
  const [titleModal, setTitleModal] = React.useState("");
  const [textModal, setTextModal] = React.useState("");
  const [idFormModal, setIdFormModal] = React.useState("");
  const [idArticle, setIdArticle] = React.useState(null);
  const [metatag, setMetatag] = React.useState({
    title: "",
    description: "",
    keyword: "",
  });

  const setModalData = (
    title,
    text,
    id_form,
    id_article,
    metaTitle,
    metaDescription,
    metaKeywords
  ) => {
    setModalShow(true);
    setTitleModal(title);
    setTextModal(text);
    setIdFormModal(id_form);
    setIdArticle(id_article);

    setMetatag({
      title: metaTitle,
      description: metaDescription,
      keyword: metaKeywords,
    });
  };
  const containerRef = useRef();

  useLayoutEffect(()=>{
    if (containerRef.current){
      containerRef.current.firstElementChild.classList.add('card-description-all');
    }
  });

  return (
    <div>
      <div onClick={() => onClick(visibility)} tabIndex={0}>
        <li>
          <Card className="d-flex align-items-stretch ">
            <Card.Img variant="top" src={src} alt="Servizi officina e carrozeria"/>
            <Card.Body  className="p-0">
              <div className="card-body d-flex flex-column">
                <Card.Title className="card-title">
                  <p dangerouslySetInnerHTML={{ __html: title }} />
                </Card.Title>
                <Card.Text className="card-text mb-4">
                  <p
                  ref={containerRef}
                    dangerouslySetInnerHTML={{
                      __html: riassunto,
                    }}
                  />
                </Card.Text>
                
              </div>
            </Card.Body>
            <div className="card-car__footer  mt-auto align-item-bottom">
            <Button
                  className="btn-datail w-100"
                  onClick={() =>
                    setModalData(
                      title,
                      testo,
                      id_form_contatto,
                      itemId,
                      meta_title,
                      meta_description,
                      meta_keywords
                    )
                  }
                >
                  Scopri di più
                </Button>
                </div>
          </Card>
        </li>
        <div />
        <GeneralModal
          show={modalShow}
          type={"officina_carrozzeria"}
          id_article={idArticle}
          onHide={() => setModalShow(false)}
          title={titleModal}
          text={textModal}
          id_form={idFormModal}
          metaKeyword={metatag}
        />
      </div>
    </div>
  );
}

export default Officina;
