import React, {useState, useEffect, useContext} from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../scss/chisiamo.scss";
import Soccorso1 from "../images/lepanto.jpg";
import Soccorso2 from "../images/soccorso2.jpg";

import SEO from "./Seo";

function ChiSiamo(props) {
	const {data} = props;
	const [modalShow, setModalShow] = React.useState(false);
	return (
		<div className='container-fluid' id='chisiamo'>
			<div className='py-3'>
				<h2 className='divider donotcross' contenteditable>
					Chi siamo
				</h2>
				<div className='blog-card-how'>
					<div className='meta-how'>
						<div
							className='photo-how'
							style={{
								backgroundImage: "url(" + Soccorso1 + ")",
								width: "100%",
								height: "49%",
							}}></div>
							<div
							className='photo-how-bottom'
							style={{
								backgroundImage: "url(" + Soccorso2 + ")",
								width: "100%",
								height: "49%",
							}}></div>
						{/* <ul className='details-how'>
							<li className='author-how'>
								<a href='#'>John Doe</a>
							</li>
							<li className='date-how'>Aug. 24, 2015</li>
							<li className='tags-how'>
								<ul>
									<li>
										<a href='#'>Learn</a>
									</li>
									<li>
										<a href='#'>Code</a>
									</li>
									<li>
										<a href='#'>HTML</a>
									</li>
									<li>
										<a href='#'>CSS</a>
									</li>
								</ul>
							</li>
						</ul> */}
					</div>
					<div className='description-how'>
						<div dangerouslySetInnerHTML={{__html: data.slice(0, 2200) + "...."}} />
						<p className='read-more-how'>
							<Button className='btn-datail' onClick={() => setModalShow(true)}>
								Scopri di più
							</Button>
						</p>
					</div>
				</div>
			</div>

			<Modal
				show={modalShow}
				onHide={() => setModalShow(false)}
				size='lg'
				aria-labelledby='contained-modal-title-vcenter'
				centered>
				<SEO
					title={" Chi siamo | Lepanto 2 "}
					description={
						" Lo staff di Lepanto offre servizi di vendita auto, officina meccanica, soccorso stradale 24h "
					}
					keyword={" la storia di Lepanto "}
				/>
				<Modal.Header closeButton>
					<Modal.Title id='contained-modal-title-vcenter'>
						Storia completa
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div dangerouslySetInnerHTML={{__html: data}} />
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={() => setModalShow(false)}>Chiudi</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}
export default ChiSiamo;
