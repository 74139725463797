import React, {useEffect, useState, useContext} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {Link, NavLink, useNavigate} from "react-router-dom";
import "../scss/navbar.scss";
import logo from "../images/logo.png";
import "../../node_modules/font-awesome/css/font-awesome.min.css";
import {HashLink} from "react-router-hash-link";
import axios from "axios";
import Globals from "../parameters/globals";
import Loading from "../components/Loading";
import BookingService from "./BookingService";
import AuthContext from "../context/AuthProvider";
import {toast} from "react-hot-toast";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import useWindowSize from "use-window-size-v2";
import Banner from "./Banner";
import DropdownButton from "react-bootstrap/DropdownButton";

// import { useLocation } from "react-router-dom";

function Navbar() {
	const [click, setClick] = useState(false);
	const handleClick = () => setClick(!click);
	const Close = () => setClick(false);
	const [stickyClass, setStickyClass] = useState("");
	const [isActive, setActive] = useState();
	const {width, height} = useWindowSize(100); // wait 100ms for the resize events
	const navigate = useNavigate();

	const {auth, setAuth, lang} = useContext(AuthContext);

	const [modalShow, setModalShow] = useState(false);
	const [reservation, setReservation] = useState([]);
	const [loading, setLoading] = useState(true);
	const [show, setShow] = useState(false);
	const showDropdown = (e) => {
	  setShow(!show);
	};
	const hideDropdown = (e) => {
	  setShow(false);
	};
	const getReservation = async () => {
		setLoading(true);
		try {
			const response = await axios.get(Globals.GETRESERVATION, {
				headers: {"Content-Type": "application/json"},
				withCredentials: true,
			});

			if (response?.data?.error === true) {
				console.log("err");
				setLoading(true);
			} else {
				let data = response?.data;
				setReservation(data.reservation || []);
				setLoading(false);
			}
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		getReservation();
	}, []);

	if (width > 1380 && click === true) {
		setClick(false);
	}

	if (click === true) {
		document.body.classList.add("bg-fixed");
	} else {
		document.body.classList.remove("bg-fixed");
	}

	const logout = async () => {
		try {
			let body = {
				accessToken: auth?.accessToken,
			};
			let formdata = new FormData();
			formdata.append("body", JSON.stringify(body));

			const response = await axios.post(Globals.LOGOUT, formdata, {
				withCredentials: true,
			});

			if (response?.data?.error === true) {
				window.alert(Globals.language.errorLabel.logoutError[lang]);
			} else {
				toast.success(Globals.language.toastMessage.logout[lang]);
				setAuth({});
				localStorage.setItem("accessToken", "");
				navigate("/home");
			}
		} catch (err) {
			setAuth({});
			localStorage.setItem("accessToken", "");
			navigate("/home");
		}
		setModalShow(false);
	};
	useEffect(() => {
		window.addEventListener("resize", detectSize);
	}, []);

	const [windowDimenion, detectHW] = useState({
		winWidth: window.innerWidth,
		winHeight: window.innerHeight,
	});

  if (width > 992) {
    document
      .querySelectorAll(".navbar .nav-item")
      .forEach(function (everyitem) {
        everyitem.addEventListener("mouseover", function (e) {
          let el_link = this.querySelector("a[data-bs-toggle]");

          if (el_link != null) {
            let nextEl = el_link.nextElementSibling;
            el_link.classList.add("show");
            nextEl.classList.add("show");
          }
        });
        everyitem.addEventListener("mouseleave", function (e) {
          let el_link = this.querySelector("a[data-bs-toggle]");

          if (el_link != null) {
            let nextEl = el_link.nextElementSibling;
            el_link.classList.remove("show");
            nextEl.classList.remove("show");
          }
        });
      });
  }

  const scrollWithOffset = (el, offset) => {
    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
      behavior: "smooth",
    });
  };

	const detectSize = () => {
		detectHW({
			winWidth: window.innerWidth,
			winHeight: window.innerHeight,
		});
	};
	useEffect(() => {
		window.addEventListener("resize", detectSize);

		return () => {
			window.removeEventListener("resize", detectSize);
		};
	}, [windowDimenion]);
	useEffect(() => {
		window.addEventListener("scroll", stickNavbar);
		return () => window.removeEventListener("scroll", stickNavbar);
	}, []);
	const stickNavbar = () => {
		if (window !== undefined) {
			let windowHeight = window.scrollY;

			windowHeight > 100 ? setStickyClass("sticky-nav") : setStickyClass("");
		}
	};
	var prevScrollpos = window.pageYOffset;
	window.onscroll = function () {
		var currentScrollPos = window.pageYOffset;
		if (width > 600) {
			if (prevScrollpos > currentScrollPos) {
				document.getElementById("navbar-top").style.top = "0";
			} else {
				document.getElementById("navbar-top").style.top = "-100%";
			}
		}

		prevScrollpos = currentScrollPos;
	};
	return (
		<div className='App' id='homepage'>
			<div className={click ? "main-container" : ""} onClick={() => Close()} />
			<nav
				className={`navbar ${stickyClass}`}
				id='navbar-top'
				style={{background: click ? "#003187" : ""}}
				onClick={(e) => e.stopPropagation()}>
				<div className='nav-container '>
					<HashLink to='/#homepage' className='nav-logo '>
						<img className='logo-navbar ' src={logo} alt="Immagine logo Lepanto"></img>
					</HashLink>
					<ul className={click ? "nav-menu active" : "nav-menu"}>
						{/* onClick={() => click ? handleClick : window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) ? "" : null  } */}
						{/* 
            <li className="nav-item">
              <HashLink
                to="/#homepage"
                activeclassname="active"
                className="nav-links"
                scroll={(el) => scrollWithOffset(el, 134)}
                onClick={click ? handleClick : null}
                smooth
              >
                Homepage
              </HashLink>
            </li> */}
            <li className="nav-item">
              <HashLink
                to={"/vendita"}
                activeclassname="active"
                className="nav-links"
                scroll={(el) => scrollWithOffset(el, 134)}
                onClick={click ? handleClick : null}
                smooth
              >
                Vendita auto{" "}
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                to={"/#officina"}
                activeclassname="active"
                className="nav-links"
                scroll={(el) => scrollWithOffset(el, 134)}
                onClick={click ? handleClick : null}
                smooth
              >
                Officina e carrozzeria{" "}
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                to={"/#soccorso"}
                activeclassname="active"
                className="nav-links"
                scroll={(el) => scrollWithOffset(el, 134)}
                onClick={click ? handleClick : null}
                smooth
              >
                Soccorso H24
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                to={"/#noleggio"}
                activeclassname="active"
                className="nav-links"
                scroll={(el) => scrollWithOffset(el, 134)}
                onClick={click ? handleClick : null}
                smooth
              >
                Lepanto to move & Noleggio{" "}
              </HashLink>
            </li>
            <li
              className="nav-item dropdown"
              onMouseEnter={width > 600 ? showDropdown : undefined}
              onMouseLeave={width > 600 ? hideDropdown : undefined}
            >
              <Dropdown show={width > 600 ? show : undefined}>
                <Dropdown.Toggle
                  variant="none"
                  className="nav-links"
                  drop={"end"}
                  id={"dropdown-button-drop-end"}
                >
                  Info & contatti
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item className="my-2">
                    <li className="nav-item-custom">
                      <HashLink
                        to={"/#chisiamo"}
                        activeclassname="active"
                        className="nav-links-custom"
                        scroll={(el) => scrollWithOffset(el, 134)}
                        onClick={click ? handleClick : null}
                        smooth
                      >
                        Chi siamo{" "}
                      </HashLink>
                    </li>
                  </Dropdown.Item>
                  <Dropdown.Item className="my-2">
                    <li className="nav-item-custom">
                      <HashLink
                        to={"/#contact"}
                        activeclassname="active"
                        className="nav-links-custom"
                        scroll={(el) => scrollWithOffset(el, 134)}
                        onClick={click ? handleClick : null}
                        smooth
                      >
                        Contattaci{" "}
                      </HashLink>
                    </li>
                  </Dropdown.Item>
                  <Dropdown.Item className="my-2">
                    <li className="nav-item-custom">
                      <HashLink
                        to={"/#lavoraconnoi"}
                        activeclassname="active"
                        className="nav-links-custom"
                        scroll={(el) => scrollWithOffset(el, 134)}
                        onClick={click ? handleClick : null}
                        smooth
                      >
                        Lavora con noi{" "}
                      </HashLink>
                    </li>
                  </Dropdown.Item>
                  <Dropdown.Item className="my-2">
                    <li className="nav-item-custom">
                      <HashLink
                        to={"/#opinione"}
                        activeclassname="active"
                        className="nav-links-custom"
                        scroll={(el) => scrollWithOffset(el, 134)}
                        onClick={click ? handleClick : null}
                        smooth
                      >
                        La tua opinione{" "}
                      </HashLink>
                    </li>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              {/* <a className="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown">  Hover me  </a>
		    <ul className="dropdown-menu ">
			  <li><a className="dropdown-item" href="#"> Submenu item 1</a></li>
			  <li><a className="dropdown-item" href="#"> Submenu item 2 </a></li>
			  <li><a className="dropdown-item" href="#"> Submenu item 3 </a></li>
		    </ul> */}
            </li>
            {/* <li className="nav-item">
              <HashLink
                to={"/#chisiamo"}
                activeclassname="active"
                className="nav-links"
                scroll={(el) => scrollWithOffset(el, 134)}
                onClick={click ? handleClick : null}
                smooth
              >
                Chi siamo{" "}
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                to={"/#contact"}
                activeclassname="active"
                className="nav-links"
                scroll={(el) => scrollWithOffset(el, 134)}
                onClick={click ? handleClick : null}
                smooth
              >
                Contattaci{" "}
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                to={"/#lavoraconnoi"}
                activeclassname="active"
                className="nav-links"
                scroll={(el) => scrollWithOffset(el, 134)}
                onClick={click ? handleClick : null}
                smooth
              >
                Lavora con noi{" "}
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                to={"/#opinione"}
                activeclassname="active"
                className="nav-links"
                scroll={(el) => scrollWithOffset(el, 134)}
                onClick={click ? handleClick : null}
                smooth
              >
                La tua opinione{" "}
              </HashLink>
            </li> */}

						<div className='d-flex justify-content-center align-items-center custom-navbar-link'>
							<li className='nav-item-custom'>
								<div className='row justify-content-center custom-navbar-link'>
									<div className='col-md-5 col-sm-12'>
										<button
											onClick={() => window.open("tel:+39045500852")}
											className=' btn btn-danger mx-2 p-0 my-1 responsive-btn-navbar'
											style={{width: "100%"}}>
											<h3 style={{fontWeight: "600"}}>SOS</h3>{" "}
											<span style={{fontWeight: 300, fontSize: "14px"}}>
												{" "}
												Soccorso stradale
											</span>
											<p>045 500 852</p>
										</button>
									</div>

									<div className='col-md-5 col-sm-12 mx-1'>
										{Object.keys(auth).length !== 0 ? (
											auth.roles === "admin" || auth.roles === "superadmin" ? (
												<>
													{/* <btn
                            onClick={() => navigate("admin-area")}
                            className=" btn btn-outline-light  responsive-btn-navbar"
                            style={{
                              width: "100%",
                              fontWeight: 300,
                              fontSize: "14px",
                            }}
                          >
                            <i className="fa fa-external-link mx-2"></i>
                            Area Admin{" "}
                          </btn>
                          <btn
                            onClick={() => logout()}
                            className=" btn btn-outline-danger  responsive-btn-navbar"
                            style={{
                              width: "100%",
                              fontWeight: 300,
                              fontSize: "14px",
                            }}
                          >
                            <i className="fa fa-external-link mx-2"></i>
                            Logout{" "}
                          </btn> */}
                          <Dropdown as={ButtonGroup} style={{ width: "100%" }}>
                            <button
                              onClick={() => navigate("admin-area")}
                              className=" btn btn-outline-light responsive-btn-navbar-my"
                              style={{
                                width: "100%",
                                fontWeight: 300,
                                fontSize:"15px"
                              }}
                            >
                              <i className="fa fa-external-link mx-2"></i>
                              My Lepanto{" "}
                            </button>

														<Dropdown.Toggle
															split
															className='btn btn-outline-light bg-transparent responsive-btn-navbar-my-logout text-light'
															id='dropdown-split-basic'
														/>
														<Dropdown.Menu>
															<Dropdown.Item onClick={() => logout()}>
																<i className='fa fa-sign-out mx-1'></i>Logout{" "}
															</Dropdown.Item>
														</Dropdown.Menu>
													</Dropdown>
												</>
											) : (
												<>
													<Dropdown as={ButtonGroup}>
														<button
															onClick={() => navigate("my-lepanto")}
															className=' btn btn-outline-light responsive-btn-navbar-my'
															style={{
																width: "100%",
																fontWeight: 300,
															}}>
															<i className='fa fa-external-link mx-2'></i>
															My Lepanto{" "}
														</button>

														<Dropdown.Toggle
															split
															className='btn btn-outline-light bg-transparent responsive-btn-navbar-my-logout text-light'
															id='dropdown-split-basic'
														/>
														<Dropdown.Menu>
															<Dropdown.Item onClick={() => logout()}>
																<i className='fa fa-sign-out mx-1'></i>Logout{" "}
															</Dropdown.Item>
														</Dropdown.Menu>
													</Dropdown>

													{/* <btn
                            onClick={() => navigate("my-lepanto")}
                            className=" btn btn-outline-light responsive-btn-navbar"
                            style={{
                              width: "100%",
                              fontWeight: 300,
                              fontSize: "14px",
                            }}
                          >
                            <i className="fa fa-external-link mx-2"></i>
                            My Lepanto{" "}
                          </btn>
                          <btn
                            onClick={() => logout()}
                            className=" btn btn-outline-light  responsive-btn-navbar"
                            style={{
                              width: "100%",
                              fontWeight: 300,
                              fontSize: "14px",
                            }}
                          >
                            <i className="fa fa-external-link mx-2"></i>
                            Logout{" "}
                          </btn> */}
												</>
											)
										) : (
											<>
												<button
													onClick={() => navigate("my-lepanto")}
													className=' btn btn-outline-light my-1 responsive-btn-navbar'
													style={{
														width: "100%",
														fontWeight: 300,
														fontSize: "14px",
													}}>
													<i className='fa fa-external-link mx-2'></i>
													My lepanto{" "}
												</button>
											</>
										)}
										<button
											className=' btn btn-outline-light  mt-2 responsive-btn-navbar'
											style={{
												width: "100%",
												fontWeight: 300,
												fontSize: "15px",
											}}
											onClick={() => setModalShow(true)}>
											La tua officina prenotati
										</button>{" "}
									</div>
									<div className='col-md-1 col-sm-12 my-auto'>
										<div className='column '>
											<i
												onClick={() => window.open("https://www.facebook.com/lepanto2")}
												style={{cursor: "pointer"}}
												className='fa fa-facebook text-light responsive-icons-navbar'></i>
											<i
												onClick={() =>
													window.open(
														"https://www.youtube.com/channel/UCnv4xYNsTHoX4Y47lZxWpvA",
													)
												}
												style={{cursor: "pointer"}}
												className='fa fa-youtube text-light responsive-icons-navbar'></i>
											<i
												onClick={() =>
													window.open("https://www.linkedin.com/company/lepanto-2-srl/")
												}
												style={{cursor: "pointer"}}
												className='fa fa-linkedin text-light responsive-icons-navbar'></i>
											<i
												onClick={() =>
													window.open("https://www.instagram.com/lepanto_2srl/")
												}
												style={{cursor: "pointer"}}
												className='fa fa-instagram text-light responsive-icons-navbar'></i>
										</div>
									</div>
								</div>
							</li>
						</div>
					</ul>

					<div className='nav-icon' onClick={handleClick}>
						<i className={click ? "fa fa-close " : "fa fa-bars"}></i>
					</div>
				</div>
			</nav>
			<div>
				{loading ? (
					<Loading />
				) : (
					<BookingService
						modalShow={modalShow}
						reservation={reservation}
						onHide={() => setModalShow(false)}
					/>
				)}
			</div>
		</div>
	);
	// }
}

export default Navbar;
