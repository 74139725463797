import axios from "axios";
import React, { useEffect, useState, useCallback, useContext } from "react";
import Globals from "../parameters/globals";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import "../scss/carsale.scss";
import Modal from "react-bootstrap/Modal";
import useWindowSize from "use-window-size-v2";
import ReactPaginate from "react-paginate";
import AuthContext from "../context/AuthProvider";
// import Pagination from "@mui/material/Pagination";
// import PaginationItem from "@mui/material/PaginationItem";
// import Stack from "@mui/material/Stack";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  MDBAccordion,
  MDBAccordionItem,
  MDBRadio,
  MDBRange,
} from "mdb-react-ui-kit";

import Card from "react-bootstrap/Card";
import { Button, ModalHeader } from "react-bootstrap";
import SEO from "../components/Seo";

function CarSale() {
  const [modalShow, setModalShow] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const { width, height } = useWindowSize(100); // wait 100ms for the resize events
  const [cars, setCars] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(3);

  const numberFormat = (value) =>
  new Intl.NumberFormat('it-IT', {
    // style: 'currency',
    currency: 'EUR'
  }).format(value);

  let PageSize = 5;
  const formatYear = (item) => {
    let d = new Date(item);
    let year = d.getFullYear();
    return year;
  };
  const formatDay = (item) => {
    let d = new Date(item);
    let day = "" + d.getDate();
    if (day.length < 2) {
      day = "0" + day;
    }

    return day;
  };
  const formatMonth = (item) => {
    let d = new Date(item);
    let month = d.getMonth() < 12 ? "" + (d.getMonth() + 1) : 1;
    if (month.length < 2) month = "0" + month;
    return month;
  };

  //FILTRI
  const [searchword, SetSearchWord] = useState(""); //search all by word

  const [listBrand, SetListBrand] = useState([]); //brand list checkable
  const [selectedBrand, setSelectBrand] = useState(""); //brand select from list

  const [listModel, SetListModel] = useState([]); //model list checkable
  const [selectedModel, setSelectedModel] = useState(""); //selected model from checkbox

  const [listFuel, SetListFuel] = useState([]); // list fuel checkable
  const [selectedFuel, setSelectedFuel] = useState(""); //selected model from checkbox

  const [listBody, setListBody] = useState([]); // list fuel checkable
  const [selectedBody, setSelectedBody] = useState(""); //selected model from checkbox

  const [listTransmission, setListTransmission] = useState([]); // list fuel checkable
  const [selectedTransmission, setSelectedTransmission] = useState(""); //selected model from checkbox

  const [maxPriceRange, setMaxPriceRange] = useState(null); //price max range
  const [minPriceRange, setMinPriceRange] = useState(null); //price min range

  const [maxKmRange, setMaxKmRange] = useState(null); //price max range
  const [minKmRange, setMinKmRange] = useState(null); //price min range

  const [minSetByUser, settedMinByUser] = useState(null);
  const [maxSetByUser, settedMaxByUser] = useState(null);

  const [minKmSetByUser, settedMinKmByUser] = useState(null);
  const [maxKmSetByUser, settedMaxKmByUser] = useState(null);

  const [listYears, SetListYears] = useState([]); // mileage list checkable
  const [selectedYear, setSelectedYears] = useState(""); //selected model from checkbox

  const [cleanButton, setCleanButton] = useState(false); // bottone per lo svuotamento dei filtri

  const getAllCars = useCallback(
    async (reset = false) => {
      try {
        let body = {};

        if (reset) {
          setSelectBrand("");
        }

        if (checkEmptyAllFilterValue()) {
          body = false;
        } else {
          body = {
            searchword: searchword,
            reset: reset,
            selectedBrand: selectedBrand,
            selectedModel: selectedModel,
            maxSetByUser: maxSetByUser,
            minSetByUser: minSetByUser,
            selectedFuel: selectedFuel,
            selectedBody: selectedBody,
            selectedTransmission: selectedTransmission,
            maxKmSetByUser: maxKmSetByUser,
            minKmSetByUser: minKmSetByUser,
            selectedYears: selectedYear,
          };
        }

        let formdata = new FormData();

        formdata.append("body", JSON.stringify(body));

        setLoading(true);
        setError(false);
        setMessage("");

        const response = await axios.postForm(Globals.GETALLCARS, formdata, {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        });

        let data = response?.data;

        if (data?.error === true) {
          setLoading(false);
          setError(true);
          setMessage(data?.message);
        } else {
          setLoading(false);
          setError(false);
          setMessage("");
          setCars(data?.veicoli);
          setCleanButton(data?.empty_button);
          SetListBrand(data?.lista_brand);
          setMaxPriceRange(data?.max_min.max);
          setMinPriceRange(data?.max_min.min);
          SetListFuel(data?.fuel_type);
          SetListModel(data?.list_model);
          setListBody(data?.list_body);
          setListTransmission(data?.list_transmission);
          setMinKmRange(data?.max_min_km.min);
          setMaxKmRange(data?.max_min_km.max);
          SetListYears(data?.list_all_years);
          setModalShow(false);

          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }
      } catch (err) {
        setLoading(false);
        setError(true);
        setMessage(err);
      }
    },
    [
      searchword,
      selectedBrand,
      selectedModel,
      maxSetByUser,
      minSetByUser,
      selectedFuel,
      selectedBody,
      selectedTransmission,
      maxKmSetByUser,
      minKmSetByUser,
      selectedYear,
    ]
  );

  const checkEmptyAllFilterValue = useCallback(() => {
    if (
      searchword !== "" ||
      selectedBrand !== "" ||
      selectedModel !== "" ||
      minSetByUser !== null ||
      maxSetByUser !== null ||
      selectedFuel !== "" ||
      selectedBody !== "" ||
      selectedTransmission !== "" ||
      minKmSetByUser !== "" ||
      maxKmSetByUser !== "" ||
      selectedYear !== ""
    ) {
      return false;
    } else {
      return true;
    }
  }, [
    searchword,
    selectedBrand,
    selectedModel,
    maxSetByUser,
    minSetByUser,
    selectedFuel,
    selectedBody,
    selectedTransmission,
    minKmSetByUser,
    maxKmSetByUser,
    selectedYear,
  ]);

  useEffect(() => {
    getAllCars();
    window.scroll(0, 0);
    if (width <= 1000) {
      setModalShow(false);
    }
  }, [
    selectedBrand,
    selectedModel,
    selectedFuel,
    selectedBody,
    selectedTransmission,
    selectedYear,
  ]);

  //metodo di svuotamento dei filtri
  const CleanAllFilter = () => {
    getAllCars(true);
    document.location.reload();
  };

  const openPrintPdfSale = (id) => {
    window.open(Globals.OPENCARPRINTSALE + id, "titlebar=no").focus();
  };

  // const indexOfLastPost = currentPage * postsPerPage;
  // const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // const currentPosts = cars.slice(indexOfFirstPost, indexOfLastPost);
  // const currentData = useMemo(() => {
  // 	const firstPageIndex = (currentPage - 1) * PageSize;
  // 	const lastPageIndex = firstPageIndex + PageSize;
  // 	return cars.slice(firstPageIndex, lastPageIndex);
  // }, [currentPage]);

  // const paginate = ({selected}) => {
  // 	setCurrentPage(selected + 1);
  // 	window.scrollTo(0, 0);
  // };

  return (
    <div className="container-fluid p-1">
      <SEO
        title={"Lepanto 2 | Vendita"}
        description={
          "Consulta il catalago auto dove potrai trovare tantissime marche e modelli "
        }
        keyword={"auto usate Verona"}
      />
      <div className="container-fluid py-3 ">
        <div className="row height d-flex justify-content-center align-items-center align-content-center">
          <div className="col-md-6 col-sm-12">
            <div className="form">
              <i className="fa fa-search"></i>
              <input
                type="text"
                value={searchword}
                className="form-control form-input"
                placeholder="Cerca tutto..."
                onChange={(e) => SetSearchWord(e.target.value)}
              />
              <span className="left-pan">
                {/* <i className="fa fa-microphone"></i> */}
                <Button variant="primary" onClick={() => getAllCars()}>
                  Cerca
                </Button>
                {width <= 1000 ? (
                  <button
                    className="btn btn-secondary mx-1"
                    onClick={() => setModalShow(true)}
                  >
                    {" "}
                    <i className="fa fa-filter"></i>
                  </button>
                ) : (
                  <></>
                )}
              </span>
              <div className="text-center my-3">
                <Button
                  variant={cleanButton ? "danger" : "secondary"}
                  className="mx-2"
                  disabled={!cleanButton}
                  onClick={() => CleanAllFilter()}
                >
                  Svuota filtri
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex my-3 only-landscape">
        {width <= 1000 ? (
          //visualizzazione per schermi piccoli
          <Modal
            show={modalShow}
            onHide={() => setModalShow(false)}
            size="xl"
            aria-labelledby="example-custom-modal-styling-title "
            centered
          >
            <ModalHeader closeButton></ModalHeader>
            <div className="landscape-mod col-md-3 col-sm-12 my-2">
              <span>
                <h2 className="text-start p-2">
                  Filtra per:{" "}
                  <button
                    className="btn btn-link float-end my-2"
                    onClick={() => CleanAllFilter()}
                  >
                    Azzera
                  </button>
                </h2>{" "}
              </span>
              {/* <Modal.Header closeButton>
                <Modal.Title>
                  <span>
                    <h2 className="text-start p-2">Filtra per: </h2>{" "}
                  </span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <btn className="btn btn-link float-end ">Azzera</btn>
              </Modal.Body> */}
              <Card className="my-4">
                <MDBAccordion flush initialActive={1}>
                  <MDBAccordionItem collapseId={1} headerTitle="Marca">
                    {listBrand?.map((item, key) => {
                      return (
                        <MDBRadio
                          name="brand"
                          value={item.brand_code}
                          id={key}
                          label={item.brand_description}
                          onChange={(e) => setSelectBrand(e.target.value)}
                        />
                      );
                    })}
                  </MDBAccordionItem>
                </MDBAccordion>
              </Card>

              <div className="px-3">
                {/* <Card className="my-4">
                  <MDBAccordion flush initialActive={1}>
                    <MDBAccordionItem collapseId={1} headerTitle="Marca">
                    
                      {listBrand?.map((item, key) => {
                        return (
                          <MDBRadio
                            name="brand"
                            value={item.brand_code}
                            id={key}
                            label={item.brand_description}
                          />
                        );
                      })}
                    </MDBAccordionItem>
                  </MDBAccordion>
                </Card> */}

                <Card className="my-4">
                  <MDBAccordion flush initialActive={1}>
                    <MDBAccordionItem collapseId={1} headerTitle="Modello">
                      {listModel?.map((item, key) => {
                        return (
                          <MDBRadio
                            name="brand"
                            value={item.model_description}
                            id={key}
                            label={item.model_description}
                            onChange={(e) => setSelectedModel(e.target.value)}
                          />
                        );
                      })}
                    </MDBAccordionItem>
                  </MDBAccordion>
                </Card>

                {minPriceRange !== null && maxPriceRange !== null && (
                  <Card className="my-4">
                    <div className="d-flex">
                      <div className="col-12 text-center">
                        <MDBAccordion flush initialActive={1}>
                          <MDBAccordionItem
                            collapseId={1}
                            headerTitle="Prezzo(€)"
                          >
                            <MDBRange
                              defaultValue={parseInt(minPriceRange)}
                              min={minPriceRange}
                              max={maxPriceRange}
                              id="customRange5"
                              step="100"
                              label="minimo: "
                              onChange={(e) => settedMinByUser(e.target.value)}
                            />
                            <MDBRange
                              defaultValue={parseInt(maxPriceRange)}
                              min={minPriceRange}
                              max={maxPriceRange}
                              step="100"
                              id="customRange5"
                              label="massimo: "
                              onChange={(e) => settedMaxByUser(e.target.value)}
                            />

                            <Button
                              variant="primary"
                              onClick={() => getAllCars()}
                            >
                              Filtra{" "}
                            </Button>
                          </MDBAccordionItem>
                        </MDBAccordion>
                      </div>
                    </div>
                  </Card>
                )}

                <Card className="my-4">
                  <MDBAccordion flush initialActive={1}>
                    <MDBAccordionItem collapseId={1} headerTitle="Carburante: ">
                      {listFuel.map((item) => {
                        return (
                          <MDBRadio
                            name="flexCheck"
                            value={item.fuel_type}
                            id="flexCheckDefault"
                            label={item.fuel_type}
                            onChange={(e) => setSelectedFuel(e.target.value)}
                          />
                        );
                      })}
                    </MDBAccordionItem>
                  </MDBAccordion>
                </Card>

                <Card className="my-4">
                  <MDBAccordion flush initialActive={1}>
                    <MDBAccordionItem
                      collapseId={1}
                      headerTitle="Carrozzeria: "
                    >
                      {listBody.map((item) => {
                        return (
                          <MDBRadio
                            name="flexCheck"
                            value={item.body}
                            id="flexCheckDefault"
                            label={item.body}
                            onChange={(e) => setSelectedBody(e.target.value)}
                          />
                        );
                      })}
                    </MDBAccordionItem>
                  </MDBAccordion>
                </Card>
                <Card className="my-4">
                  <MDBAccordion flush initialActive={1}>
                    <MDBAccordionItem collapseId={1} headerTitle="Trazione: ">
                      {listTransmission.map((item) => {
                        return (
                          <MDBRadio
                            name="flexCheck"
                            value={item.transmission}
                            id="flexCheckDefault"
                            label={item.transmission}
                            onChange={(e) =>
                              setSelectedTransmission(e.target.value)
                            }
                          />
                        );
                      })}
                    </MDBAccordionItem>
                  </MDBAccordion>
                </Card>

                <div className="d-flex my-3">
                  <div className="col-12">
                    {minKmRange !== null && maxKmRange !== null && (
                      <Card className="my-4">
                        <div className="d-flex">
                          <div className="col-12 text-center">
                            <MDBAccordion flush initialActive={1}>
                              <MDBAccordionItem collapseId={1} headerTitle="KM">
                                <MDBRange
                                  defaultValue={parseInt(minKmRange)}
                                  min={minKmRange}
                                  max={maxKmRange}
                                  id="customRange5"
                                  step="1000"
                                  label="minimo: "
                                  onChange={(e) =>
                                    settedMinKmByUser(e.target.value)
                                  }
                                />
                                <MDBRange
                                  defaultValue={parseInt(maxKmRange)}
                                  min={minKmRange}
                                  max={maxKmRange}
                                  step="1000"
                                  id="customRange5"
                                  label="massimo: "
                                  onChange={(e) =>
                                    settedMaxKmByUser(e.target.value)
                                  }
                                />

                                <Button
                                  variant="primary"
                                  onClick={() => getAllCars()}
                                >
                                  Filtra{" "}
                                </Button>
                              </MDBAccordionItem>
                            </MDBAccordion>
                          </div>
                        </div>
                      </Card>
                    )}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="col-12">
                    <Card>
                      <MDBAccordion flush initialActive={1}>
                        <MDBAccordionItem collapseId={1} headerTitle="Anno: ">
                          {listYears.map((item) => {
                            return (
                              <MDBRadio
                                name="flexCheck"
                                value={item.year}
                                id="flexCheckDefault"
                                label={item.year}
                                onChange={(e) =>
                                  setSelectedYears(e.target.value)
                                }
                              />
                            );
                          })}
                        </MDBAccordionItem>
                      </MDBAccordion>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        ) : (
          //visualizzazione desktop
          <div className="col-md-3 col-sm-12">
            <span>
              <h2 className="text-start p-2">
                Filtra per:{" "}
                <button
                  className="btn btn-link float-end my-2"
                  onClick={() => CleanAllFilter()}
                >
                  Azzera
                </button>
              </h2>{" "}
            </span>

            <div className="px-3">
              {listBrand.length > 0 && (
                <Card className="my-4">
                  <MDBAccordion flush initialActive={1}>
                    <MDBAccordionItem collapseId={1} headerTitle="Marca">
                      {listBrand?.map((item, key) => {
                        return (
                          <MDBRadio
                            name="brand"
                            value={item.brand_code}
                            id={key}
                            label={item.brand_description}
                            onChange={(e) => setSelectBrand(e.target.value)}
                          />
                        );
                      })}
                    </MDBAccordionItem>
                  </MDBAccordion>
                </Card>
              )}

              <Card className="my-4">
                <MDBAccordion flush initialActive={0}>
                  <MDBAccordionItem collapseId={1} headerTitle="Modello">
                    {listModel?.map((item, key) => {
                      return (
                        <MDBRadio
                          name="brand"
                          value={item.model_description}
                          id={key}
                          label={item.model_description}
                          onChange={(e) => setSelectedModel(e.target.value)}
                        />
                      );
                    })}
                  </MDBAccordionItem>
                </MDBAccordion>
              </Card>

              {minPriceRange !== null && maxPriceRange !== null && (
                <Card className="my-4">
                  <div className="d-flex">
                    <div className="col-12 text-center">
                      <MDBAccordion flush initialActive={0}>
                        <MDBAccordionItem
                          collapseId={1}
                          headerTitle="Prezzo(€)"
                        >
                          <MDBRange
                            defaultValue={parseInt(minPriceRange)}
                            min={minPriceRange}
                            max={maxPriceRange}
                            id="customRange5"
                            step="100"
                            label="minimo: "
                            onChange={(e) => settedMinByUser(e.target.value)}
                          />
                          <MDBRange
                            defaultValue={parseInt(maxPriceRange)}
                            min={minPriceRange}
                            max={maxPriceRange}
                            step="100"
                            id="customRange5"
                            label="massimo: "
                            onChange={(e) => settedMaxByUser(e.target.value)}
                          />

                          <Button
                            variant="primary"
                            onClick={() => getAllCars()}
                          >
                            Filtra{" "}
                          </Button>
                        </MDBAccordionItem>
                      </MDBAccordion>
                    </div>
                  </div>
                </Card>
              )}

              <Card className="my-4">
                <MDBAccordion flush initialActive={0}>
                  <MDBAccordionItem collapseId={1} headerTitle="Carburante: ">
                    {listFuel.map((item) => {
                      return (
                        <MDBRadio
                          name="flexCheck"
                          value={item.fuel_type}
                          id="flexCheckDefault"
                          label={item.fuel_type}
                          onChange={(e) => setSelectedFuel(e.target.value)}
                        />
                      );
                    })}
                  </MDBAccordionItem>
                </MDBAccordion>
              </Card>

              <Card className="my-4">
                <MDBAccordion flush initialActive={0}>
                  <MDBAccordionItem collapseId={1} headerTitle="Carrozzeria: ">
                    {listBody.map((item) => {
                      return (
                        <MDBRadio
                          name="flexCheck"
                          value={item.body}
                          id="flexCheckDefault"
                          label={item.body}
                          onChange={(e) => setSelectedBody(e.target.value)}
                        />
                      );
                    })}
                  </MDBAccordionItem>
                </MDBAccordion>
              </Card>
              <Card className="my-4">
                <MDBAccordion flush initialActive={0}>
                  <MDBAccordionItem collapseId={1} headerTitle="Trazione: ">
                    {listTransmission.map((item) => {
                      return (
                        <MDBRadio
                          name="flexCheck"
                          value={item.transmission}
                          id="flexCheckDefault"
                          label={item.transmission}
                          onChange={(e) =>
                            setSelectedTransmission(e.target.value)
                          }
                        />
                      );
                    })}
                  </MDBAccordionItem>
                </MDBAccordion>
              </Card>
              <div className="d-flex my-3">
                <div className="col-12">
                  {minKmRange !== null && maxKmRange !== null && (
                    <Card className="my-4">
                      <div className="d-flex">
                        <div className="col-12 text-center">
                          <MDBAccordion flush initialActive={0}>
                            <MDBAccordionItem collapseId={1} headerTitle="KM">
                              <MDBRange
                                defaultValue={parseInt(minKmRange)}
                                min={minKmRange}
                                max={maxKmRange}
                                id="customRange5"
                                step="1000"
                                label="minimo: "
                                onChange={(e) =>
                                  settedMinKmByUser(e.target.value)
                                }
                              />
                              <MDBRange
                                defaultValue={parseInt(maxKmRange)}
                                min={minKmRange}
                                max={maxKmRange}
                                step="1000"
                                id="customRange5"
                                label="massimo: "
                                onChange={(e) =>
                                  settedMaxKmByUser(e.target.value)
                                }
                              />

                              <Button
                                variant="primary"
                                onClick={() => getAllCars()}
                              >
                                Filtra{" "}
                              </Button>
                            </MDBAccordionItem>
                          </MDBAccordion>
                        </div>
                      </div>
                    </Card>
                  )}
                </div>
              </div>
              <div className="d-flex">
                <div className="col-12">
                  <Card>
                    <MDBAccordion flush initialActive={0}>
                      <MDBAccordionItem collapseId={1} headerTitle="Anno: ">
                        {listYears.map((item) => {
                          return (
                            <MDBRadio
                              name="flexCheck"
                              value={item.year}
                              id="flexCheckDefault"
                              label={item.year}
                              onChange={(e) => setSelectedYears(e.target.value)}
                            />
                          );
                        })}
                      </MDBAccordionItem>
                    </MDBAccordion>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        )}

        {loading ? (
          <div className="col-md-8 col-sm-12 ">
            <Loading />
          </div>
        ) : (
          <div className='col-md-8 col-sm-12'>
          	{!loading && cars.length > 0 ? (
          		cars.map((item) => {
          			return (
          				<div >
          					<div className='card-sell'>
          						<div className='col-md-6 col-sm-12'>
          							<div className='thumbnail'>
          								<img
          									draggable={false}
          									className='d-block w-100'
          									src={item.src}
          									alt='images cars detail'
          								/>
          							</div>
          						</div>
          						<div className='col-md-6 col-sm-12 px-2'>
          							<div className=''>
          								<h1 className='title-car-sale text-center'>
          									{item.title !== ""
          										? item.title
          										: item.brand_description +
          										  " - " +
          										  item.model_description +
          										  " - " +
          										  item.version_description}
          								</h1>
          								<div className='separator'></div>
          								<p className='desc-car text-center'>
          									<div className='container'>
          										<div className='row p-2'>
          											<div className='col-xl-4 col-sm-12 p-3'>
          												{" "}
          												<strong>Chilometri : </strong> {item.mileage} Km
          											</div>
          											<div className='col-xl-4 col-sm-12 p-3'>
          												<strong>Anno: </strong> {formatDay(item.initial_registration)}-
          												{formatMonth(item.initial_registration)}-
          												{formatYear(item.initial_registration)}
          											</div>
          											<div className='col-xl-4 col-sm-12 p-3'>
          												<strong>Potenza : </strong> {item.kilowatt} kW (
          												{(item.kilowatt * 1.36).toFixed(2)} Cv){" "}
          											</div>
          											<div className='w-100'></div>
          											<div className='col-12 p-3'>
          												<strong
          													className='display-6 text-danger'
          													style={{fontWeight: "600"}}>
          													 {numberFormat(item.private_price)} €
          												</strong>
          											</div>
          										</div>
          									</div>
          									<div className='row p-1'>
          										<div className='col-md-6 col-sm-12 p-1'>
          											<button
          												onClick={() =>
          													navigate(
          														"/dettaglio-auto/" +
          															item.id +
          															"/" +
          															item.brand_description +
          															"/" +
          															"test-drive",
          													)
          												}
          												className='add-to-cart-test-drive w-100'>
          												Test drive
          											</button>
          										</div>
          										<div className='col-md-6 col-sm-12 p-1'>
          											<button
          												className='add-to-cart w-100'
          												onClick={() =>
          													navigate(
          														"/dettaglio-auto/" +
          															item.id +
          															"/" +
          															item.brand_description +
          															"/" +
          															"dettaglio",
          													)
          												}>
          												Dettaglio 
          											</button>
          										</div>
          										<div className='col-12 p-2'>
          											{" "}
          											<button
          												className='btn btn-link w-100 text-decoration-none'
          												onClick={() => openPrintPdfSale(item.id)}>
          												<i className='fa fa-print mx-2'></i>
          												Stampa
          											</button>
          										</div>
          									</div>
          								</p>
          							</div>
          						</div>
          					</div>
          				</div>
          			);
          		})
          	) : (
          		<h2 className='text-center'> Nessun auto trovata</h2>
          	)}

          </div>
        )}
      </div>
    </div>
  );
}

export default CarSale;
