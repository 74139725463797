import {useRef, useState, useEffect} from "react";
import {useContext} from "react";
import axios from "axios";
import {useNavigate, useLocation} from "react-router-dom";
import Globals from "../parameters/globals";
import "../scss/login.scss";
import Form from "react-bootstrap/Form";
import {toast} from "react-hot-toast";
import "bootstrap/dist/css/bootstrap.min.css";
import {Link} from "react-router-dom";
import AuthContext from "../context/AuthProvider";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import SEO from "../components/Seo";

const Login = (props) => {
	const {auth, setAuth, lang} = useContext(AuthContext);

	const navigate = useNavigate();
	const location = useLocation();

	const from = location.state?.from?.pathname || "/";

	const OnlyForm = props.OnlyForm || false;

	const errRef = useRef();

	const [user, setUser] = useState("");
	const [pwd, setPwd] = useState("");
	const [errMsg, setErrMsg] = useState(false);

	const [passwordShown, setPasswordShown] = useState(false);
	const [pswrecovery, passwordRecoveryEmail] = useState("");
	const [success, setSuccess] = useState(false);
	const [codeOtp, setCodeOtp] = useState("");
	const [newPassword, setNewPassword] = useState("");

	const [show, setShow] = useState(false);
	const [showOtp, setShowOtp] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const handleCloseOtp = () => setShowOtp(false);
	const handleShowOtp = () => setShowOtp(true);
	const handleCloseSuccess = () => setsuccessChangePwd(false);

	const [successChangePwd, setsuccessChangePwd] = useState(false);

	const togglePassword = () => {
		setPasswordShown(!passwordShown);
	};

	useEffect(() => {
		//nel caso un'utente passi dal register ma è già loggato verrà renderizzato alla pagina
		//di competenza in base al ruolo

		if (Object.keys(auth).length !== 0) {
			if (auth?.roles === "admin" || auth?.roles === "superadmin") {
				navigate("/admin-area");
			} else {
				navigate("/my-lepanto");
			}
		}
	}, [auth]);

	useEffect(() => {
		setErrMsg("");
	}, [user, pwd]);

	const startPasswordRecovery = async (e) => {
		e.preventDefault();

		try {
			let body = {
				email: pswrecovery,
			};

			let formdata = new FormData();

			formdata.append("body", JSON.stringify(body));

			const response = await axios.post(
				Globals.POSTPASSWORD + "&lang=" + lang,
				formdata,
				{
					withCredentials: true,
				},
			);
			// TODO: remove console.logs before deployment
			// console.log(JSON.stringify(response?.data));
			//console.log(JSON.stringify(response))

			if (response?.data?.error === true) {
				setErrMsg(response.data.message);
				setShow(true);
				setShowOtp(false);
				setsuccessChangePwd(false);
			} else {
				//clear state and controlled inputs
				setErrMsg(false);

				setSuccess(true);
				setShow(false);
				setShowOtp(true);
			}
		} catch (err) {
			setSuccess(false);

			setErrMsg(Globals.language.errorLabel.serverError[lang]);
		}
	};

	const changePassword = async (e) => {
		e.preventDefault();

		try {
			let body = {
				recovery_code: codeOtp,
				newPassword: newPassword,
			};

			let formdata = new FormData();

			formdata.append("body", JSON.stringify(body));

			const response = await axios.post(
				Globals.CHANGEPASSWORD + "&lang=" + lang,
				formdata,
				{
					withCredentials: true,
				},
			);
			// TODO: remove console.logs before deployment
			// console.log(JSON.stringify(response?.data));
			//console.log(JSON.stringify(response))

			if (response?.data?.error === true) {
				setErrMsg(response.data.message);
				setSuccess(false);
			} else {
				//clear state and controlled inputs
				setSuccess(true);
				handleCloseOtp(false);
				setShowOtp(false);
				setsuccessChangePwd(true);
			}
		} catch (err) {
			setSuccess(false);
			setErrMsg(Globals.language.errorLabel.serverError[lang]);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			let body = {
				user: user,
				pass: pwd,
			};

			let formdata = new FormData();

			formdata.append("body", JSON.stringify(body));

			const response = await axios.postForm(
				Globals.LOGIN + "&lang=" + lang,
				formdata,
				{
					withCredentials: true,
				},
			);
			const accessToken = response?.data?.access_token;
			const roles = response?.data?.roles;
			if (response?.data?.error === true) {
				setErrMsg(response.data.message);
			} else {
				setUser("");
				setPwd("");
				setAuth({user, pwd, roles, accessToken});
				localStorage.setItem("accessToken", accessToken);
				if (roles === "privato" || roles === "commerciante") {
					navigate("/my-lepanto");
				} else {
					navigate("/home");
				}
				toast.success(`${Globals.language.toastMessage.welcome[lang]} ${user}.`);
			}
		} catch (err) {
			setErrMsg(Globals.language.errorLabel.serverError[lang]);
		}
	};

	return (
		<div className='d-flex justify-content-center m-5 p-5'>
			<SEO
				title={"  Login | Lepanto 2 "}
				description={"  Accedi all'area riservata  "}
				keyword={"  login  "}
				noIndex={true}

			/>{" "}
			<Form onSubmit={(e) => handleSubmit(e)}>
				<h3 className='mb-3'>Inserisci le tue credenziali di accesso </h3>
				<Form.Group>
					<Form.Label>Username</Form.Label>
					<Form.Control
						type='text'
						placeholder={"Inserisci username"}
						value={user}
						onChange={(e) => setUser(e.target.value)}
						required
					/>
				</Form.Group>
				<Form.Group className='mb-3' controlId='formBasicEmail'>
					<Form.Label>Password</Form.Label>
					<Form.Control
						type='password'
						placeholder={"Inserisci password"}
						value={pwd}
						onChange={(e) => setPwd(e.target.value)}
						required
					/>
				</Form.Group>

				<p> {errMsg}</p>

				<div className='col-12'>
					<button type='submit' className='btn btn-danger px-4 float-end mt-4'>
						{Globals.language.loginPage.buttonEntra[lang]}
					</button>
					<p className='text-start text-secondary  mt-4'>
						{Globals.language.loginPage.notRegister[lang]}
						<Link to='/register'>
							<a className='mx-1' href='/register'>
								{Globals.language.loginPage.registrati[lang]}
							</a>
						</Link>
					</p>
				</div>

				<div className='mt-5'>
					<Button
						variant='link'
						className='btn btn-link b-0 float-end text-primary'
						onClick={handleShow}>
						{Globals.language.loginPage.pwdDimenticata[lang]}
					</Button>
				</div>
			</Form>
			<Modal
				size='md'
				aria-labelledby='contained-modal-title-vcenter'
				centered
				show={show}
				onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>
						{Globals.language.loginPage.recuperoPasswordTitle[lang]}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{Globals.language.loginPage.recuperoPasswordDesc[lang]}
				</Modal.Body>
				<Modal.Body>
					<Form.Label>Email</Form.Label>
					<Form.Control
						type='email'
						placeholder={Globals.language.loginPage.emailPlaceholder[lang]}
						autoFocus
						className='form-control'
						value={pswrecovery}
						onChange={(e) => passwordRecoveryEmail(e.target.value)}
					/>
					<div
						ref={errRef}
						className={errMsg ? "errmsg" : "offscreen"}
						style={{color: "red", textAlign: "center"}}
						aria-live='assertive'>
						{errMsg}
					</div>
				</Modal.Body>
				<Modal.Footer>
					{/*<Button variant='secondary' onClick={handleClose}>*/}
					{/*	Chiudi*/}
					{/*</Button>*/}
					<Button variant='success' onClick={startPasswordRecovery}>
						{Globals.language.loginPage.buttonConferma[lang]}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				size='md'
				aria-labelledby='contained-modal-title-vcenter'
				centered
				show={successChangePwd}
				onHide={handleCloseSuccess}>
				<Modal.Header>
					<Modal.Title>
						{Globals.language.loginPage.successChangePwd[lang]}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{" "}
					<h2 className='text-center'>
						{Globals.language.loginPage.operationCompleted[lang]}{" "}
						<i
							className='fa fa-check-circle-fill '
							style={{fontSize: "30px", color: "green"}}></i>
					</h2>
				</Modal.Body>
				<Modal.Body></Modal.Body>
				<Modal.Footer>
					{/*<Button variant='secondary' onClick={handleCloseSuccess}>*/}
					{/*	Chiudi*/}
					{/*</Button>*/}
					<Button variant='danger' onClick={handleCloseSuccess}>
						{Globals.language.loginPage.goToLogin[lang]}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal centered show={showOtp} onHide={handleCloseOtp}>
				<Modal.Header>
					<Modal.Title>
						{Globals.language.loginPage.changePassword[lang]}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{Globals.language.loginPage.changePasswordDesc[lang]}
				</Modal.Body>
				<Modal.Body>
					<Form>
						<Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
							<Form.Label>{Globals.language.loginPage.code[lang]}</Form.Label>
							<Form.Control
								type='text'
								placeholder={Globals.language.loginPage.code[lang]}
								autoFocus
								value={codeOtp}
								onChange={(e) => setCodeOtp(e.target.value)}
							/>
							<Form.Label>{Globals.language.loginPage.newPassword[lang]}</Form.Label>
							<Form.Control
								type='password'
								placeholder={Globals.language.loginPage.newPassword[lang]}
								autoFocus
								value={newPassword}
								onChange={(e) => setNewPassword(e.target.value)}
							/>
						</Form.Group>
					</Form>
					<div
						ref={errRef}
						className={errMsg ? "errmsg" : "offscreen"}
						style={{color: "red", textAlign: "center"}}
						aria-live='assertive'>
						{errMsg}
					</div>
				</Modal.Body>
				<Modal.Footer>
					{/*<Button variant='secondary' onClick={handleCloseOtp}>*/}
					{/*	Annulla*/}
					{/*</Button>*/}
					<Button variant='success' onClick={changePassword}>
						{Globals.language.loginPage.buttonConferma[lang]}
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default Login;
