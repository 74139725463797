import React, {useState, useEffect, useContext} from "react";
import axios from "axios";
import Globals from "../parameters/globals";
// import { Helmet } from "react-helmet";
import AuthContext from "../context/AuthProvider";
import SEO from "../components/Seo";
function Disclaimer() {
	const [error, setError] = useState(false);
	const [message, setMessage] = useState("");
	const [disclaimer, setDisclaimer] = useState([]);
	const {lang} = useContext(AuthContext);

	useEffect(() => {
		getDisclaimer();
	}, [lang]);

	const getDisclaimer = async () => {
		setError(false);
		setMessage("");

		try {
			const response = await axios.get(Globals.DISCLAIMER, {
				headers: {"Content-Type": "application/json"},
				withCredentials: true,
			});

			if (response?.data?.error === true) {
				setError(true);
				setMessage(response?.data?.error);
			} else {
				window.scrollTo(0, 0);
				setDisclaimer(
					lang === "it"
						? response?.data?.disclaimer.htmlcontents_it
						: lang === "en"
						? response?.data?.disclaimer.htmlcontents_en
						: response?.data?.disclaimer.htmlcontents_si,
				);
			}
		} catch (err) {
			setError(true);
			setMessage(err);
		}
	};

	return (
		<>
			{error ? (
				<section className='text-center'>
					{" "}
					<h2> {message}</h2>
				</section>
			) : (
				<div
					className='container'
					style={{marginTop: "140px", marginBottom: "100px", minHeight: "600px"}}>
					<SEO
						title={"   Email Disclaimer | Lepanto 2  "}
						description={
							"  Le informazioni contenute o allegate a tutte le email ricevute da Lepanto sono riservate el destinatario   "
						}
						keyword={"    email disclaimer   "}
						noIndex={true}

					/>
					<div dangerouslySetInnerHTML={{__html: disclaimer}} />
				</div>
			)}
		</>
	);
}

export default Disclaimer;
